import React from "react";
import style from "./NewScreen.module.scss";
import awesomeleft from "../../../../../assets/images/awesome-arrow-left.svg";
import dollorS from "../../../../../assets/images/dollorS.svg";
import useNewScreen from "./useNewScreen";
import Skeleton from "react-loading-skeleton";
import useMintBurn from "../../useMintBurn";
import { useSelector } from "react-redux";
import BigNumber from "bignumber.js";

function NewScreen({
  handleActiveTab,
  mint,
  max,
  wSACXtoSynthValue,
  changeHandler,
  tokenBalToWSACX,
  setAmount,
  amount,
  errorMessage,
  setErrorMessage,
  checkWrappedTokenBal,
  getTotalStakedCollateral,
}) {
  const { swapSelectedData, wrappedSACXTokenBalance } = useSelector(
    (state) => state.swapReducer
  );
  const { submitHandler, approve, loading } = useNewScreen({
    mint,
    swapSelectedData,
    max,
    wrappedSACXTokenBalance,
    setAmount,
    amount,
    setErrorMessage,
    checkWrappedTokenBal,
    getTotalStakedCollateral,
  });
  console.log("approve", approve);
  return (
    <div className={style.container}>
      <div className={style.Wrapper}>
        <div>
          <img
            src={awesomeleft}
            onClick={() => {
              handleActiveTab(0);
              setErrorMessage("");
            }}
            alt="left"
          />
        </div>

        <div className={style.subWrapper}>
          <div className={style.imgWrapper}>
            <img src={dollorS} alt="Dollor" />
            {mint ? (
              <div className={style.text}>
                Total wASCX ={" "}
                {wrappedSACXTokenBalance
                  ? wrappedSACXTokenBalance?.toFixed(4)
                  : "0.00"}
              </div>
            ) : (
              <div className={style.text}>
                Total {swapSelectedData?.name} = {swapSelectedData?.balance}
              </div>
            )}
          </div>
          <div className={style.textWrapper}>
            <div className={style.subtextWrapper}>
              <div className={style.subtext1}>
                Enter {swapSelectedData?.name} value
              </div>
              <div className={style.subtext2}>
                <input
                  type={"number"}
                  placeholder="0.000"
                  disabled={max}
                  onChange={changeHandler}
                  value={amount}
                />
              </div>
            </div>
            <div
              className={style.border}
              style={{ borderBottom: errorMessage ? "1px solid red" : "" }}
            ></div>
            {/* <div className={style.subtextWrapper}>
              <div className={style.subtext1}>GAS PRICE (GWEI)</div>
              <div className={style.subtext2}>
                = 0 <span>EDIT</span>
              </div>
            </div> */}
            <span className={style.errorMessage}>
              {errorMessage && errorMessage}
            </span>
            {mint && !max && (
              <div className={style.imgWrapper2}>
                <div className={style.text}>
                  wSACX Value ≈{" "}
                  {` ${
                    tokenBalToWSACX ? tokenBalToWSACX.toFixed(4) : "0.00"
                  } (approx)`}
                </div>
              </div>
            )}
            {mint && (
              <div className={style.imgWrapper2}>
                <div className={style.text}>
                  1 {swapSelectedData?.name} =
                  {` ${wSACXtoSynthValue ? wSACXtoSynthValue : "0.00"} wSACX`}
                </div>
              </div>
            )}
            {approve ? (
              <button onClick={submitHandler} disabled={loading}>
                {loading ? (
                  <div className={style.spinnerWrapper}>
                    <div className={style.spinner}></div>
                  </div>
                ) : (
                  <>
                    {mint
                      ? approve == "0"
                        ? "First Approve"
                        : wrappedSACXTokenBalance
                        ? `Mint  ${swapSelectedData?.name}`
                        : "No WSACX in account"
                      : swapSelectedData.balance
                      ? `Burn  ${swapSelectedData?.name}`
                      : "Nothing to Burn"}
                  </>
                )}
              </button>
            ) : (
              <div className={style.LoadingButtonSkeleton}>
                <Skeleton
                  baseColor="#4d4370"
                  highlightColor="#00eaea"
                  height={66}
                  width={"100%"}
                  borderRadius="10px"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewScreen;
