import React, { useState } from "react";
import style from "./farms.module.scss";
import Switch from "react-switch";
import Sorticon from "../../../assets/images/sorticon.svg";
import BoxData from "./Data";
const Farms = () => {
  const [Data] = useState(BoxData);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <p>Farms</p>
            <span>Stake Liquidity Pool (LP) tokens to earn</span>
          </div>
          <div className={style.stakesection}>
            <div className={style.leftsection}>
              <div className={style.stakeswitch}>
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={40}
                  offColor="#352D5C"
                  onColor="#48A7C6"
                  offHandleColor="#48A7C6"
                  onHandleColor="#352D5C"
                />
                <p>Staked Only</p>
              </div>
              <div className={style.live}>
                <div className={style.finished}>
                  <button>Live</button>
                  <p>Finished</p>
                </div>
              </div>
            </div>
            <div className={style.rightsection}>
              <div className={style.sortsection}>
                <div className={style.title}>
                  <p>SORT BY</p>
                </div>
                <div className={style.sortby}>
                  <p>Hot</p>
                  <img src={Sorticon} alt="sort" />
                </div>
              </div>
              <div className={style.searchsection}>
                <div className={style.searchfield}>
                  <p>SEARCH</p>
                  <input type="text" placeholder="Search Frames" />
                </div>
              </div>
            </div>
          </div>
          <div className={style.boxsection}>
            {Data.map((currentElm) => {
              const {
                id,
                imgpath,
                ethmlogo,
                earned,
                text,
                apr,
                aprtxt,
                Liquidity,
                Liquiditytext,
                Multiplier,
                Multipliertext,
                arrowicon,
              } = currentElm;
              return (
                <div className={style.box} key={id}>
                  <div className={style.firstsection}>
                    <img src={imgpath} alt="BTC" />
                    <img src={ethmlogo} alt="ethm" className={style.ethmlogo} />
                  </div>
                  <div className={style.firstsection}>
                    <span>{earned}</span>
                    <p>{text}</p>
                  </div>
                  <div className={style.firstsection}>
                    <span>{apr}</span>
                    <p>{aprtxt}</p>
                  </div>
                  <div className={style.firstsection}>
                    <span>{Liquidity}</span>
                    <p>{Liquiditytext}</p>
                  </div>
                  <div className={style.firstsection}>
                    <span>{Multiplier}</span>
                    <p>{Multipliertext}</p>
                  </div>
                  <div className={style.firstsection}>
                    <img src={arrowicon} alt="arrow" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Farms;
