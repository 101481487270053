import React, { useState } from "react";
import { useEffect } from "react";
import {
  burnSwapToken,
  CheckApprovedMint,
  maxMintAmount,
  mintSwapToken,
  SendApprovedMint,
} from "../../../../../Services/swap.helper";
import { useActiveWeb3React } from "../../../../../Services/WalletConnection/useWalletConnect";
import { useAlert } from "react-alert";
import useMintBurn from "../../useMintBurn";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedSwapData } from "../../../../../store/reducers/swapReducer";
import BigNumber from "bignumber.js";

const useNewScreen = ({
  mint,
  swapSelectedData,
  max,
  wrappedSACXTokenBalance,
  setAmount,
  amount,
  setErrorMessage,
  checkWrappedTokenBal,
  getTotalStakedCollateral,
}) => {
  const history = useHistory();
  const { GetSwapList } = useMintBurn();
  const { account, chainId } = useActiveWeb3React();
  const [approve, setApproved] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  useEffect(() => {
    account && checkApprovedMinting();
    if (mint) {
      max && wrappedSACXTokenBalance && getMaxMint();
    } else {
      max && getMaxBurn();
    }
  }, [account, chainId]);
  const getMaxBurn = () => {
    setAmount(swapSelectedData?.balance);
  };
  const getMaxMint = async () => {
    try {
      const res = await maxMintAmount(
        swapSelectedData,
        wrappedSACXTokenBalance
      );
      BigNumber(res).toFixed() < 0.00001 ? setAmount(0) : setAmount(res);
    } catch (error) {
      console.log("error", error);
      throw new Error();
    }
  };
  const checkApprovedMinting = async () => {
    const approved = await CheckApprovedMint(account);
    setApproved(approved);
  };

  const onApprove = async (e) => {
    try {
      setLoading(true);
      const approvedTokens = await SendApprovedMint(account);
      setLoading(false);
      checkApprovedMinting();
      alert.show("Token Approved Successfully", { type: "success" });
    } catch (error) {
      setLoading(false);
      alert.show("Something Went Wrong", { type: "error" });
    }
  };
  const submitHandler = async () => {
    if (!mint && !swapSelectedData.balance) {
      return;
    }
    if (mint && !wrappedSACXTokenBalance) {
      return;
    }
    if (approve !== "0" && !+amount) {
      setErrorMessage("Invalid Input Field ");
      return;
    }
    try {
      if (mint) {
        if (approve == "0") {
          onApprove();
        } else {
          submitMint();
        }
      } else {
        submitBurn();
      }
    } catch (error) {}
  };
  const submitMint = async () => {
    try {
      setLoading(true);
      const res = await mintSwapToken(swapSelectedData, amount, account);
      await updateSelectedData();
      checkWrappedTokenBal();
      getTotalStakedCollateral();
      setLoading(false);
      setAmount("");
      alert.show("Token Mint Successfully", { type: "success" });
    } catch (error) {
      setLoading(false);
      alert.show("Something Went Wrong", { type: "error" });
      console.log("error", error);
    }
  };
  const submitBurn = async () => {
    try {
      setLoading(true);
      const res = await burnSwapToken(swapSelectedData, amount, account);
      await updateSelectedData();
      checkWrappedTokenBal();
      getTotalStakedCollateral();
      setLoading(false);
      setAmount("");
      alert.show("Token Mint Successfully", { type: "success" });
    } catch (error) {
      setLoading(false);
      alert.show("Something Went Wrong", { type: "error" });
      console.log("error", error);
    }
  };
  const updateSelectedData = async () => {
    try {
      const dataList = await GetSwapList();
      const selectedObj = dataList.find(
        (item) => item.symbol === swapSelectedData.symbol
      );
      dispatch(setSelectedSwapData(selectedObj));
    } catch (error) {
      throw new Error();
    }
  };
  return { submitHandler, approve, loading };
};

export default useNewScreen;
