const Data = [
  {
    id: 1,
    name: "forex",
    title: "USDa",
    live: "Live",
    price: "Price",
    pvalue: "$1.00",
    description:
      "Tracks the price of a single US Dollar (USD).This Synth always remains constant at 1.",
  },
  {
    id: 2,
    name: "forex",
    title: "EURa",
    live: "Live",
    price: "Price",
    pvalue: "$1.13",
    description:
      "Tracks the price of EURa Euros through price feeds supplied by an oracle.",
  },
  {
    id: 3,
    name: "forex",
    title: "JPYa",
    live: "Live",
    price: "Price",
    pvalue: "$0.01",
    description:
      "Tracks the price of JPYa Japanese Yen through price feeds supplied by an oracle.",
  },

  {
    id: 4,
    title: "AUDa",
    name: "forex",
    live: "Live",
    price: "Price",
    pvalue: "$0.70",
    description:
      "Tracks the price of AUDa Australian Dollars through price feeds supplied by an oracle",
  },
  {
    id: 5,
    title: "CHFa",
    name: "forex",
    live: "Live",
    price: "Price",
    pvalue: "$1.09",
    description:
      "Tracks the price of CHFa Swiss Franc through price feeds supplied by an oracle.",
  },
  {
    id: 6,
    title: "USDa",
    name: "forex",
    live: "Live",
    price: "Price",
    pvalue: "$1.00",
    description:
      "Tracks the price of a single US Dollar (USD).This Synth always remains constant at 1.",
  },
  {
    id: 7,
    title: "KRWa",
    name: "forex",
    live: "Live",
    price: "Price",
    pvalue: "$1.00",
    description:
      "Tracks the price of KRWa South Korean Won through price feeds supplied by an oracle.",
  },
  {
    id: 8,
    title: "USDa",
    name: "forex",
    live: "Live",
    price: "Price",
    pvalue: "$1.00",
    description:
      "Tracks the price of a single US Dollar (USD).This Synth always remains constant at 1.",
  },
  {
    id: 9,
    title: "BTCa",
    name: "crypto",
    live: "Live",
    price: "Price",
    pvalue: "$58,304.04",
    description:
      "Tracks the price of BTCa Bitcoin through price feeds supplied by an oracle.",
  },
  {
    id: 10,
    title: "ETHa",
    name: "crypto",
    live: "Live",
    price: "Price",
    pvalue: "$1.00",
    description:
      "Tracks the price of ETHa Ether through price feeds supplied by an oracle.",
  },
  {
    id: 11,
    title: "ADAa",
    name: "crypto",
    live: "Live",
    price: "Price",
    pvalue: "$1.01",
    description:
      "Tracks the price of ADAa Cardano through price feeds supplied by an oracle.",
  },
  {
    id: 12,
    title: "LINKa",
    name: "crypto",
    live: "Live",
    price: "Price",
    pvalue: "$1.70",
    description:
      "Tracks the price of LINKa Chainlink through price feeds supplied by an oracle. ",
  },
  {
    id: 13,
    title: "DOTa",
    name: "crypto",
    live: "Live",
    price: "Price",
    pvalue: "$37.74",
    description:
      "Tracks the price of sDOT Polkadot through price feeds supplied by an oracle.",
  },
  {
    id: 14,
    title: "XAUUSD",
    name: "commodities",
    live: "Live",
    price: "Price",
    pvalue: "$9523.01",
    description:
      "Tracks the price of a single US Dollar (USD). This Synth always remains constant at 1.",
  },
  {
    id: 15,
    title: "GoldD",
    name: "commodities",
    live: "Live",
    price: "Price",
    pvalue: "$6533.01",
    description:
      "Tracks the price of EURa Euros through price feeds supplied by an oracle.",
  },
  {
    id: 16,
    title: "GC2!D",
    name: "commodities",
    live: "Live",
    price: "Price",
    pvalue: "$2533.01",
    description:
      "Tracks the price of JPYa Japanese Yen through price feeds supplied by an oracle.",
  },
  {
    id: 17,
    title: "GOLDSILVER",
    name: "commodities",
    live: "Live",
    price: "Price",
    pvalue: "$112.70",
    description:
      "Tracks the price of AUDa Australian Dollars through price feeds supplied by an oracle",
  },
];
export default Data;
