import React from "react";
import style from "../Protocol/Protocol.module.scss";

function Protocol() {
  return (
    <div>
      <div className={style.container}>
        <div className={style.leftSide} data-aos="zoom-in">
          <video loop={false} autoPlay={false} muted={false} controls={true}>
            <source
              src="https://res.cloudinary.com/df9x7ac77/video/upload/v1640360292/video4_k0y2gy.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className={style.rightSide}>
          <div className={style.heading}>
            How Does the
            <span> ACX</span> Protocol Work?
          </div>
          <div className={style.subHeading}>
            ACX is the first and only decentralized synthetic asset issuance
            protocol built on Binance Smart Chain to offer cross-chain
            compatibility across other EVMs. These synthetic assets are
            collateralized by Alchemi (ACX), which enables the issuance of
            Alchemi synthetic assets (Synths) when locked in the contract. This
            pooled collateral model allows users to perform conversions between
            Alchemi Synths directly with the smart contract, avoiding the need
            for counter parties. This mechanism solves the liquidity and
            slippage issues experienced by other DEX’s currently available in
            the market.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Protocol;
