import React from "react";
import style from "./heading.module.scss";

function Heading({ main }) {
  return (
    <>
      <div className={style.Container}>
        <div className={style.Heading}>
          {main ? (
            <>
              All ACX Synthetic assets require to be backed by staked ACX at a
              target safe collateral ratio, ACX stakers need to manage their own
              ratio to be above this figure which is currently determined by the
              core team for now but will be determined down the road by a
              community governance module.
            </>
          ) : (
            <>
              All ACX Synthetic assets need to be backed by staked ACX at a
              target safe collateral ratio, ACX stakers need to manage their own
              ratio to be above this figure which is currently determined to be
              600% by the core team but will be determined down the road by a
              community governance module.
            </>
          )}
        </div>
        {!main && (
          <div className={style.textWrapper}>
            <div className={style.text1}>
              <div className={style.Heading1}>STAKED VALUE</div>
              <div className={style.subHeading}>$O.OO</div>
            </div>
            <div className={style.text2}>
              <div className={style.Heading1}>C-RATIO</div>
              <div className={style.subHeading}>0%</div>
              <div className={style.border}></div>
            </div>
            <div className={style.text3}>
              <div className={style.Heading1}>ACTIVE DEBT</div>
              <div className={style.subHeading}>$O.OO</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Heading;
