import React, { useState } from "react";
import style from "./createpair.module.scss";
import { Link } from "react-router-dom";
import CreatepairArrow from "../../../assets/images/create-pair-arrow.svg";
import Settingicon from "../../../assets/images/settingicon.svg";
import Bitcoin from "../../../assets/images/bitcoin.svg";
import litecoin from "../../../assets/images/litecoin.svg";
import Ethrem from "../../../assets/images/ethrm.svg";
import Bitcoing from "../../../assets/images/bitcoingreen.svg";
const image = {
  ETH: Ethrem,
  USD: Bitcoing,
  BTC: Bitcoin,
  LTC: litecoin,
};
const Createpair = () => {
  const [currency, setCurrency] = useState("ETH");
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.settingicon}>
            <div className={style.redirectbtn}>
              <Link to="/Liquidity">
                <span>
                  <img src={CreatepairArrow} alt="Arrow" />
                  Create a Pair
                </span>
              </Link>
            </div>
            <div className={style.settingimg}>
              <img src={Settingicon} alt="Settings" />
            </div>
          </div>
          <div className={style.Mainpara}>
            <span>You are the first liquidity provider.</span>
            <p>
              The ratio of tokens you add will set the price of this pool. Once
              you are happy with the rate click supply to review.
            </p>
          </div>
          <div className={style.inputdiv}>
            <span>Input</span>
            <div className={style.innerinputdiv}>
              <div className={style.leftside}>
                <input type="number" placeholder="0.00" />
              </div>
              <div className={style.rightside}>
                <img src={image[currency]} alt="SelectImage" />
                <select
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                  value={currency}
                >
                  <option value="ETH">ETH</option>
                  <option value="BTC">BTC</option>
                  <option value="USD">USD</option>
                  <option value="LTC">LTC</option>
                </select>
              </div>
            </div>
          </div>
          <div className={style.plusbtn}>
            <span>+</span>
          </div>
          <div className={style.inputdiv}>
            <span>Input</span>
            <div className={style.innerinputdivs}>
              <div className={style.leftside}>
                <input type="number" placeholder="0.00" />
              </div>
              <div className={style.rightside}>
                <select className={style.selecttoken}>
                  <option value="Select A Token">Select A Token</option>
                  <option value="ETH">ETH</option>
                  <option value="BTC">BTC</option>
                  <option value="USD">USD</option>
                  <option value="LTC">LTC</option>
                </select>
              </div>
            </div>
          </div>
          <div className={style.createpairbtn}>
            <Link to="/Liquidity">
              <button>Add Pair</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Createpair;
