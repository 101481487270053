import React from "react";
import style from "./maintanance.module.scss";
import logo from "../../assets/images/currency.svg";
import Innerlogo from "../../assets/images/foooterlogo.svg";
import Telegram from "../../assets/images/telegram.svg";
import Facebook from "../../assets/images/facebook.svg";
import Discord from "../../assets/images/discord.svg";
import Reddit from "../../assets/images/reddit.svg";
import Twitter from "../../assets/images/twitter.svg";
import { useSelector } from "react-redux";

const Maintenance = () => {
  const { admin_settings } = useSelector((state) => state.settingReducer);
  return (
    <>
      <div className={style.container} >
        <div className={style.nav}>
          <div className={style.logo}>
            <img src={Innerlogo} alt="Logo" />
          </div>
        </div>
        <div className={style.innerlogo}  data-aos="zoom-in">
          <img src={logo} alt="InnerLogo" />
        </div>
        <p>Site under maintenance !</p>
        <span>Join the ACX community by clicking one of the links below.</span>
        <div className={style.socialicons}>
            <ul>
              <li>
                <a  href={admin_settings?.telegramUrl} target="_blank">
                  <img src={Telegram} alt="Telegram" />
                </a>
              </li>
              <li>
                <a  href={admin_settings?.facebookUrl} target="_blank">
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a  href={admin_settings?.redditUrl}target="_blank">
                  <img src={Reddit} alt="Reddit" />
                </a>
              </li>
              <li>
                <a href={admin_settings?.twitterUrl} target="_blank">
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
            </ul>
          </div>
      </div>
    </>
  );
};

export default Maintenance;
