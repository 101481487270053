import React from "react";
import style from "./Tab2.module.scss";
import BarChart from "../../../../_Common/BarChart/BarChart";

const Tab1 = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.textWrapper}>DEBT OVERTIME</div>
          <div className={style.graphWrapper}>
            <div className={style.graph}>
              <BarChart />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tab1;
