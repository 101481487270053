import React, { PureComponent } from "react";
import { PieChart, Pie, ResponsiveContainer } from "recharts";
import styles from "./DashboardChart.module.scss";
const noData = [
  { name: "Group A", value: 400, fill: "#23fccf" },
  { name: "Group A", value: 200, fill: "#7B8AE9" },
  { name: "Group A", value: 200, fill: "#59C7A9" },
  { name: "Group A", value: 200, fill: "#E03731" },
  { name: "Group A", value: 200, fill: "#FF7E16" },
];

export default class DashboardChart extends PureComponent {
  render() {
    return (
      <div className={styles.wrapper}>
        <ResponsiveContainer width={200} height={200}>
          <PieChart onMouseEnter={this.onPieEnter}>
            {noData.length > 0 ? (
              <Pie
                data={noData}
                innerRadius={85}
                outerRadius={100}
                stroke="transparent"
                paddingAngle={0}
                dataKey="value"
                fill="#fff"
              />
            ) : (
              <Pie
                data={noData}
                innerRadius={85}
                outerRadius={100}
                stroke="transparent"
                paddingAngle={5}
                dataKey="value"
                fill="#fff"
              />
            )}
          </PieChart>
        </ResponsiveContainer>
        <div className={styles.portfolioInfo}>
          <div className={styles.totalWorth}>$3,561.32</div>
          {/* <div className={styles.totalAssets}>dAsset Value</div> */}
        </div>
      </div>
    );
  }
}
