import React, { useState } from "react";
import style from "./wrapSwap.module.scss";
import Modal from "../popup/popup";
import ModalStyle from "../ModalStyles/ModalStyle";
import DropDown from "./DropDownSwap/dropDown";
import useSwap from "./useWrapSwap";
import FramerRoute from "../../_Common/FramerRoute";
import LoadingSkeleton from "./LoadingSkeleton/loadingSkeleton";

function WrappedSwap() {
  const {
    popupVisible,
    setPopupVisible,
    submitHandler,
    calculateAmount,
    amount,
    selected,
    setSelected,
    options,
    errorMessage,
    loadingSwap,
    account,
    calculateAmountSingle,
    singleValue,
    totalValue,
    approved,
    sACXTokenBalance,
    wrappedSACXTokenBalance
  } = useSwap();

  return (
    <>
      <Modal visible={popupVisible} onClose={() => setPopupVisible(false)}>
        <ModalStyle />
      </Modal>
      <div className={style.container}>
        <div className={style.Wrapper}>
          <div className={style.heading}>
            ACX Swap is a convenient, fast, and ultra secure way to execute
            instant cross-chain swaps of your favorite coins across all EVM
            blockchains.
          </div>
          <FramerRoute width="90%">
            {true ? (
              <div className={style.SubWrapper}>
                <div className={style.subContainer}>
                  <div className={style.HeadingWrapper2}>
                    <div className={style.balanceWrapper}>
                      {account && (
                        <div className={style.balance}>{`wSACX : ${
                          wrappedSACXTokenBalance && account ? wrappedSACXTokenBalance : "0.00"
                        }`}</div>
                      )}
                      {account && (
                        <div className={style.balance}>{`sACX: ${
                          sACXTokenBalance && account
                            ? sACXTokenBalance
                            : "0.00"
                        }`}</div>
                      )}
                    </div>
                    <div className={style.NavWrapper2}>
                      <form action="/">
                        <div className={style.NavWrapper}>
                          <div className={style.DropDown}>
                            <DropDown
                              grow="100%"
                              selected={selected}
                              setSelected={setSelected}
                              options={options}
                              imgShow={true}
                            />
                          </div>

                          <input
                            type="number"
                            placeholder="0.0"
                            onChange={(e) => {
                              calculateAmount(e.target.value);
                              calculateAmountSingle();
                            }}
                            value={amount}
                            // disabled={loadingSwapInput}
                          />
                        </div>
                        <span className={style.errorMessage}>
                          {errorMessage && errorMessage}
                        </span>
                        <div className={style.bottomtextsize}>
                          <div className={style.bottomtext}>{}</div>
                          <div className={style.bottomtext2}>
                            {`Total ${
                              totalValue.to ? totalValue.to : "wSACX"
                            }  you will get : ${
                              totalValue?.amount ? totalValue?.amount : 0.0
                            }`}
                          </div>
                          {singleValue?.from && (
                            <div className={style.bottomtext2}>
                              {`1 ${singleValue?.from} = ${singleValue?.amount} ${singleValue?.to} `}
                            </div>
                          )}
                        </div>
                        <button
                          type="submit"
                          value="Submit"
                          onClick={submitHandler}
                          disabled={account && loadingSwap}
                        >
                          {loadingSwap ? (
                            <div className={style.spinnerWrapper}>
                              <div className={style.spinner}></div>
                            </div>
                          ) : account ? (
                            approved === "0" ? (
                              "Approve First"
                            ) : selected === "wSACX" ? (
                              "Get SACX"
                            ) : (
                              "Get Wrapped SACX"
                            )
                          ) : (
                            "First Connect to Wallet"
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <LoadingSkeleton />
            )}
          </FramerRoute>
        </div>
      </div>
    </>
  );
}

export default WrappedSwap;
