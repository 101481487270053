import Apple from "../../../assets/images/apple.svg";
import Microsoft from "../../../assets/images/Microsoft.svg";
import Alphabet from "../../../assets/images/gmail.svg";
import Amazaon from "../../../assets/images/Amazon.svg";
import Tesla from "../../../assets/images/Tesla.svg";

const Data = [
  {
    id: 1,
    imgpath: Apple,
    imgtext1: "Apple",
    imgtext: "AAPL",
    marketcap: "$512.11",
    change: "+12.01%",
    amount: "1998.00",
    yourequity: "$512.11",
  },
  {
    id: 2,
    imgpath: Microsoft,
    imgtext1: "Microsoft",
    imgtext: "MSFT",
    marketcap: "$512.11",
    change: "+12.01%",
    amount: "1998.00",
    yourequity: "$512.11",
  },
  {
    id: 1,
    imgpath: Alphabet,
    imgtext1: "Gmail",
    imgtext: "Google",
    marketcap: "$512.11",
    change: "+12.01%",
    amount: "1998.00",
    yourequity: "$512.11",
  },
  {
    id: 1,
    imgpath: Amazaon,
    imgtext1: "Amazon",
    imgtext: "AMZN",
    marketcap: "$512.11",
    change: "+12.01%",
    amount: "1998.00",
    yourequity: "$512.11",
  },
  {
    id: 1,
    imgpath: Tesla,
    imgtext1: "Tesla",
    imgtext: "Tesla",
    marketcap: "$512.11",
    change: "+12.01%",
    amount: "1998.00",
    yourequity: "$512.11",
  },
];
export default Data;
