import React, { useState } from "react";
import style from "./Users.module.scss";
import expertiseData from "./data";
import twitterImage from "../../../assets/images/twitter.svg";
import linkedImage from "../../../assets/images/Linked_White.svg";

const Users = ({ tweets }) => {
  return (
    <>
      <div className={style.cardContainer}>
        {tweets?.map((curElement, index) => {
          const { author_id, id, text } = curElement;
          const urlify = (text) => {
            const urlRegex =
              /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
            return text.replace(urlRegex, (url) => {
              return `<a href="${url}"  target='_blank'>$${url}</a>`;
            });
          };
          const html = urlify(text);
          return (
            <div className={style.testiMonial} key={id}>
              <div className={style.position}>
                <div className={style.position1}>
                  <img src={twitterImage} alt="twitterImage" />
                  <div className={style.column}>
                    <div
                      className={style.textDesc}
                      dangerouslySetInnerHTML={{ __html: html }}
                    />
                  </div>
                </div>
              </div>
              <div className={style.viewDetail}>
                <p className={style.textColor}>@ACXInfo</p>
                <div
                  className={style.view}
                  onClick={() =>
                    window.open(
                      `https://twitter.com/${author_id}/status/${id}`,
                      "_blank"
                    )
                  }
                >
                  <span>
                    <img src={linkedImage} />
                  </span>
                  <label>read more</label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Users;
