import React from "react";
import style from "./Header.module.scss";

function Header() {
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.HeadingWrapper}>
            <div className={style.heading}>Total Rewards</div>
            <div className={style.subHeading}>$0.00</div>
          </div>
          <div className={style.HeadingWrapper}>
            <div className={style.heading}>Fee Rewards</div>
            <div className={style.subHeading}>$0.00</div>
          </div>
          <div className={style.HeadingWrapper}>
            <div className={style.heading}>Collateral Rewards</div>
            <div className={style.subHeading}>$0.00</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
