import React from "react";
import Dna from "./DNA/Dna";
import Unique from "./Unique/Unique";
import Protocol from "./Protocol/Protocol";
import MainEnd from "./mainEnd/MainEnd";
import Header from "./Header/header";

function index() {
  return (
    <>
      <Header />
      <Dna />
      <Unique />
      <Protocol />
      <MainEnd />
    </>
  );
}

export default index;
