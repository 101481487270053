import BTC from "../../assets/images/bitcoin.svg";
import Acx from "../../assets/images/acxlogos.svg";
import Binance from "../../assets/images/binanceicon.svg";
const Data = [
  {
    id: 1,
    imgpath: BTC,
    mint: "BTC",
    price: "$5591.27",
    roi: "6.63%",
    Purchased: "$114,007,867",
    btn: "Mint",
  },
  {
    id: 2,
    imgpath: Acx,
    mint: "ACX",
    price: "$5591.27",
    roi: "6.63%",
    Purchased: "$114,007,867",
    btn: "Mint",
  },
  {
    id: 3,
    imgpath: Binance,
    mint: "BNB",
    price: "$5591.27",
    roi: "6.63%",
    Purchased: "$114,007,867",
    btn: "Mint",
  },
];
export default Data;
