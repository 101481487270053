import Lcx from "../../../assets/images/lcx.svg";
import Request from "../../../assets/images/request.svg";
import Linch from "../../../assets/images/linch.svg";
import Ren from "../../../assets/images/ren.svg";
import Polygon from "../../../assets/images/Polygon.svg";
import Curve from "../../../assets/images/curve.svg";
const TableData = [
  {
    id: 1,
    ImgPath: Lcx,
    Imgtext1: "Lcx",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 2,
    ImgPath: Request,
    Imgtext1: "Request",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 3,
    ImgPath: Linch,
    Imgtext1: "Linch",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 4,
    ImgPath: Ren,
    Imgtext1: "Ren",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 5,
    ImgPath: Polygon,
    Imgtext1: "Polygon",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 6,
    ImgPath: Curve,
    Imgtext1: "Curve",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
];
export default TableData;
