import React, { useState } from "react";
import style from "./pool.module.scss";
import Switch from "react-switch";
import Sorticon from "../../../assets/images/sorticon.svg";
import Pooldata from "./Data";
const Pools = () => {
  const [Data] = useState(Pooldata);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <p>Pools</p>
            <span>
              Single asset staking, Earn your favorite tokens <br />
              <span>High APY, Low Risk</span>
            </span>
          </div>
          <div className={style.stakesection}>
            <div className={style.leftsection}>
              <div className={style.stakeswitch}>
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={40}
                  offColor="#352D5C"
                  onColor="#48A7C6"
                  offHandleColor="#48A7C6"
                  onHandleColor="#352D5C"
                />
                <p>Staked Only</p>
              </div>
              <div className={style.live}>
                <div className={style.finished}>
                  <button>Live</button>
                  <p>Finished</p>
                </div>
              </div>
            </div>
            <div className={style.rightsection}>
              <div className={style.sortsection}>
                <div className={style.title}>
                  <p>SORT BY</p>
                </div>
                <div className={style.sortby}>
                  <p>Hot</p>
                  <img src={Sorticon} alt="sort" />
                </div>
              </div>
              <div className={style.searchsection}>
                <div className={style.searchfield}>
                  <p>SEARCH</p>
                  <input type="text" placeholder="Search Pool" />
                </div>
              </div>
            </div>
          </div>
          <div className={style.boxsection}>
            {Data.map((currentElm) => {
              const {
                id,
                imgpath,
                earned,
                text,
                apy,
                apytxt,
                totalstaked,
                tstext,
                endsin,
                endsintext,
                arrowicon,
              } = currentElm;
              return (
                <div className={style.box} key={id}>
                  <div className={style.firstsection}>
                    <img src={imgpath} alt="BTC" />
                  </div>
                  <div className={style.firstsection}>
                    <span>{earned}</span>
                    <p>{text}</p>
                  </div>
                  <div className={style.firstsection}>
                    <span>{apy}</span>
                    <p>{apytxt}</p>
                  </div>
                  <div className={style.firstsection}>
                    <span>{totalstaked}</span>
                    <p>{tstext}</p>
                  </div>
                  <div className={style.firstsection}>
                    <span>{endsin}</span>
                    <p>{endsintext}</p>
                  </div>
                  <div className={style.firstsection}>
                    <img src={arrowicon} alt="arrow" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pools;
