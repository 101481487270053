import React, { useState } from "react";
import style from "./gold.module.scss";
import Togglegraph from "../../../../assets/images/togglegraph.svg";
import Goldicon from "../../../../assets/images/Mask.svg";
import AngleArrowIcon from "../../../../assets/images/icon-dropdown.svg";
import TableData from "../../../../Data/Options/Commodities/Gold/Data";
import Candlechart from "../../../ChartsAndGraph/Chart";
import Switch from "react-switch";
const Crypto = () => {
  const [Data] = useState(TableData);
  const [hidechart, sethidechart] = useState(true);
  const [Buy, setbuy] = useState(1);
  const [intervalIndex, setIndex] = useState(0);
  const [isCandleStick, setChartType] = useState(true);

  return (
    <>
      <div className={style.container}>
        <div className={style.graphcontainer}>
          <div className={style.hidechart}>
            <div className={style.graphtitle}>
              <div className={style.leftsection}>
                <div className={style.imgsection}>
                  <img src={Goldicon} alt="Gold" />
                </div>
                <div className={style.textsection}>
                  <span>GOLDD</span>
                  <p>I think it's going up</p>
                </div>
              </div>
              <div className={style.rightsection}>
                <div className={style.candlecharticon}>
                  CandleStick Chart
                  <Switch
                    onChange={(checked) => setChartType(checked)}
                    checked={isCandleStick}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={40}
                    offColor="#352D5C"
                    onColor="#48A7C6"
                    offHandleColor="#48A7C6"
                    onHandleColor="#352D5C"
                  />
                </div>
                <div className={style.togglegraph}>
                  <span onClick={() => sethidechart(!hidechart)}>
                    <img src={Togglegraph} alt="Toggle" />
                    &nbsp; Hide Chart
                  </span>
                </div>
              </div>
            </div>
            {hidechart ? (
              <div className={style.graphbody}>
                <div className={style.graghrole}>
                  <div className={style.leftheading}>
                    <span>$57,249.19</span>
                    <p>+2.5%</p>
                  </div>
                  <div className={style.rightheading}>
                    <ul>
                      <li>
                        <button onClick={() => setIndex(0)}>1min</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(1)}>5min</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(2)}>15min</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(3)}>1h</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(4)}>1d</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(5)}>1w</button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={style.graph}>
                  <div className={style.innergraph}>
                    <Candlechart
                      interval={intervalIndex}
                      isCandleStick={isCandleStick}
                      height="400px"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={style.GraphTable}>
            <div className={style.tabledata}>
              <div className={style.tableheading}>
                <div className={style.leftside}>
                  <ul>
                    <li
                      onClick={() => setbuy(1)}
                      className={Buy === 1 ? style.active : ""}
                    >
                      Buy
                    </li>
                    <li
                      onClick={() => setbuy(2)}
                      className={Buy === 2 ? style.active : ""}
                    >
                      Sell
                    </li>
                  </ul>
                </div>
                <div className={style.rightside}>
                  <div className={style.selectionarea}>
                    <span>
                      Expiring Dec 10th, 2021, 1:00 PM
                      <img src={AngleArrowIcon} alt="Arrow" />
                    </span>
                  </div>
                </div>
              </div>
              <div className={style.tablebody}>
                <table>
                  <thead>
                    <tr>
                      <td>Strike</td>
                      <td>Break Even</td>
                      <td>Implied Volatility</td>
                      <td>Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((currnelm) => {
                      const {
                        id,
                        strike,
                        breaks,
                        implied,
                        price,
                        plus,
                        checked,
                      } = currnelm;
                      return (
                        <tr key={id}>
                          <td>{strike}</td>
                          <td>{breaks}</td>
                          <td>{implied}</td>
                          <td>
                            <button>
                              {price}&nbsp;
                              {plus ? (
                                plus
                              ) : (
                                <img src={checked} alt="Checked" />
                              )}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className={style.rightcontainer}>
          <div className={style.title}>
            <span>GOLDD $4,500.00</span>
          </div>
          <div className={style.amountdiv}>
            <div className={style.heading}>
              <div className={style.text}>
                <p>Amount</p>
                <span>1</span>
              </div>
              <div className={style.imgsection}>
                <img src={Goldicon} alt="Gold" />
              </div>
            </div>
          </div>
          <div className={style.textsection}>
            <div className={style.innertext}>
              <span>Total Cost</span>
              <p>$355.83 USDa</p>
            </div>
          </div>
          <div className={style.textsection}>
            <div className={style.innertext}>
              <span>Price per option</span>
              <p>$654.34</p>
            </div>
          </div>
          <div className={style.textsection}>
            <div className={style.innertext}>
              <span>Base</span>
              <p>$640.54</p>
            </div>
          </div>
          <div className={style.textsection}>
            <div className={style.innertext}>
              <span>Fees</span>
              <p>$30.80</p>
            </div>
          </div>
          <div className={style.balancediv}>
            <div className={style.heading}>
              <p>Balance</p>
              <span>$355.83 USDa</span>
            </div>
          </div>
          <div className={style.btndiv}>
            <button>Mint USDa</button>
          </div>
          <div className={style.optionbtn}>
            <button>Open Position</button>
          </div>
          <div className={style.sidebargraph}>
            <div className={style.graphtitle}>
              <span>Expected Profit and Loss</span>
              <p>-$349.22</p>
            </div>
            <div className={style.graphbody}>
              <div className={style.innergraph}>
                <Candlechart
                  interval={intervalIndex}
                  // isCandleStick={isCandleStick}
                  height="230px"
                />
              </div>
            </div>
          </div>
          <div className={style.profit}>
            <div className={style.innertext}>
              <span>Max Profit</span>
              <p>$3,840.62</p>
            </div>
          </div>
          <div className={style.break}>
            <div className={style.innertext}>
              <span>Break Even</span>
              <p>$3,840.62</p>
            </div>
          </div>
          <div className={style.loss}>
            <div className={style.innertext}>
              <span>Max Loss</span>
              <p>$$659.38</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Crypto;
