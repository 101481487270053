import React, { useState } from "react";
import style from "./Table.module.scss";
import TableData from "./data";

function Table() {
  const [data] = useState(TableData);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.TableHeading}>
            <div className={style.Heading}>STATS</div>
          </div>
          <div className={style.tableContainer}>
            <div className={style.tableWrapper}>
              <div className={style.subWrapper}>
                <table>
                  <thead>
                    <tr>
                      <td>Assets</td>
                      <td>Open Interest</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((win) => {
                      const {
                        id,
                        price,

                        asset,
                      } = win;
                      return (
                        <tr key={id} className={style.winrow}>
                          <td>
                            <b className={style.FontHeading}>{asset}</b>
                          </td>
                          <td>
                            <b>{price}</b>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
