import React from "react";
import style from "./liquidations.module.scss";
import BarChart from "../../_Common/BarChart/BarChart";
const Liquidations = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.innerwrapper}>
            <div className={style.heading}>
              <span>Liquidations</span>
              <p>Active Liquidations flagged within the past 30 days</p>
            </div>
            <div className={style.liquidationstable}>
              <table>
                <tr>
                  <td>Wallet Address</td>
                  <td>Time til Liquidation</td>
                  <td>Current C-Ratio</td>
                  <td>Liquidation Amount</td>
                  <td>Amount To Cover Debt</td>
                </tr>

                <tr>
                  <td>0x2649aB9104AD79eefeE6BeFcA3eeEA7655B0f5fF</td>
                  <td>1hour</td>
                  <td>0%</td>
                  <td>$56868.6453</td>
                  <td>$56868.6453</td>
                </tr>
                <tr>
                  <td>0x2649aB9104AD79eefeE6BeFcA3eeEA7655B0f5fF</td>
                  <td>1hour</td>
                  <td>0%</td>
                  <td>$56868.6453</td>
                  <td>$56868.6453</td>
                </tr>
                <tr>
                  <td>0x2649aB9104AD79eefeE6BeFcA3eeEA7655B0f5fF</td>
                  <td>1hour</td>
                  <td>0%</td>
                  <td>$56868.6453</td>
                  <td>$56868.6453</td>
                </tr>
                <tr>
                  <td>0x2649aB9104AD79eefeE6BeFcA3eeEA7655B0f5fF</td>
                  <td>1hour</td>
                  <td>0%</td>
                  <td>$56868.6453</td>
                  <td>$56868.6453</td>
                </tr>
              </table>
            </div>
          </div>
          <div className={style.graphsection}>
            <div className={style.graphbody}>
              <div className={style.graghrole}>
                <div className={style.leftheading}>
                  <span>$28,905.93</span>
                  <p>ACX Price</p>
                </div>
                <div className={style.rightheading}>
                  <ul>
                    <li>
                      <button className={style.activebtn}>24H</button>
                    </li>
                    <li>
                      <button>7D</button>
                    </li>
                    <li>
                      <button>1M</button>
                    </li>
                    <li>
                      <button>3M</button>
                    </li>
                    <li>
                      <button>6M</button>
                    </li>
                    <li>
                      <button>1Y</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={style.graph}>
                <BarChart defaultColor={true} dataFile={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Liquidations;
