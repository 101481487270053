import React, { useCallback, useEffect, useRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./popup.module.scss";

export default function Modal({ visible, onClose, children }) {
  const escFunction = useCallback(
    (e) => {
      if (e.type === "click") {
        e.preventDefault();
      }
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    document.removeEventListener("click", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
      document.removeEventListener("click", escFunction, false);
    };
  }, [escFunction]);

  const scrollRef = useRef(null);

  useEffect(() => {
    visible ? disableBodyScroll(scrollRef) : enableBodyScroll(scrollRef);
  }, [visible]);
  if (visible) {
    return (
      <div className={styles.modal} ref={scrollRef}>
        <div className={cn(styles.outer)}>
          <OutsideClickHandler onOutsideClick={onClose}>
            <div className={cn(styles.container)}>
              {children}
              <button className={styles.close} onClick={onClose}>
                close
              </button>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
