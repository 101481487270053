const TableData = [
  {
    id: 1,
    asset: "USDa",
    price: "$-338,203,523.60",
  },
  {
    id: 2,
    asset: "BTCa",
    price: "$338,203,523.60",
  },
  {
    id: 3,
    asset: "USDa",
    price: "$-338,203,523.60",
  },
  {
    id: 4,
    asset: "BTCa",
    price: "$-338,203,523.60",
  },
];
export default TableData;
