import React, { useState } from "react";
import style from "./liquidity.module.scss";
import { Link } from "react-router-dom";
import ZapComponent from "./zap/Zap";
const Liquidity = () => {
  const [active, setactive] = useState(1);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.innerwrapper}>
            <div
              className={`${style.liquidity} ${
                active === 1 ? style.active : ""
              }`}
              onClick={() => setactive(1)}
            >
              Liquidity
            </div>
            <div
              className={`${style.zap} ${active === 2 ? style.active : ""}`}
              onClick={() => setactive(2)}
            >
              Zap
            </div>
          </div>
          {active === 1 && (
            <div className={style.Mainpara}>
              <div className={style.para}>
                <span>Liquidity provider rewards</span>
                <p>
                  Liquidity providers earn a 0.3% fee on all trades proportional
                  to their share of the pool. Fees are added to the pool, accrue
                  in real time and can be claimed by withdrawing your liquidity.
                </p>
                <a href="/">Read more about providing liquidity</a>
              </div>
              <div className={style.addliquiditybtn}>
                <span>Your Liquidity</span>
                <div className={style.btndiv}>
                  <Link to="/create-pair">
                    <button>Create a Pair</button>
                  </Link>
                  <Link to="/add-liquidity">
                    <button className={style.liquiditybtn}>
                      Add Liquidity
                    </button>
                  </Link>
                </div>
              </div>
              <div className={style.connectarea}>
                <p>Connect to a wallet to view your liquidity.</p>
              </div>
              <p></p>
            </div>
          )}
          {active === 2 && (
            <div className={style.mainzap}>
              <ZapComponent />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Liquidity;
