const Data = [
  {
    id: 1,
    title: "ACXR",
    description: "$5970.35",
  },
  {
    id: 2,
    title: "Market Cap",
    description: "$2,785,903,972",
  },
  {
    id: 3,
    title: "TVL",
    description: "$1,850,393,677",
  },
  {
    id: 4,
    title: "APY",
    description: "72,371.4%",
  },
  {
    id: 5,
    title: "Current Index",
    description: "5.06 ACXR",
  },
  {
    id: 6,
    title: "Treasury Balance",
    description: "$789,380,458",
  },
  {
    id: 7,
    title: "Backing per ACXR",
    description: "$2,165",
  },
  {
    id: 8,
    title: "Runway",
    description: "425.6 Days",
  },
];
export default Data;
