import React, { useEffect, useState } from "react";
import Navigation from "../src/Components/_Common/navigation/navigation";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "../src/Components/_Common/Footer/Footer";
import ScrollToTop from "./Components/_Common/ScrollToTop";
import NetworkChangeModal from "./Components/_Common/Modal/NetworkChangeModal/Modal";
import GenricModal from "./Components/_Common/Modal/popup/popup";
import { useActiveWeb3React } from "./Services/WalletConnection/useWalletConnect";
import { NetworkTestChainId } from "./utils/contract";
import axios from "axios";
import { baseUrl } from "./utils/AppConstant";
import { useDispatch, useSelector } from "react-redux";
import { setSettings } from "./store/reducers/settingsReducer";
import NavigationRoute from "./Components/_Common/navigationRoute";
import Maintenance from "./Components/Maintanace";
import LoadingScreen from "./Components/LoadingScreen";
import AOS from "aos";
import "aos/dist/aos.css";
import { UnsupportedChainIdError } from "@web3-react/core";

const App = () => {
  const { account, chainId, error } = useActiveWeb3React();
  const isWrongNetwork = error && error instanceof UnsupportedChainIdError;
  const [loadingScreen, setLoadingScreen] = useState(true);
  const { admin_settings } = useSelector((state) => state.settingReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    ChainId();
    fetchSettings();
  }, [chainId]);
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
    AOS.refresh();
  }, []);

  const ChainId = () => {
    if (chainId !== NetworkTestChainId) {
      return (
        <div style={{ minHeight: "100vh" }}>
          {
            <GenricModal visible={true} scroll={true}>
              <NetworkChangeModal close={false} />
            </GenricModal>
          }
        </div>
      );
    }
  };
  const fetchSettings = async () => {
    try {
      setLoadingScreen(true);
      const res = await axios.get(`${baseUrl}/settings`);
      const { __v, _id, createdAt, updatedAt, ...rest } = res?.data;
      dispatch(setSettings(rest));
      setLoadingScreen(false);
    } catch (error) {
      setLoadingScreen(false);
      console.log("error", error);
    }
  };

  return (
    <BrowserRouter>
      {loadingScreen ? (
        <LoadingScreen />
      ) : (
        <>
          {admin_settings?.maintenanceMode && <Maintenance />}

          {!admin_settings?.maintenanceMode && (
            <>
              <ScrollToTop />
              <Navigation />
              <NavigationRoute />
              <Footer />
              {(chainId || isWrongNetwork) && ChainId()}
            </>
          )}
        </>
      )}
    </BrowserRouter>
  );
};

export default App;
