import React, { useState, useEffect } from "react";
import style from "./sidebar.module.scss";
import btcicon from "../../../assets/images/bitcoin.svg";
import ethmicon from "../../../assets/images/ethrm.svg";
import litecoin from "../../../assets/images/litecoin.svg";
import Euroicon from "../../../assets/images/eurosd.svg";
import Usdjpyicon from "../../../assets/images/usdjpy.svg";
import Usdicon from "../../../assets/images/usdcad.svg";
import Xauuicon from "../../../assets/images/Mask.svg";
import SilverIcon from "../../../assets/images/silver.svg";
import Bsticon from "../../../assets/images/bst.svg";
import Disicon from "../../../assets/images/dis.svg";
import Pildicon from "../../../assets/images/pild.svg";
import BtcComponent from "../Crypto/Btc/Btc";
import EthmComponent from "../Crypto/Ethem/Ethm";
import LtcComponent from "../Crypto/Ltc/Ltc";
import DistComponent from "../Stocks/Dis/Dis";
import BstComponent from "../Stocks/Bst/Bst";
import PildComponent from "../Stocks/Pild/Pild";
import EuroComponent from "../Forex/Euro/Euro";
import UsdComponent from "../Forex/Usd/Usd";
import UsdjpyComponent from "../Forex/Usdjpy/Usdpy";
import XauuComponent from "../Commodities/Xauu/Xauu";
import GoldComponent from "../Commodities/Gold/Gold";
import SilverComponent from "../Commodities/Silver/Silver";
const Pathlist = [
  {
    title: "Crypto",
    path: "/options/crypto/btc",
    submenu: [
      {
        title: "BTC-USD",
        Icon: btcicon,
        path: "/options/crypto/btc",
      },
      {
        title: "ETH-USD",
        Icon: ethmicon,
        path: "/options/crypto/ethm",
      },
      {
        title: "LTC-USD",
        Icon: litecoin,
        path: "/options/crypto/ltc",
      },
    ],
  },
  {
    title: "Stocks",
    path: "/options/stocks/dis",
    submenu: [
      {
        title: "DIS",
        Icon: Disicon,
        path: "/options/stocks/dis",
      },
      {
        title: "BST",
        Icon: Bsticon,
        path: "/options/stocks/bst",
      },
      {
        title: "PILD",
        Icon: Pildicon,
        path: "/options/stocks/pild",
      },
    ],
  },
  {
    title: "Forex",
    path: "/options/forex/euro",
    submenu: [
      {
        title: "EUROUSD",
        Icon: Euroicon,
        path: "/options/forex/euro",
      },
      {
        title: "USDCAD",
        Icon: Usdicon,
        path: "/options/forex/usd",
      },
      {
        title: "USDJPY",
        Icon: Usdjpyicon,
        path: "/options/forex/usdjpy",
      },
    ],
  },
  {
    title: "Commodities",
    path: "/options/commodities/xauu",
    submenu: [
      {
        title: "XAUUSD",
        Icon: Xauuicon,
        path: "/options/commodities/xauu",
      },
      {
        title: "USDCAD",
        Icon: Xauuicon,
        path: "/options/commodities/gold",
      },
      {
        title: "USDJPY",
        Icon: SilverIcon,
        path: "/options/commodities/silver",
      },
    ],
  },
];
const Sidebar = ({ id, history }) => {
  const [activeitem, setsetitem] = useState(0);
  useEffect(() => {
    if (id === "btc" || id === "ethm" || id === "ltc") {
      setsetitem(0);
    } else if (id === "dis" || id === "bst" || id === "pild") {
      setsetitem(1);
    } else if (id === "euro" || id === "usd" || id === "usdjpy") {
      setsetitem(2);
    } else {
      setsetitem(3);
    }
  }, [activeitem]);


  return (
    <>
      <div className={style.container}>
        <div className={style.sidebar}>
          <div className={style.subsidebar}>
            <ul className={style.parentlistitem}>
              <li
                onClick={() => history.push("/options/crypto/btc")}
                className={
                  id === "btc" || id === "ethm" || id === "ltc"
                    ? style.active
                    : ""
                }
              >
                <div
                  className={`${style.siderole} ${
                    activeitem === 0 ? style.siderole : ""
                  }`}
                  onClick={() => setsetitem(0)}
                >
                  <div className={style.siderolelable}>
                    <label>Crypto</label>
                  </div>
                </div>
              </li>
            </ul>
            {activeitem === 0 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/crypto/btc")}
                  className={id === "btc" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(0)}>
                    <div className={style.siderolelable}>
                      <img src={btcicon} alt="BTC" />
                      <label>
                        BTC-USD
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {activeitem === 0 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/crypto/ethm")}
                  className={id === "ethm" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(0)}>
                    <div className={style.siderolelable}>
                      <img src={ethmicon} alt="BTC" />
                      <label>
                        ETH-USD
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {activeitem === 0 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/crypto/ltc")}
                  className={id === "ltc" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(0)}>
                    <div className={style.siderolelable}>
                      <img src={litecoin} alt="BTC" />
                      <label>
                        LTC-USD
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
          <div className={style.subsidebar}>
            <ul className={style.parentlistitem}>
              <li
                onClick={() => history.push("/options/stocks/dis")}
                className={
                  id === "dis" || id === "bst" || id === "pild"
                    ? style.active
                    : ""
                }
              >
                <div
                  className={`${style.siderole} ${
                    activeitem === 1 ? style.siderole : ""
                  }`}
                  onClick={() => setsetitem(1)}
                >
                  <div className={style.siderolelable}>
                    <label>Stocks</label>
                  </div>
                </div>
              </li>
            </ul>
            {activeitem === 1 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/stocks/dis")}
                  className={id === "dis" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(1)}>
                    <div className={style.siderolelable}>
                      <img src={Disicon} alt="Dis" />
                      <label>
                        DIS
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {activeitem === 1 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/stocks/bst")}
                  className={id === "bst" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(1)}>
                    <div className={style.siderolelable}>
                      <img src={Bsticon} alt="BST" />
                      <label>
                        BST
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {activeitem === 1 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/stocks/pild")}
                  className={id === "pild" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(1)}>
                    <div className={style.siderolelable}>
                      <img src={Pildicon} alt="pild" />
                      <label>
                        PILD
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
          <div className={style.subsidebar}>
            <ul className={style.parentlistitem}>
              <li
                onClick={() => history.push("/options/forex/euro")}
                className={
                  id === "euro" || id === "usd" || id === "usdjpy"
                    ? style.active
                    : ""
                }
              >
                <div
                  className={`${style.siderole} ${
                    activeitem === 2 ? style.siderole : ""
                  }`}
                  onClick={() => setsetitem(2)}
                >
                  <div className={style.siderolelable}>
                    <label>Forex</label>
                  </div>
                </div>
              </li>
            </ul>
            {activeitem === 2 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/forex/euro")}
                  className={id === "euro" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(2)}>
                    <div className={style.siderolelable}>
                      <img src={Euroicon} alt="euro" />
                      <label>
                        EUROUSD
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {activeitem === 2 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/forex/usd")}
                  className={id === "usd" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(2)}>
                    <div className={style.siderolelable}>
                      <img src={Usdicon} alt="usdcad" />
                      <label>
                        USDCAD
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {activeitem === 2 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/forex/usdjpy")}
                  className={id === "usdjpy" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(2)}>
                    <div className={style.siderolelable}>
                      <img src={Usdjpyicon} alt="Usdjpy" />
                      <label>
                        USDJPY
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
          <div className={style.subsidebar}>
            <ul className={style.parentlistitem}>
              <li
                onClick={() => history.push("/options/commodities/xauu")}
                className={
                  id === "xauu" || id === "gold" || id === "silver"
                    ? style.active
                    : ""
                }
              >
                <div
                  className={`${style.siderole} ${
                    activeitem === 3 ? style.siderole : ""
                  }`}
                  onClick={() => setsetitem(3)}
                >
                  <div className={style.siderolelable}>
                    <label>Commodities</label>
                  </div>
                </div>
              </li>
            </ul>
            {activeitem === 3 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/commodities/xauu")}
                  className={id === "xauu" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(3)}>
                    <div className={style.siderolelable}>
                      <img src={Xauuicon} alt="BTC" />
                      <label>
                        XAUUSD
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {activeitem === 3 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/commodities/gold")}
                  className={id === "gold" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(3)}>
                    <div className={style.siderolelable}>
                      <img src={Xauuicon} alt="BTC" />
                      <label>
                        GOLD
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {activeitem === 3 && (
              <ul className={style.childlistitem}>
                <li
                  onClick={() => history.push("/options/forex/silver")}
                  className={id === "silver" ? style.inneractive : ""}
                >
                  <div className={style.siderole} onClick={() => setsetitem(3)}>
                    <div className={style.siderolelable}>
                      <img src={SilverIcon} alt="silver" />
                      <label>
                        SILVER
                        <p>$57,547.50</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className={style.rightsection}>
          {id === "btc" ? (
            <BtcComponent />
          ) : id === "ethm" ? (
            <EthmComponent />
          ) : id === "ltc" ? (
            <LtcComponent />
          ) : (
            ""
          )}
          {id === "dis" ? (
            <DistComponent />
          ) : id === "bst" ? (
            <BstComponent />
          ) : id === "pild" ? (
            <PildComponent />
          ) : (
            ""
          )}
          {id === "euro" ? (
            <EuroComponent />
          ) : id === "usd" ? (
            <UsdComponent />
          ) : id === "usdjpy" ? (
            <UsdjpyComponent />
          ) : (
            ""
          )}
          {id === "xauu" ? (
            <XauuComponent />
          ) : id === "gold" ? (
            <GoldComponent />
          ) : id === "silver" ? (
            <SilverComponent />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
