import React from "react";
import Heading from "./Header/Header";
import Table from "./table/Table";
import style from "./Positions.module.scss";

function Positions() {
  return (
    <>
      <div className={style.container}>
        <Heading />
        <Table />
      </div>
    </>
  );
}

export default Positions;
