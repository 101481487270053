const Data = [
  {
    id: 1,
    symbol: "DXY",
    name: "U.S. Dollar",
    last: "1,998.00",
    change: "+12.01%",
    high: "1,998.00",
    low: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 2,
    symbol: "EURUSD",
    name: "Fx/U.S. Dollar",
    last: "1,998.00",
    change: "+12.01%",
    high: "1,998.00",
    low: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 3,
    symbol: "USDCAD",
    name: "$51U.S.Dollar",
    last: "1,998.00",
    change: "+12.01%",
    high: "1,998.00",
    low: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 4,
    symbol: "USDJPY",
    name: "Fx/U.S. Dollar",
    last: "1,998.00",
    change: "+12.01%",
    high: "1,998.00",
    low: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 5,
    symbol: "USDCHF",
    name: "Fx/U.S. Dollar",
    last: "1,998.00",
    change: "+12.01%",
    high: "1,998.00",
    low: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 6,
    symbol: "GBPUSD",
    name: "Fx/U.S. Dollar",
    last: "1,998.00",
    change: "+12.01%",
    high: "1,998.00",
    low: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 7,
    symbol: "AUDUSD",
    name: "U.S. Dollar",
    last: "1,998.00",
    change: "+12.01%",
    high: "1,998.00",
    low: "1,998.00",
    time: "00:46:00",
  },
];
export default Data;
