import Bitcoin from "../../../../assets/images/bitcoin.svg";
import Polygon from "../../../../assets/images/Polygon12.svg";
import Ethereum3 from "../../../../assets/images/Ethereum3.svg";
import Dash from "../../../../assets/images/Dash.svg";
import Ethereum from "../../../../assets/images/Ethereum2.svg";

const TableData = [
  {
    id: 1,
    img: Bitcoin,
    asset: "Bitcoin",
    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$56868.6453",
    Rewards: "56868.6453 USDa",
    Time: "1d 22h 18m 47s",
  },
  {
    id: 2,
    img: Ethereum,
    asset: "Ethereum",
    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$208.1315",
    Rewards: "208.1315 USDa",
    Time: "1d 22h 18m 47s",
  },
  {
    id: 3,
    img: Ethereum3,
    asset: "Ethereum ",
    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$4143.445",
    Rewards: "4143.445 USDa",
    Time: "1d 22h 18m 47s",
  },
  {
    id: 4,
    img: Dash,
    asset: "Dash",

    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$562.79",
    Rewards: "562.79 USDa",
    Time: "1d 22h 18m 47s",
  },
  {
    id: 5,
    img: Polygon,
    asset: "Polygon",

    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$56868.6453",
    Rewards: "56868.6453 USDa",
    Time: "1d 22h 18m 47s",
  },
];
export default TableData;
