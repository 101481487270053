import Mask from "../../../assets/images/Mask.svg";
import Silver from "../../../assets/images/silver.svg";
const Data = [
  {
    id: 1,
    imgpath: Mask,
    imgtext1: "XAUUSD",
    imgtext: "56523.51 USDb",
  },
  {
    id: 2,
    imgpath: Silver,
    imgtext1: "XAUUSD",
    imgtext: "56523.51 USDb",
  },
  {
    id: 3,
    imgpath: Mask,
    imgtext1: "XAUUSD",
    imgtext: "56523.51 USDb",
  },
  {
    id: 4,
    imgpath: Mask,
    imgtext1: "XAUUSD",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Mask,
    imgtext1: "XAUUSD",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Mask,
    imgtext1: "Apple",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Silver,
    imgtext1: "Microsoft",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Silver,
    imgtext1: "Apple",
    imgtext: "56523.51 USDb",
  },
];
export default Data;
