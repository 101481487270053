import React, { useState } from "react";
import style from "./MintTab.module.scss";
import MINT from "./MINT/Mint";
import BURN from "./BURN/Burn";
import Horizontal from "./Slider";
import useMint from "./MINT/useMint";
import useMintBurn from "../useMintBurn";
import { useSelector } from "react-redux";

function MintTab() {
  const { swapSelectedData, wrappedSACXTokenBalance, tolStakedCollateral } =
    useSelector((state) => state.swapReducer);
  const { synthUsdPrice } = useMint({ swapSelectedData });

  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <div className={style.Wrapper}>
        <div className={style.container}>
          <div className={style.tabs}>
            <ul className={style.nav}>
              <li
                onClick={() => setActiveTab(1)}
                className={activeTab === 1 ? style.active : ""}
              >
                MINT
              </li>
              <li
                onClick={() => setActiveTab(2)}
                className={activeTab === 2 ? style.active : ""}
              >
                BURN
              </li>
            </ul>
            <div className="outlet">
              {activeTab === 1 && <MINT />}
              {activeTab === 2 && <BURN />}
            </div>
          </div>
        </div>
        <div className={style.CardWrapper}>
          <div className={style.Card}>
            <div className={style.HeadingWrapper}>
              <div className={style.Heading}>
                Mint {swapSelectedData?.name} by ACX stakers
              </div>
              <div className={style.subHeading}>
                Mint USDa by staking your ACX. ACX stakers earn weekly staking
                rewards in exchange for managing their Collateralization Ratio
                and debt. 
                {/* <span>Learn more.</span> */}
              </div>
            </div>
            <div className={style.border}></div>
            <div className={style.subHeadingWrapper}>
              <div className={style.Heading}>Total wSACX</div>
              <div className={style.subHeading}>
                {wrappedSACXTokenBalance
                  ? wrappedSACXTokenBalance?.toFixed(4)
                  : "0.00"}
              </div>
            </div>
            <div className={style.border}></div>
            <div className={style.SliderWrapper}>
              <div className={style.subHeadingWrapper}>
                <div className={style.Heading}>C-RATIO</div>
                <div className={style.subHeading}>750%</div>
              </div>
              <div className={style.border}></div>
              {/* <div className={style.Slider}>
                <Horizontal />
              </div> */}

              <div className={style.subHeadingWrapper}>
                <div className={style.Heading}>Balance</div>
                <div className={style.subHeading}>
                  {swapSelectedData?.balance?.toFixed(4)}
                </div>
              </div>
            </div>
            <div className={style.border}></div>
            <div className={style.subHeadingWrapper}>
              <div className={style.Heading}>ChainLink Price</div>
              <div className={style.subHeading}>
                {swapSelectedData?.chainLinkPrice?.toFixed(6)}
              </div>
            </div>
            <div className={style.border}></div>
            <div className={style.subHeadingWrapper}>
              <div className={style.Heading}>1 {swapSelectedData?.name}</div>
              <div className={style.subHeading}>
                {synthUsdPrice ? synthUsdPrice : "0.00"} $
              </div>
            </div>
            <div className={style.border}></div>
            <div className={style.subHeadingWrapper}>
              <div className={style.Heading}>Total Staked Collateral</div>
              <div className={style.subHeading}>
                {tolStakedCollateral ? tolStakedCollateral : "0.00"}
              </div>
            </div>
            <div className={style.border}></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MintTab;
