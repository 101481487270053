import React, { useEffect, useState } from "react";
import style from "./modalStyle.module.scss";
import logo from "../../../assets/images/AcxLogo.svg";
import coinbaseWallet from "../../../assets/images/coinbaseWallet.svg";
import metamask from "../../../assets/images/metamask.svg";
import walletConnect from "../../../assets/images/walletConnect.svg";
import {
  injected,
  walletconnect,
} from "../../../Services/WalletConnection/WalletConnector";
import { useActiveWeb3React } from "../../../Services/WalletConnection/useWalletConnect";
import { setAccountAddress } from "../../../store/reducers/accountReducer";
import { useDispatch, useSelector } from "react-redux";

function ModalStyle({ toggle }) {
  const [connect, setConnect] = useState(null);
  const { active, account, library, connector, activate, deactivate } =
    useActiveWeb3React();
  const accountsAddress = useSelector(
    (state) => state.accountsReducer.accountsAddress
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (connector === injected) {
      setConnect(0);
    } else if (connector === walletconnect) {
      setConnect(1);
    }
  }, []);
  // useEffect(() => {
  //   if (account) {
  //     ;
  //     const check = accountsAddress?.find((item) => item === account);
  //     if (!accountsAddress.length || !check) {
  //       dispatch(setAccountAddress(account));
  //       importToken();
  //       importSACXToken();
  //       importBUSDToken();
  //     }
  //   }
  // }, [account]);
  // console.log("accountsAddress",accountsAddress);

  const connectHandler = async (type) => {
    setConnect(type);
    if (connector && connector.walletConnectProvider) {
      connector.walletConnectProvider = undefined;
    }
    try {
      if (type === 0) {
        await activate(injected);
      }
      if (type === 1) {
        const walletResConnect = await activate(walletconnect);
      }
      // window.ethereum.request({ method: 'eth_requestAccounts' })
      // type===2&& await activate()
      toggle();
    } catch (ex) {
      toggle();
      console.log(ex);
    }
  };
  return (
    <>
      <div className={style.container}>
        <div className={style.Header}>
          <img src={logo} alt="logo" />
        </div>
        <div className={style.subContainer}>
          <div className={style.textWrapper}>Connect Your Wallet</div>
          <div className={style.buttonWrapper}>
            <button
              className={`${style.btn1} ${connect === 0 ? style.active : ""}`}
              onClick={() =>
                window.ethereum === undefined
                  ? window
                      .open(
                        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
                        "_blank"
                      )
                      .focus()
                  : connectHandler(0)
              }
            >
              {window.ethereum === undefined ? (
                <div className={style.MetaText}>Install MetaMask Extension</div>
              ) : (
                <div className={style.MetaText}>MetaMask</div>
              )}
              <div className={style.Metaimg}>
                <img src={metamask} alt="metamask" />
              </div>
            </button>
            <button
              className={`${style.btn2} ${connect === 1 ? style.active : ""}`}
              onClick={() => connectHandler(1)}
            >
              <div className={style.walletConnectText}>Wallet Connect</div>
              <div className={style.walletConnectimg}>
                <img src={walletConnect} alt="walletConnect" />
              </div>
            </button>
            {/* <button
              className={`${style.btn3} ${connect === 2 ? style.active : ""}`}
              onClick={() => connectHandler(2)}
            >
              <div className={style.coinbaseWalletText}>Coinbase Wallet</div>
              <div className={style.coinbaseWalletimg}>
                <img src={coinbaseWallet} alt="coinbaseWallet" />
              </div>
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalStyle;
