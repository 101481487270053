import axios from "axios";
import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { baseUrl } from "../../../utils/AppConstant";
import User from "../Users/Users";
import style from "./Users.module.scss";
function Slider() {
  const [loadingTweets, setLoadingTweets] = useState(false);
  const [tweets, setTweets] = useState("");
  useEffect(() => {
    GetUserTweets();
  }, []);

  const GetUserTweets = async () => {
    try {
      setLoadingTweets(true);
      const res = await axios.get(`${baseUrl}/twitter-api`);
      setTweets(res.data);
      setLoadingTweets(false);
    } catch (error) {
      setLoadingTweets(false);
      console.log("error", error);
    }
  };

  return (
    <>
      <div className={style.marqueWrapper}>
        <div className={style.container}>
          <div className={style.heading}>DON'T JUST TAKE OUR WORD FOR IT</div>
          <div className={style.subHeading}>
            Join our rapidly growing community of
            <span> ACX</span> users
          </div>
        </div>
        <Marquee pauseOnHover={true} speed={60} gradient={false}>
          {tweets && <User tweets={tweets} />}
        </Marquee>
      </div>
    </>
  );
}

export default Slider;
