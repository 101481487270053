import React from "react";
import style from "./glossary.module.scss";
const Glossary = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.Glossary}>
            <div className={style.heading}>
              <p>Glossary</p>
            </div>
            <div className={style.textwrapper}>
              <span>0x</span>
              <p>
                The 0x protocol is an open standard for building decentralized
                exchanges (DEXs) on the Ethereum blockchain. Launched in 2018,
                0x enables developers to incorporate peer-to-peer digital asset
                exchange into platforms and apps. 0x’s native token is ZRX,
                which allows holders participation rights in 0x platform
                governance.
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>1inch Liquidity Protocol</span>
              <p>
                The 1inch Liquidity Protocol is an automated market maker (AMM)
                that is designed to increase liquidity on the protocol and to
                make use of virtual balances in order to decrease impermanent
                loss. Users can benefit from providing tokens as liquidity on
                the 1inch platform through a process that’s called “liquidity
                mining” — whereby traders provide assets like ETH to a specific
                pool, lock it in, and earn 1INCH, the native token of the 1inch
                platform, as interest. This model is similar to native DEX
                tokens like Uniswap’s UNI, and incentivizes community-based
                liquidity provision.
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>3D Model Rendering</span>
              <p>
                3D model rendering is the process of creating a virtual image or
                animation by using varying digital texture, color, and lighting
                software. The modeling process uses data points to represent
                objects in three-dimensional space, which is then rendered from
                3D models into 2D images through a computationally heavy
                process.
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>401k Plan</span>
              <p>
                In the US, a 401k is an employer-sponsored, government-supported
                pension savings plan for employees. 401k plans allow companies
                to sponsor eligible employees in saving and investing towards
                retirement by contributing a portion of their income to the plan
                on a tax-deferred basis. These contributions are often
                incentivized by employer matching. 401k plans are typically
                defined by contribution limits, tax advantages, and early
                withdrawal penalties.
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>51% Attack</span>
              <p>
                A 51% attack is a hypothetical scenario in which more than 50%
                of a blockchain network's nodes fall under the control of a
                single group. In such a circumstance, the consensus of a network
                is no longer sufficiently distributed enough to be viable,
                leaving the blockchain open to manipulation. Attackers with
                greater than 50% control of a network would be able to stop,
                reverse, and duplicate new transactions, a catastrophic
                condition for any blockchain. Notably, a 51% attack becomes more
                difficult and expensive as a network grows more sizable,
                distributed, and valuable.
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>Aave</span>
              <p>
                The AAVE token is an Ethereum-based ERC-20 asset used as the
                governance token of the Aave blockchain protocol. AAVE token
                holders have the ability to propose changes and vote to approve
                or deny new proposals to the Aave protocol. With significant
                enough distribution, AAVE tokens are intended to eventually
                accommodate the autonomous and decentralized governance of the
                Aave platform
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>Acala USD Stablecoin (USDa)</span>
              <p>
                The Acala USD stablecoin (aUSD) is a stablecoin that is pegged
                to the U.S. dollar on a 1:1 ratio that operates on the Acala
                Network blockchain. aUSD facilitates the Acala Network's
                operation and is essential to the Acala decentralized finance
                (DeFi) platform. Users can borrow aUSD after they deposit
                cryptocurrency assets such as bitcoin (BTC), ether (ETH), or
                polkadot (DOT) through a Collateralized Debt Position (CDP) from
                the Honzon stablecoin protocol.
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>Account Abstraction Layer (AAL)</span>
              <p>
                The Account Abstraction Layer (AAL) is the technical
                infrastructure that makes smart contract development possible on
                the Qtum blockchain. The foundational layer of Qtum follows the
                UTXO model used on blockchains such as Bitcoin. The AAL on Qtum
                allows the accounts model used on Ethereum to be “abstracted” or
                transferred in order to work on top of the UTXO model. Qtum’s
                AAL is the computing go-between that allows the UTXO and
                accounts models to interact.
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>Account-Checker Tool</span>
              <p>
                An account-checker is a script or program that takes a list of
                usernames and passwords — known as a combolist — and tests them
                against a set of target websites. Account-checker tools
                substantially increase the speed and efficiency with which an
                attacker can test a large volume of credentials on a broad range
                of websites and service providers. These tools are generally
                used by malicious actors looking to capitalize and commit fraud
                or identity theft against the account holders from whom they
                have stolen access.
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>Account Model</span>
              <p>
                The account model is a blockchain architecture that features
                direct webrmation and value transfer. Smart contract platforms
                such as Ethereum use the account model as opposed to the UTXO
                model used by the Bitcoin network, which limits the capabilities
                of smart contracts. One potential downside of the account model
                architecture is difficulty in scaling transactions-per-second.
              </p>
            </div>
            <div className={style.textwrapper}>
              <span>Accredited Investor</span>
              <p>
                An accredited investor is an investor who meets specific
                criteria pertaining to income, net worth, and qualifications.
                While such requirements can vary from country to country,
                generally accredited investors can include high-net-worth
                individuals (HNWIs), financial institutions, banks, and large
                corporations. Accredited investors are often able to access
                complex investments that other investors might not have access
                to — including venture capital firms, hedge funds, and angel
                investment enterprises. Accredited investors can also benefit
                from high returns and increased diversification, although the
                types of investments that are limited to accredited investors
                can also be subject to higher risk, high minimum investment
                amounts, low liquidity, and higher fees.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Glossary;
