import React, { useState, useEffect, useRef } from "react";
import styles from "./dropDown.module.scss";
import img from "../../../../assets/images/SwapDown.svg";
const DropDown = ({
  selected,
  grow,
  setSelected,
  options,
  showRightIcon,
  padding,
  color,
  imgShow,
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", onClickOutSide);
    return () => {
      document.removeEventListener("click", onClickOutSide);
    };
  }, []);
  const onClickOutSide = (event) => {
    const isClickInsideElement = dropRef?.current?.contains(event.target);
    if (!isClickInsideElement) {
      setIsActive(false);
    }
  };
  return (
    <div
      className={styles.dropdown}
      ref={dropRef}
      style={{ width: grow && grow }}
    >
      <div
        className={styles.dropdownbtn}
        onClick={(e) => setIsActive(!isActive)}
        style={{
          padding: padding ? `${padding}` : "",
          background: `${color?.length ? color : ""}`,
        }}
      >
        {/* {showRightIcon === false ? null : (
          <div className={styles.imgContainer}>
            <img className={styles.leftIcon} src={"/assets/XML.PNG"} />
          </div>
        )} */}
        <span>{selected}</span>
        {imgShow && (
          <img className={styles.withRightIcon} src={img} alt="SwapDown" />
        )}
      </div>

      {isActive && (
        <div
          className={`${styles.dropdowncontent} ${
            grow && styles.dropdowncontentPaddingZero
          }`}
        >
          {options.map((option) => (
            <div
              onClick={(e) => {
                if (option !== selected) {
                  setSelected(option);
                  setIsActive(false);
                }
              }}
              className={`${styles.dropdownitems} ${
                option === selected && styles.dropdownitemsDisable
              }`}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
