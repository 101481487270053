import React from "react";
import SidebarComponent from "../_Common/Sidebar/Sidebar";
import style from "./swap.module.scss";
import networkingSwap from "../../assets/images/networkingSwap.svg";
import synthetic from "../../assets/images/synthetic.svg";
import wrappedSwap from "../../assets/images/wrappedSwap.svg";
import Bond from "../../assets/images/bond.svg";
import AllowSwap from "./AllowSwap/AllowSwap";
import Blockchain from "./blockchain/blockchain";
import WrappedSwap from "./WrapSwap/WrapSwap";
const Pathlist = [
  {
    title: "Wrapped Swap",
    Icon: wrappedSwap,
    path: "/swap/wrap-swap",
  },
  {
    title: "Synth Swap",
    Icon: synthetic,
    path: "/swap/allow-swap",
  },

  // {
  //   title: "Cross Swap",
  //   Icon: networkingSwap,
  //   path: "/swap/cross-swap",
  // },
];
function Stake({
  history,
  match: {
    params: { id },
  },
}) {
  console.log("id ",id );
  return (
    <>
      <div className={style.container}>
        <div className={style.sidebar}>
          <SidebarComponent Pathlist={Pathlist} id={id} history={history} />
        </div>
        <div className={style.rightsection}>
          {id === "allow-swap" && <AllowSwap />}
          {id === "wrap-swap" && <WrappedSwap />}
          {id === "cross-swap" && <Blockchain />}
        </div>
      </div>
    </>
  );
}

export default Stake;

