import React, { useEffect, useState } from "react";
import FramerRoute from "../../../_Common/FramerRoute";
import LoadingSkeleton from "../LoadingSkeleton/loadingSkeleton";
import style from "./StakeTab.module.scss";
import useStakeTab from "./useStakeTab";

function StakeTab() {
  const {
    approved,
    changeHandler,
    account,
    onApprove,
    onStake,
    state,
    loading,
    balance,
    sACXTokenbalance,
    warmupInfo,
    epochInfo,
    onClaim,
    loadingClaim,
    timeRemaining,
    ARP,
  } = useStakeTab();
  return (
    <FramerRoute>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.textSection}>
            <div className={style.Heading}>Stake</div>
            <div className={style.subHeading}>{`Current ACX APR : ${ARP}%`}</div>
          </div>
          <div className={style.lableSection}>
            <div className={style.balanceWrapper}>
              {account && (
                <div className={style.balance}>{`ACX : ${
                  balance && account ? balance : "0.00"
                }`}</div>
              )}
              {account && (
                <div className={style.balance}>{`sACX: ${
                  sACXTokenbalance && account ? sACXTokenbalance : "0.00"
                }`}</div>
              )}
            </div>

            <div className={style.inputField}>
              <form>
                {approved > "0" && (
                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter Amount"
                    min={0}
                    onChange={changeHandler}
                    value={state?.amount}
                  />
                )}
                {state?.amountVal && (
                  <span className={style.errorText}>{state?.amountVal}</span>
                )}
                {approved === "0" && (
                  <button
                    className={`${style.btn1} ${!account && style.isdisabled} `}
                    onClick={onApprove}
                    disabled={account && loading}
                  >
                    {loading ? (
                      <div className={style.spinnerWrapper}>
                        <div className={style.spinner}></div>
                      </div>
                    ) : (
                      "Approve First"
                    )}
                  </button>
                )}
                {approved > "0" && (
                  <button
                    className={`${style.btn1} ${!account && style.isdisabled}`}
                    onClick={onStake}
                    disabled={account && loading}
                  >
                    {loading ? (
                      <div className={style.spinnerWrapper}>
                        <div className={style.spinner}></div>
                      </div>
                    ) : (
                      "Stake"
                    )}
                  </button>
                )}
                {approved > "0" && warmupInfo && (
                  <>
                    <span className={style.claimBalance}>
                      {` Stake Amount :  ${
                        warmupInfo?.deposit ? warmupInfo.deposit / 1e9 : 0.0
                      }`}
                    </span>
                    <span className={style.claimBalance}>
                      {`Estimated Time : ${timeRemaining}`}
                    </span>
                    {warmupInfo?.expiry != "0" &&
                      +epochInfo?.number >=  +warmupInfo?.expiry && (
                        <button
                          className={`${style.btn1} ${
                            !account && style.isdisabled
                          }`}
                          onClick={onClaim}
                          disabled={!account && loadingClaim}
                        >
                          {loadingClaim ? (
                            <div className={style.spinnerWrapper}>
                              <div className={style.spinner}></div>
                            </div>
                          ) : (
                            "Claim"
                          )}
                        </button>
                      )}
                  </>
                )}

                {account && approved === null && <LoadingSkeleton />}
                {!account && approved === null && (
                  <div className={style.connectWallet}>
                    First Connect to Wallet
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </FramerRoute>
  );
}

export default StakeTab;
