import BigNumber from "bignumber.js";
import { BOND_CONTRACT } from "../utils/contract";
import {
  GetBondContract,
  GetGenericBondContract,
} from "./Contract/contract.helper";

export const CheckApprovedPreSale = async (account, contractAddress) => {
  const customToken = await GetGenericBondContract(contractAddress);
  const approved = await customToken.methods
    .allowance(account, BOND_CONTRACT)
    .call();
  return approved;
};
export const SendApprovedPreSale = async (account, contractAddress) => {
  const customToken = await GetGenericBondContract(contractAddress);
  const addApproved = await customToken.methods
    .approve(BOND_CONTRACT, "100000000000000000000000")
    .send({ from: account });
  return addApproved;
};
export const BuyToken = async (
  contractAddress,
  amount,
  maxAmount,
  receiver,
  account
) => {
  const amountRound1 = Math.ceil(Number(amount));
  const maxAmountRound1 = Math.ceil(Number(maxAmount));
  const amountRound = BigNumber(amountRound1).toFixed();
  const maxAmountRound = BigNumber(Number(maxAmountRound1)).toFixed();

  try {
    const customToken = await GetBondContract();
    const res = customToken.methods
      .deposit(
        contractAddress,
        amountRound.toString(),
        maxAmountRound.toString(),
        receiver
      )
      .send({ from: account });
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
