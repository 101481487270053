import React, { useEffect, useState } from "react";
import style from "./timer.module.scss";
import { useTimer } from "react-timer-hook";
import { useSelector } from "react-redux";

function MyTimer({ expiryTimestamp }) {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <div className={style.Card}>
      <div className={style.Cardtimer}>
        <div className={style.items}>{days}</div>
        <div style={{ fontSize: "10px" }}>DAYS</div>
      </div>

      <div className={style.Cardtimer}>
        <div className={style.items}>{hours}</div>
        <div style={{ fontSize: "10px" }}>HOURS</div>
      </div>

      <div className={style.Cardtimer}>
        <div className={style.items}>{minutes}</div>
        <div style={{ fontSize: "10px" }}>MINUTES</div>
      </div>

      <div className={style.Cardtimer}>
        <div className={style.items}>{seconds}</div>
        <div style={{ fontSize: "10px" }}>SECONDS</div>
      </div>
    </div>
  );
}

export default function App() {
  const [expiryTimestamp, setExpiryTimestamp] = useState("");
  const { admin_settings } = useSelector((state) => state.settingReducer);
  useEffect(() => {
    admin_settings.preSaleStartDate && getPreSaleDate();
  }, [admin_settings.preSaleStartDate]);

  const getPreSaleDate = () => {
    const startDate = admin_settings?.preSaleStartDate;
    const endDate = admin_settings?.preSaleEndDate;
    let date1 = new Date(startDate);
    let date2 = new Date(endDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const startDateInMilliSeconds = new Date(startDate).valueOf();
    const currentDateInMilliSeconds = new Date().valueOf();
    const compare = currentDateInMilliSeconds >= startDateInMilliSeconds;

    if (compare) {
      const end = new Date(
        startDateInMilliSeconds + Difference_In_Days * 24 * 60 * 60 * 1000
      ).getTime();
      let date = new Date(end);
      setExpiryTimestamp(date);
    } else {
      const end = new Date(
        currentDateInMilliSeconds - Difference_In_Days * 24 * 60 * 60 * 1000
      ).getTime();
      let date = new Date(end);
      setExpiryTimestamp(date);
    }
  };

  return (
    <div>
      {expiryTimestamp && <MyTimer expiryTimestamp={expiryTimestamp} />}
    </div>
  );
}

