import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import style from "./header.module.scss";
import WithLogo from "../../../assets/images/WithLogo.svg";
import { TwinSpin } from "react-cssfx-loading";
import VideoPoster from "./VideoPoster";
// const VideoPoster = React.lazy(() => import("./VideoPoster"));

function Header() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);



  return (
    <>
      <div className={style.container}>
        <div className={style.leftSide}>
          <div className={style.imgWrapper}>
            <img src={WithLogo} alt="Logo" />
          </div>
          <div data-aos="fade-right" className={style.heading}>
            Trade, Invest, Earn 24/7 With <span>ACX</span> DeFi.
          </div>
          <div data-aos="fade-right" className={style.subHeading}>
            Trade and Invest in Cryptocurrencies, *Stocks, *ETFs, *Forex,
            *Currencies, *Indices and *Commodities- all in one place. Just
            connect your wallet and begin trading 24/7 with no minimums or
            trading pair restrictions.
          </div>
          <button
            className={style.btn}
            onClick={() => history.push("/AcxToken")}
            data-aos="fade-right"
          >
            Buy Tokens
          </button>
        </div>
        <div className={style.rightSide}>
          {loading && (
            <TwinSpin color="#00EAEA" duration="4s" className={style.loader} />
          )}

          <VideoPoster
            loading={loading}
            onStart={() => setLoading(true)}
            onEnd={() => setLoading(false)}
          />
        </div>
      </div>
    </>
  );
}

export default Header;
