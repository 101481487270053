import React from "react";
import ErrorImg from "../../../assets/images/404Error.svg";
import style from "./Error.module.scss";

function Error() {
  return (
    <>
      <div className={style.container}>
        <div className={style.Wrapper}>
          <div className={style.HeadingWrapper}>
            <div className={style.Heading}>Looks Like you’re lost in space</div>
            <img src={ErrorImg} alt="" className={style.imgWrapper} />
            <div className={style.SecHeading}>Page Not Found</div>
            <div className={style.subHeading}>
              Looks like you've followed a broken link or entered a URL that
              doesn't exist on this site.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error;
