import React from "react";
import style from "./reversetoken.module.scss";
import SidebarComponent from "../_Common/Sidebar/Sidebar";
import Dashboradicon from "../../assets/images/dashboradicon.svg";
import Bridgeicon from "../../assets/images/bridge.svg";
import Minticon from "../../assets/images/mint.svg";
import DashboradComponent from "./Dashboard/Dashboard";
import MintComponent from "./Mint/Mint";
import BridgeComponent from "./Bridge/Bridge";
const Pathlist = [
  {
    title: "Dashboard",
    Icon: Dashboradicon,
    path: "/reverse-token-acxr/dashboard",
  },
  {
    title: "Mint",
    Icon: Minticon,
    path: "/reverse-token-acxr/mint",
  },

  {
    title: "Bridge",
    Icon: Bridgeicon,
    path: "/reverse-token-acxr/bridge",
  },
];
const Acx = ({
  history,
  match: {
    params: { id },
  },
}) => {
  return (
    <>
      <div className={style.container}>
        <div className={style.sidebar}>
          <SidebarComponent Pathlist={Pathlist} id={id} history={history} />
        </div>
        <div className={style.rightsection}>
          {id === "dashboard" ? (
            <DashboradComponent />
          ) : id === "mint" ? (
            <MintComponent />
          ) : id === "bridge" ? (
            <BridgeComponent />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Acx;
