import React from "react";
import style from "./Dna.module.scss";
import DNA from "../../../assets/images/DNA.svg";

function Dna() {
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.container}>
          <img src={DNA} className={style.img1} alt="DNA" />
        </div>
      </div>
    </>
  );
}

export default Dna;
