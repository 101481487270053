import React from "react";
import PreSale from "./PreSale/PreSale";
import style from "./AcxToken.module.scss";

function AcxToken() {
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <PreSale />
        </div>
      </div>
    </>
  );
}

export default AcxToken;
