import React, { useEffect, useState } from "react";
import { depositorInfo, redeemToken } from "../../../../Services/bond.helper";
import { useActiveWeb3React } from "../../../../Services/WalletConnection/useWalletConnect";
import style from "./bondcomponent.module.scss";
import { BUSD_CONTRACT } from "../../../../utils/contract";
import { useAlert } from "react-alert";
import Skeleton from "react-loading-skeleton";
import BigNumber from "bignumber.js";

function SingleBond({ toggle, toggleAmount, bondData }) {
  const alert = useAlert();
  const { account } = useActiveWeb3React();
  const [buy, setBuy] = useState(1);
  const [loadingRedeem, setLoadingRedeem] = useState(false);
  const [loadingBuy, setLoadingBuy] = useState(false);
  const [depositInfo, setDepositInfo] = useState(false);
  const { chainId } = useActiveWeb3React();
  useEffect(() => {
    GetDepositData();
  }, [chainId]);
  const GetDepositData = async () => {
    try {
      const res = await depositorInfo(
        bondData?.contractAddress,
        account,
        bondData
          ? bondData.isLiquidityBond
            ? bondData.LP_Decimal
            : bondData.decimals
          : 1e18
      );
      setDepositInfo(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  const redeemACX = async (e) => {
    try {
      setLoadingRedeem(true);
      const res = await redeemToken(bondData?.contractAddress, account);
      alert.show("ACX Claim Successfully", { type: "success" });
      setLoadingRedeem(false);
      toggle();
    } catch (error) {
      alert.show("SomeThing Went Wrong", { type: "error" });
      setLoadingRedeem(false);
      toggle();
    }
  };
  const BuyToken = async (e) => {
    e.preventDefault();
    if (account) {
      toggleAmount();
    } else {
      alert.show("First Connect Wallet", { type: "error" });
    }
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.mainwrapper}>
          <div className={style.wrapper}>
            <div className={style.Cards}>
              <div className={style.card}>
                <p>ACX Price</p>
                <span>{Number(bondData?.price)?.toFixed(2) + " $"}</span>
              </div>
              <div className={style.card}>
                <p>Floor</p>
                <span>$0.00</span>
              </div>
            </div>
            <div className={style.bodysection}>
              <div className={style.leftside}>
                <ul>
                  <li
                    onClick={() => setBuy(1)}
                    className={buy === 1 ? style.active : ""}
                  >
                    Buy
                  </li>
                  <li
                    onClick={() => setBuy(2)}
                    className={buy === 2 ? style.active : ""}
                  >
                    Redeem
                  </li>
                </ul>
              </div>

              <div className={style.lableSection}>
                {buy === 1 && (
                  <div className={style.lablebttn}>
                    <button onClick={BuyToken}>Buy</button>
                  </div>
                )}
                {buy === 2 && (
                  <div className={style.lablebttn}>
                    <button onClick={redeemACX} disabled={loadingRedeem}>
                      {loadingRedeem ? (
                        <div className={style.spinnerWrapper}>
                          <div className={style.spinner}></div>
                        </div>
                      ) : (
                        "Claim"
                      )}
                    </button>
                  </div>
                )}
                <div className={style.textWrapper2}>
                  <div className={style.textsubWrapper}>
                    <span>TX Setting</span>
                  </div>
                  <div className={style.textsubWrapper}>
                    <div className={style.textHeading}>{"Claimable ACX"}</div>
                    {depositInfo ? (
                      <div className={style.textsubHeading}>
                        {depositInfo.payout}
                      </div>
                    ) : (
                      <Skeleton
                        baseColor="#4d4370"
                        highlightColor="#00eaea"
                        height={20}
                        width={100}
                      />
                    )}
                  </div>
                  <div className={style.textsubWrapper}>
                    <div className={style.textHeading}>
                      {"Purchase at the rate"}
                    </div>
                    {depositInfo ? (
                      <div className={style.textsubHeading}>
                        {BigNumber(depositInfo?.pricePaid).toFixed()}
                      </div>
                    ) : (
                      <Skeleton
                        baseColor="#4d4370"
                        highlightColor="#00eaea"
                        height={20}
                        width={100}
                      />
                    )}
                  </div>
                  <div className={style.textsubWrapper}>
                    <div className={style.textHeading}>{" Time Remaining"}</div>
                    {depositInfo ? (
                      <div className={style.textsubHeading}>
                        {depositInfo.timeRemaining}
                      </div>
                    ) : (
                      <Skeleton
                        baseColor="#4d4370"
                        highlightColor="#00eaea"
                        height={20}
                        width={100}
                      />
                    )}
                  </div>
                  {/* <div className={style.textsubWrapper}>
                    <div className={style.textHeading}>{"Time Vesting"}</div>
                    {depositInfo ? (
                      <div className={style.textsubHeading}>
                        {depositInfo.vesting}
                      </div>
                    ) : (
                      <Skeleton
                        baseColor="#4d4370"
                        highlightColor="#00eaea"
                        height={20}
                        width={100}
                      />
                    )}
                  </div> */}
                </div>
              </div>

              {/* {buy === 2 && (
                <div className={style.lableSection}>
                  <div className={style.lablebttn}>
                    <button onClick={redeemACX}>
                      {loadingRedeem ? (
                        <div className={style.spinnerWrapper}>
                          <div className={style.spinner}></div>
                        </div>
                      ) : (
                        "Claim"
                      )}
                    </button>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleBond;
