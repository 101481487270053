import React from "react";
import style from "./loading.module.scss";

import Innerlogo from "../../assets/images/foooterlogo.svg";

const LoadingScreen = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.nav}>
          <div className={style.logo}>
            <img src={Innerlogo} alt="Logo" />
          </div>
        </div>
        <div className={style.innerlogo}>
          <div className={style.blobs}>
            <div className={style.blobCenter}></div>
            <div className={style.blob}></div>
            <div className={style.blob}></div>
            <div className={style.blob}></div>
            <div className={style.blob}></div>
            <div className={style.blob}></div>
            <div className={style.blob}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingScreen;
