import React from "react";
import SidebarComponent from "../_Common/Sidebar/Sidebar";
import style from "./trade.module.scss";
import Crypto from "../../assets/images/crypto.svg";
import Stock from "../../assets/images/stock.svg";
import Forex from "../../assets/images/forex.svg";
import Commodities from "../../assets/images/commodities.svg";
import Indexicon from "../../assets/images/indexicon.svg";
import CryptoComponent from "./crypto/Crypto";
import StocksComponent from "./stocks/Stocks";
import ForexComponent from "./forex/Forex";
import CommoditiesComponent from "./commodities/Commodities";
import IndexComponent from "./IndexComponent/IndexCompo";
const Pathlist = [
  {
    title: "Crypto",
    Icon: Crypto,
    path: "/trade/crypto",
  },
  {
    title: "Stocks",
    Icon: Stock,
    path: "/trade/stocks",
  },

  {
    title: "Forex",
    Icon: Forex,
    path: "/trade/forex",
  },
  {
    title: "Commodities",
    Icon: Commodities,
    path: "/trade/commodities",
  },
  {
    title: "Index",
    Icon: Indexicon,
    path: "/trade/index",
  },
];
const Trade = ({
  history,
  match: {
    params: { id },
  },
}) => {
  return (
    <>
      <div className={style.container}>
        <div className={style.sidebar}>
          <SidebarComponent Pathlist={Pathlist} id={id} history={history} />
        </div>
        <div className={style.rightsection}>
          {id === "crypto" ? (
            <CryptoComponent />
          ) : id === "stocks" ? (
            <StocksComponent />
          ) : id === "forex" ? (
            <ForexComponent />
          ) : id === "commodities" ? (
            <CommoditiesComponent />
          ) : id === "index" ? (
            <IndexComponent />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Trade;
