const Data = [
  {
    id: 1,
    imgtext1: "BTCa/USDa",
    imgtext: "56523.51 USDb",
  },
  {
    id: 2,
    imgtext1: "BTCa/USDa",
    imgtext: "56523.51 USDb",
  },
  {
    id: 3,
    imgtext1: "BTCa/USDa",
    imgtext: "56523.51 USDb",
  },
  {
    id: 4,
    imgtext1: "BTCa/USDa",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgtext1: "BTCa/USDa",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgtext1: "BTCa/USDa",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgtext1: "BTCa/USDa",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgtext1: "BTCa/USDa",
    imgtext: "56523.51 USDb",
  },
];
export default Data;
