import Bitcoin from "../../../assets/images/bitcoin.svg";
import Sorticon from "../../../assets/images/sorticon.svg";
import Uniswapicon from "../../../assets/images/uniswap.svg";
import Acxlogo from "../../../assets/images/acxlogos.svg";
import Pinteresticon from "../../../assets/images/pinterest.svg";
const Data = [
  {
    id: 1,
    imgpath: Bitcoin,
    earned: "Earned",
    text: "0",
    apy: "APY",
    apytxt: "38.53%",
    totalstaked: "Total Staked",
    tstext: "$475,365,113",
    endsin: "EndsIn",
    endsintext: "-",
    arrowicon: Sorticon,
  },
  {
    id: 2,
    imgpath: Acxlogo,
    earned: "Earned",
    text: "0",
    apy: "APY",
    apytxt: "38.53%",
    totalstaked: "Total Staked",
    tstext: "$475,365,113",
    endsin: "EndsIn",
    endsintext: "40x",
    arrowicon: Sorticon,
  },
  {
    id: 3,
    imgpath: Uniswapicon,
    earned: "Earned",
    text: "0",
    apy: "APY",
    apytxt: "38.53%",
    totalstaked: "Total Staked",
    tstext: "$475,365,113",
    endsin: "EndsIn",
    endsintext: "-",
    arrowicon: Sorticon,
  },
  {
    id: 4,
    imgpath: Pinteresticon,
    earned: "Earned",
    text: "0",
    apy: "APY",
    apytxt: "38.53%",
    totalstaked: "Total Staked",
    tstext: "$475,365,113",
    endsin: "EndsIn",
    endsintext: "40x",
    arrowicon: Sorticon,
  },
];

export default Data;
