// import Bitcoin from "../../../../assets/images/bitcoin.svg";
// import litecoin from "../../../../assets/images/litecoin.svg";
// import Ethrem from "../../../../assets/images/ethrm.svg";
// import Bitcoing from "../../../../assets/images/bitcoingreen.svg";
// import currency from "../../../../assets/images/currency.svg";

const TableData = [
  {
    id: 1,
    // img: Bitcoin,
    asset: "USDa",
    price: "$-338,203,523.60",
    change: "21.01%",
    debt: "21.01%",
  },
  {
    id: 2,
    // img: currency,
    asset: "BTCa",
    price: "$-338,203,523.60",
    change: "21.01%",
    debt: "21.01%",
  },
  {
    id: 3,
    // img: Ethrem,
    asset: "USDa",
    price: "$-338,203,523.60",
    change: "21.01%",
    debt: "21.01%",
  },
  {
    id: 4,
    // img: Bitcoing,
    asset: "BTCa",
    price: "$-338,203,523.60",
    change: "21.01%",
    debt: "21.01%",
  },
  {
    id: 5,
    // img: Bitcoin,
    asset: "USDa",
    price: "$-338,203,523.60",
    change: "21.01%",
    debt: "21.01%",
  },
  {
    id: 6,
    // img: Bitcoin,
    asset: "BTCa",
    price: "$-338,203,523.60",
    change: "21.01%",
    debt: "21.01%",
  },
];
export default TableData;
