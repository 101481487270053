import Bitcoin from "../../../assets/images/bitcoin.svg";
import Acxlogo from "../../../assets/images/acxlogos.svg";
import Pinicon from "../../../assets/images/pinterest.svg";
import Bellicon from "../../../assets/images/bellicon.svg";
const Data = [
  {
    id: 1,
    imgpath: Bitcoin,
    belt: "beltBTC",
    uses: "Uses: Belt",
    buytoken: "Buy token",
    wallet: "Wallet",
    text: "0",
    deposit: "Deposit",
    dtext: "0",
    apy: "APY",
    apytext: "3.48%",
    daily: "Daily",
    dailytext: "0.0095%",
    tvl: "TVL",
    tvltext: "$17.91M",
  },
  {
    id: 2,
    imgpath: Acxlogo,
    belt: "ACX Vault",
    uses: "Uses:ACX",
    buytoken: "Buy token",
    wallet: "Wallet",
    text: "0",
    deposit: "Deposit",
    dtext: "0",
    apy: "APY",
    apytext: "3.48%",
    daily: "Daily",
    dailytext: "0.0095%",
    tvl: "TVL",
    tvltext: "$17.91M",
  },
  {
    id: 3,
    imgpath: Bellicon,
    belt: "beltBTC",
    uses: "Uses: Belt",
    buytoken: "Buy token",
    wallet: "Wallet",
    text: "0",
    deposit: "Deposit",
    dtext: "0",
    apy: "APY",
    apytext: "3.48%",
    daily: "Daily",
    dailytext: "0.0095%",
    tvl: "TVL",
    tvltext: "$17.91M",
  },
  {
    id: 4,
    imgpath: Pinicon,
    belt: "beltBTC",
    uses: "Uses: Belt",
    buytoken: "Buy token",
    wallet: "Wallet",
    text: "0",
    deposit: "Deposit",
    dtext: "0",
    apy: "APY",
    apytext: "3.48%",
    daily: "Daily",
    dailytext: "0.0095%",
    tvl: "TVL",
    tvltext: "$17.91M",
  },
];

export default Data;
