import React, { useState } from "react";
import style from "./BlockChainModal.module.scss";
import POLYGON from "../../../../../assets/images/POLYGON1.svg";
import BSC from "../../../../../assets/images/BSC.svg";
import VELAS from "../../../../../assets/images/VELAS.svg";

function ModalStyle() {
  const [connect, setConnect] = useState(0);
  return (
    <>
      <div className={style.container}>
        <div className={style.Wrapper}>
          <div className={style.CardWrapper}>
            <div
              className={`${style.Card1} ${connect === 0 ? style.active : ""}`}
              onClick={() => setConnect(0)}
            >
              <img src={POLYGON} alt="Polygon" />
              <div className={style.text}>POLYGON</div>
            </div>
            <div
              className={`${style.Card2} ${connect === 1 ? style.active : ""}`}
              onClick={() => setConnect(1)}
            >
              <img src={BSC} alt="Polygon" />
              <div className={style.text}>BSC</div>
            </div>
          </div>
          <div className={style.CardWrapper2}>
            <div
              className={`${style.Card3}  ${connect === 2 ? style.active : ""}`}
              onClick={() => setConnect(2)}
            >
              <img src={VELAS} alt="Polygon" />
              <div className={style.text}>VELAS</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalStyle;
