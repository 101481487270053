import React, { useState } from "react";
import style from "./Table.module.scss";
import TableData from "./data";

function Table() {
  const [data] = useState(TableData);
  const [active, setactive] = useState(1);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.innerwrapper}>
            <div
              className={`${style.liquidity} ${
                active === 1 ? style.active : ""
              }`}
              onClick={() => setactive(1)}
            >
              Open Positions
            </div>
            <div
              className={`${style.zap} ${active === 2 ? style.active : ""}`}
              onClick={() => setactive(2)}
            >
              Expired Positions
            </div>
          </div>
          {active === 1 && (
            <div className={style.tableContainer}>
              <div className={style.tableWrapper}>
                <div className={style.subWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <td>Market</td>
                        <td>Position</td>
                        <td>Expiry</td>
                        <td>Price</td>
                        <td>Avg Price</td>
                        <td>Mark Price</td>
                        <td>P&L</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((win) => {
                        const {
                          id,
                          price,
                          change,
                          asset,

                          debt,
                     
                        } = win;
                        return (
                          <tr key={id} className={style.winrow}>
                            <td>
                              <b className={style.FontHeading}>{asset}</b>
                            </td>
                            <td>
                              <b>{price}</b>
                            </td>
                            <td>
                              <b>{change}</b>
                            </td>
                            <td>
                              <b>{debt}</b>
                            </td>
                            <td>
                              <b>{debt}</b>
                            </td>
                            <td>
                              <b>{debt}</b>
                            </td>
                            <td>
                              <b>{debt}</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {active === 2 && (
            <div className={style.tableContainer}>
              <div className={style.tableWrapper}>
                <div className={style.subWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <td>Market</td>
                        <td>Position</td>
                        <td>Expiry</td>
                        <td>Price</td>
                        <td>Avg Price</td>
                        <td>Mark Price</td>
                        <td>P&L</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((win) => {
                        const {
                          id,
                          price,
                          change,
                          asset,

                          debt,
                      
                        } = win;
                        return (
                          <tr key={id} className={style.winrow}>
                            <td>
                              <b className={style.FontHeading}>{asset}</b>
                            </td>
                            <td>
                              <b>{price}</b>
                            </td>
                            <td>
                              <b>{change}</b>
                            </td>
                            <td>
                              <b>{debt}</b>
                            </td>
                            <td>
                              <b>{debt}</b>
                            </td>
                            <td>
                              <b>{debt}</b>
                            </td>
                            <td>
                              <b>{debt}</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Table;
