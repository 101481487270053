import React from "react";
import style from "./Withdraw.module.scss";

function Withdraw() {
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.textSection}>
            <div className={style.Heading}>Withdraw</div>
          </div>
          <div className={style.lableSection}>
            <div className={style.balance}>You Will get up to</div>
            <div className={style.balance1}>0</div>
            <div className={style.inputField}>
              <form>
                <div className={style.labelbtn}>
                  <input type="text" name="text" placeholder="Enter Amount" />
                  <button className={`${style.btn1} `}>Max</button>
                </div>
                <button className={`${style.btn2} `}>Claim</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Withdraw;
