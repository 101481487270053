import React from "react";
import MintTab from "./Tabs/Tab";
import Heading from "../InstantLoan/Heading/Heading";
import style from "./MintBurn.module.scss";

function MintBurn() {
  return (
    <>
      <div className={style.container}>
        <Heading />
        <MintTab />
      </div>
    </>
  );
}

export default MintBurn;
