import React, { useState } from "react";
import style from "./Mint.module.scss";
import NewScreen from "../NewScreen/NewScreen";
import useMint from "./useMint";
import useMintBurn from "../../useMintBurn";
import { useSelector } from "react-redux";

const Mint = () => {
  const { swapSelectedData } = useSelector((state) => state.swapReducer);
  const {

    wSACXtoSynthValue,
    changeHandler,
    tokenBalToWSACX,
    setAmount,
    amount,
    errorMessage,
    setErrorMessage,
    checkWrappedSACXTokenBalance,
    getTotalStakedCollateral
  } = useMint({ swapSelectedData });

  const [activeTab, setActiveTab] = useState(0);
  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    setAmount("");
  };
  return (
    <>
      <div className={style.container}>
        {activeTab === 0 && (
          <div className={`${style.wrapper} `}>
            <div className={style.topComp} onClick={() => handleActiveTab(1)}>
              <div className={style.Heading}>MINT MAX</div>
              <div className={style.subHeading}>
                Mint the maximum amount of {swapSelectedData?.name}
              </div>
            </div>
            <div
              className={style.bottomComp}
              onClick={() => handleActiveTab(2)}
            >
              <div className={style.Heading}>MINT CUSTOM</div>
              <div className={style.subHeading}>
                Mint a custom amount of {swapSelectedData?.name}
              </div>
            </div>
          </div>
        )}
        {activeTab === 2 || activeTab === 1 ? (
          <NewScreen
            handleActiveTab={handleActiveTab}
            mint={true}
            max={activeTab === 1 ? true : false}
        
            wSACXtoSynthValue={wSACXtoSynthValue}
            changeHandler={changeHandler}
            tokenBalToWSACX={tokenBalToWSACX}
            setAmount={setAmount}
            amount={amount}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            checkWrappedTokenBal={checkWrappedSACXTokenBalance}
            getTotalStakedCollateral={getTotalStakedCollateral}
          />
        ) : null}
      </div>
    </>
  );
};

export default Mint;
