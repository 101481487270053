import React, { useRef, useEffect } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import candleData from "./Dats";
import AreachardData from "./areachartdata";

// const resolutions = [60000, 300000, 900000, 3600000, 86400000, 604800000];

let ChartJS = ({ interval, isCandleStick, height }) => {
  const ref = useRef(null);
  useEffect(() => {
    initilizechart();
  }, [interval, isCandleStick]);
  const initilizechart = () => {
    if (!ref.current) {
      return;
    }

    ref.current.innerHTML = "";
    const chart = createChart(ref.current, {
      height: ref.current.offsetWidth,
      width: ref.current.offsetHeight,
      layout: {
        backgroundColor: "#362D5D",
        textColor: "rgba(255, 255, 255, 0.9)",
      },
      // grid: {
      //   vertLines: {
      //     color: "rgba(197, 203, 206, 0.5)",
      //   },
      //   horzLines: {
      //     color: "rgba(197, 203, 206, 0.5)",
      //   },
      // },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      // rightPriceScale: {
      //   borderColor: "rgba(197, 203, 206, 0.8)",
      // },
      // timeScale: {
      //   borderColor: "rgba(197, 203, 206, 0.8)",
      // },
    });
    if (isCandleStick) {
      const candleSeries = chart.addCandlestickSeries();
      candleSeries.setData(candleData);
    } else {
      const areaSeries = chart.addAreaSeries();
      areaSeries.setData(AreachardData);
    }
    new ResizeObserver((entries) => {
      if (entries.length === 0 || entries[0].target !== ref.current) {
        return;
      }
      const newRect = entries[0].contentRect;
      chart.applyOptions({ height: newRect.height, width: newRect.width });
    }).observe(ref.current);
  };
  return <div ref={ref} style={{ height }}></div>;
};

export default ChartJS;
