import React from "react";
import style from "./Portfolio.module.scss";
import SidebarComponent from "../_Common/Sidebar/Sidebar";
import Positions from "./Positions/Positions";
import Positionsimg from "../../assets/images/Positions.svg";
import History from "./History/History";
import HistoryLogo from "../../assets/images/HistoryLogo.svg";
import Reward from "./Reward/Reward";
import RewardLogo from "../../assets/images/RewardLogo.svg";
import Wallet from "./Wallet/Wallet";
import WalletLogo from "../../assets/images/WalletLogo.svg";
const Pathlist = [
  {
    title: "Wallet",
    Icon: WalletLogo,
    path: "/portfolio/wallet",
  },
  {
    title: "Positions",
    Icon: Positionsimg,
    path: "/portfolio/positions",
  },
  {
    title: "Reward",
    Icon: RewardLogo,
    path: "/portfolio/reward",
  },
  {
    title: "History",
    Icon: HistoryLogo,
    path: "/portfolio/history",
  },
];
function Portfolio({
  history,
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <div className={style.container}>
        <div className={style.sidebar}>
          <SidebarComponent Pathlist={Pathlist} id={id} history={history} />
        </div>
        <div className={style.rightsection}>
          {id === "wallet" && <Wallet />}
          {id === "positions" && <Positions />}
          {id === "reward" && <Reward />}
          {id === "history" && <History />}
        </div>
      </div>
    </>
  );
}

export default Portfolio;
