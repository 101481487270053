import React, { useState } from "react";
import style from "./Table.module.scss";
import CardData from "./data";
import Top from "../../../../assets/images/top3.svg";
import Bottom from "../../../../assets/images/bottom3.svg";

function Card() {
  const [data] = useState(CardData);
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <table cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>Asset Name</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />
                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>APY Rewards</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />

                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>Price</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />

                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>24h Change</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />

                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>Market Cap</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />

                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>24h Vol</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />

                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>30 Day Trend</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />

                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>Balance</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />

                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>Value</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />

                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
              <th>
                <div className={style.headingWrapper}>
                  <div className={style.heading}>Portfolio %</div>
                  <div className={style.imgWrapper}>
                    <img src={Top} alt="top" />

                    <img src={Bottom} alt="Bottom" />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          {data.map((carddata) => {
            const {
              id,
              img,
              img2,
              heading1,
              heading2,
              heading3,
              heading4,
              heading5,
              heading6,
              heading7,
              heading8,
              heading9,
            } = carddata;
            return (
              <tbody>
                <tr key={id}>
                  <td>
                    <div className={style.firstimage}>
                      <img src={img} alt="Bitcoin" />
                    </div>
                    <div className={style.HeadingWrapper}>
                      <div className={style.Heading}>{heading1}</div>
                      <div className={style.subHeading}>{heading2}</div>
                    </div>
                  </td>
                  <td></td>
                  <td>{heading3}</td>
                  <td>{heading4}</td>
                  <td>{heading5}</td>
                  <td>{heading6}</td>
                  <td>
                    <img className={style.bargraph} src={img2} alt="chart" />
                  </td>
                  <td>{heading7}</td>
                  <td>{heading8}</td>
                  <td>{heading9}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </div>
  );
}

export default Card;
