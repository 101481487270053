import React, { useState } from "react";
import style from "./vaults.module.scss";
import VaultsData from "./Data";
const Vaults = () => {
  const [Data] = useState(VaultsData);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <span>Vaults</span>
            <p>
              ACX Vaults act comparably to a mix between a hedge fund and a
              savings account. Users can deposit Crypto into the vault and sit
              back as the vault leverages automation to execute a variety of
              strategies to maximize yield.
            </p>
          </div>
          <div className={style.stakesection}>
            <div className={style.leftsection}>
              <p>200 Vaults</p>
            </div>
            <div className={style.live}>
              <p>TVL $323.48M</p>
            </div>
            <div className={style.sortsection}>
              <p>ACX Buyback $2.00k/day</p>
            </div>
            <div className={style.searchfield}>
              <p>Deposited</p>
              <p>0.00</p>
            </div>
          </div>
          <div className={style.boxsection}>
            <div className={style.innerboxsection}>
              {Data.map((currentElm) => {
                const {
                  id,
                  imgpath,
                  belt,
                  uses,
                  buytoken,
                  wallet,
                  text,
                  deposit,
                  dtext,
                  apy,
                  apytext,
                  daily,
                  dailytext,
                  tvl,
                  tvltext,
                } = currentElm;
                return (
                  <div className={style.box} key={id}>
                    <div className={style.firstsection}>
                      <img src={imgpath} alt="BTC" />
                    </div>
                    <div className={style.firstsection}>
                      <div className={style.firstdiv}>
                        <p>{belt}</p>
                        <span>{uses}</span>
                      </div>
                      <b>{buytoken}</b>
                    </div>
                    <div className={style.firstsection}>
                      <span>{wallet}</span>
                      <p>{text}</p>
                    </div>
                    <div className={style.firstsection}>
                      <span>{deposit}</span>
                      <p>{dtext}</p>
                    </div>
                    <div className={style.firstsection}>
                      <span>{apy}</span>
                      <p>{apytext}</p>
                    </div>
                    <div className={style.firstsection}>
                      <span>{daily}</span>
                      <p>{dailytext}</p>
                    </div>
                    <div className={style.firstsection}>
                      <span>{tvl}</span>
                      <p>{tvltext}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vaults;
