import React from "react";
import Table from "./table/Table";

function History() {
  return (
    <div>
      <Table />
    </div>
  );
}

export default History;
