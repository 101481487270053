import React from "react";
import Card from "./Card/Card";
import Header from "./Header/Header";

function Reward() {
  return (
    <>
      <Header />
      <Card />
    </>
  );
}

export default Reward;
