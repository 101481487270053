import React, { useState } from "react";
import Heading from "./Heading/Heading";
import MintTab from "./Tabs/MintTab";
import style from "./MintBurn.module.scss";
import backIcon from "../../../assets/images/awesome-arrow-left.svg";
import useMintBurn from "./useMintBurn";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

function MintBurn() {
  const {
    active,
    selectSingleHandler,
    setActive,
    loadingSwapData,
    swapData,
    checkSwapToken,
    account,
  } = useMintBurn();
  const { swapSelectedData } = useSelector((state) => state.swapReducer);
  const renderLoading = (row, column) => {
    return (
      <>
        {[...Array(row).keys()].map((col) => (
          <tr>
            {[...Array(column).keys()].map((item) => (
              <td
                style={{
                  padding: "10px 10px 10px 10px",
                  width: "20vw",
                  minWidth: "80px",
                }}
              >
                <Skeleton
                  baseColor="#4d4370"
                  highlightColor="#00eaea"
                  height={30}
                />
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <Heading main={true} />
      <div className={style.container}>
        {active ? (
          <div className={style.Tablesection}>
            <div className={style.markettable}>
              <div className={style.innermarket}>
                <table>
                  <thead>
                    <tr>
                      <td>Assets</td>
                      <td>Name</td>
                      <td>Balance </td>
                      <td>ChainLink Price</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  {loadingSwapData ? (
                    renderLoading(4, 5)
                  ) : swapData?.length ? (
                    <tbody>
                      {swapData?.map((Tablevalue) => {
                        const {
                          contractAddress,
                          icon,
                          symbol,
                          name,
                          balance,
                          chainLinkPrice,
                          color,
                        } = Tablevalue;
                        return (
                          <div className={style.rowdiv} key={contractAddress}>
                            <tr>
                              <td>
                                {icon ? (
                                  <img src={icon} alt="Images" />
                                ) : (
                                  <div className={style.noImage}>
                                    {symbol[1]}
                                  </div>
                                )}
                                {symbol}
                              </td>
                              <td>{name}</td>
                              <td>{balance}</td>
                              <td>{chainLinkPrice}</td>
                              <td>
                                <button
                                  onClick={(e) => checkSwapToken(e, Tablevalue)}
                                >
                                  {"Mint&Burn"}
                                </button>
                              </td>
                            </tr>
                          </div>
                        );
                      })}
                    </tbody>
                  ) : (
                    <div className={style.noData}>
                      {!account
                        ? "First Connect to Wallet "
                        : "No Data Available"}
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={style.buttonWrapper}>
              <button onClick={() => setActive(true)}>
                <img src={backIcon} />
                <span>Back</span>
              </button>
            </div>

            <MintTab />
          </>
        )}
      </div>
    </>
  );
}

export default MintBurn;
