import React from "react";
import style from "./farming.module.scss";
const Farming = () => {
  return (
    <>
      <div className={style.farming}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <span>Farming</span>
          </div>
          <div className={style.Cards}>
            <div className={style.card}>
              <span>ACX</span>
              <p>$0.00</p>
              <label>Fully diluted market cap for ACX</label>
            </div>
            <div className={style.card}>
              <span>ACX</span>
              <p>$0.00</p>
              <label>Fully diluted market cap for ACX</label>
            </div>
            <div className={style.card}>
              <span>Curvepool USDa</span>

              <label>
                Rewards for holding curve-susd and staking it in the rewards
                contract
              </label>
              <span>Curvepool USDa</span>
              <p>000</p>
              <span>Curvepool USDa</span>
              <p>0%</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Farming;
