import React from "react";
import Heading from "./Heading/heading";
import Chart from "./Chart/ChartTab";
import Table from "./table/Table";
import ChartTable from "./ChartTable/Table";
import style from "./debt.module.scss";

function debt() {
  return (
    <div>
      <Heading />
      <Chart />
      <div className={style.container}>
        <ChartTable />
        <Table />
      </div>
    </div>
  );
}

export default debt;
