import { Link, useLocation } from "react-router-dom";
import Logo from "../../../assets/images/AcxLogo.svg";
import mobileNav from "../../../assets/images/mobileNav1.svg";
import React, { useEffect, useState } from "react";
import styles from "./navigation.module.scss";
import { slide as Menu } from "react-burger-menu";
import Modal from "../../Swap/popup/popup";
import ModalStyle from "../../Swap/ModalStyles/ModalStyle";
import AngleArrowIcon from "../../../assets/images/icon-dropdown.svg";
import { useActiveWeb3React } from "../../../Services/WalletConnection/useWalletConnect";
import {
  setAccountAddress,
  setBondTokens,
} from "../../../store/reducers/accountReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  ImportBUSDToken,
  importSACXToken,
  ImportToken,
  ImportWrapSACX,
} from "../../../Services/WalletConnection/ImportCustomToken";
import {
  ACX_CONTRACT,
  BUSD_CONTRACT,
  NetworkTest,
  NetworkTestChainId,
  SACX_CONTRACT,
  WRAPPED_SWAP_CONTRACT,
} from "../../../utils/contract";
import { changeNetwork } from "../../../Services/WalletConnection/changeNetwork";

const Navigation = () => {
  const { account, chainId } = useActiveWeb3React();
  const { pathname } = useLocation();
  const [menuOpenState, setMenuOpenState] = useState(false);

  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);
  const handleMenu = () => {
    setMenuOpenState(!menuOpenState);
  };
  const [popupVisible, setPopupVisible] = useState(false);
  const [error, setError] = useState();
  const accountsAddress = useSelector(
    (state) => state.accountsReducer.accountsAddress
  );
  const dispatch = useDispatch();
  useEffect(async () => {
    if (account || chainId) {
      // handleNetworkSwitch();
      const check = accountsAddress?.some(
        (add) =>
          add.acxAddress === ACX_CONTRACT &&
          add.sAcxAddress === SACX_CONTRACT &&
          add.wrappedSACX === WRAPPED_SWAP_CONTRACT &&
          add.accountAddress === account
      );
      if (!accountsAddress.length || !check) {
        if (chainId === NetworkTestChainId) {
          dispatch(
            setAccountAddress({
              acxAddress: ACX_CONTRACT,
              sAcxAddress: SACX_CONTRACT,
              accountAddress: account,
              wrappedSACX: WRAPPED_SWAP_CONTRACT,
            })
          );
          dispatch(
            setBondTokens({
              bondAddress: BUSD_CONTRACT,
              accountAddress: account,
            })
          );
          await ImportToken();
          await importSACXToken();
          await ImportBUSDToken();
          await ImportWrapSACX();
        }

        // customTokenTime=setTimeout(window.location.reload(), 9000);
        // window.ethereum.request({ method: 'eth_requestAccounts' })
      }
    }
  }, [account, chainId]);
  const togglePopup = (e) => {
    e.preventDefault();
    setPopupVisible((prev) => !prev);
  };
  const toggle = () => {
    setPopupVisible(!popupVisible);
  };
  const handleNetworkSwitch = async () => {
    try {
      setError();
      await changeNetwork({ networkName: NetworkTest, setError });
    } catch (e) {
      console.log(error);
    }
  };


  return (
    <>
      <Modal visible={popupVisible} onClose={toggle}>
        <ModalStyle toggle={toggle} />
      </Modal>
      {/* Mobile Menu*/}
      <Menu
        right
        disableAutoFocus
        customBurgerIcon={false}
        isOpen={menuOpenState}
        onStateChange={(state) => stateChangeHandler(state)}
        itemListElement="div"
      >
        <div className="bm-item">
          <div className="headerLogo">
            <img src={Logo} alt="logo" />
          </div>

          <div className="bm-list">
            <ul>
              <li className="bm-item-list mainlist">
                <label for="btn-1" className="market-btn">
                  <button className="styleimg" />
                  Market
                  <img src={AngleArrowIcon} alt="Arrow" />
                </label>
                <input type="checkbox" id="btn-1" />
                <ul onClick={handleMenu}>
                  <li>
                    <Link to="/market/crypto">Crypto</Link>
                  </li>
                  <li>
                    <Link to="/market/stocks">Stocks</Link>
                  </li>
                  <li>
                    <Link to="/market/forex">Forex</Link>
                  </li>
                  <li>
                    <Link to="/market/commodities">Commodities</Link>
                  </li>
                </ul>
              </li>
              <li className="bm-item-list mainlist">
                <label for="btn-2" className="market-btn">
                  Trade
                  <img src={AngleArrowIcon} alt="Arrow" />
                </label>
                <input type="checkbox" id="btn-2" />
                <ul onClick={handleMenu}>
                  <li>
                    <Link to="/trade/crypto">Crypo</Link>
                  </li>
                  <li>
                    <Link to="/trade/stocks">Stocks</Link>
                  </li>
                  <li>
                    <Link to="/trade/forex">Forex</Link>
                  </li>
                  <li>
                    <Link to="/trade/commodities">Commodities</Link>
                  </li>
                  <li>
                    <Link to="/trade/index">Index</Link>
                  </li>
                </ul>
              </li>

              <li className="bm-item-list mainlist">
                <label for="btn-3" className="market-btn">
                  Swap
                  <img src={AngleArrowIcon} alt="Arrow" />
                </label>
                <input type="checkbox" id="btn-3" />
                <ul onClick={handleMenu}>
                  <li>
                    <Link to="/swap/wrap-swap">Wrapped Swap</Link>
                  </li>
                  <li>
                    <Link to="/swap/allow-swap">Synth Swap</Link>
                  </li>

                  {/* <li>
                    <Link to="/swap/cross-swap">Cross Swap</Link>
                  </li> */}
                </ul>
              </li>
              <li className="bm-item-list mainlist">
                <label for="btn-4" className="market-btn">
                  Stake
                  <img src={AngleArrowIcon} alt="Arrow" />
                </label>
                <input type="checkbox" id="btn-4" />
                <ul onClick={handleMenu}>
                  <li>
                    <Link to="/stake/stake">Stake</Link>
                  </li>
                  {/* <li>
                    <Link to="/stake/mint-burn">Mint & Burn</Link>
                  </li>
                  <li>
                    <Link to="/stake/earn">Earn</Link>
                  </li>
                  <li>
                    <Link to="/stake/debt">Debt</Link>
                  </li>
                  <li>
                    <Link to="/stake/instantLoan">Instant Loan</Link>
                  </li> */}
                  <li>
                    <Link to="/stake/bond">Bond</Link>
                  </li>
                </ul>
              </li>
              <li className="bm-item-list" onClick={handleMenu}>
                <Link to="/liquidity">Liquidity</Link>
              </li>
              <li className="bm-item-list mainlist">
                <label for="btn-6" className="market-btn">
                  Options
                  <img src={AngleArrowIcon} alt="Arrow" />
                </label>
                <input type="checkbox" id="btn-6" />
                <ul>
                  <li className="bm-item-list mainlist">
                    <label for="btn-7" className="market-btn listcolor">
                      Crypto
                      <img src={AngleArrowIcon} alt="Arrow" />
                    </label>
                    <input type="checkbox" id="btn-7" />
                    <ul onClick={handleMenu}>
                      <li>
                        <Link to="/options/crypto/btc">BTC-USD</Link>
                      </li>
                      <li>
                        <Link to="/options/crypto/ethm">ETH-USD</Link>
                      </li>
                      <li>
                        <Link to="/options/crypto/ltc">LTC-USD</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="bm-item-list mainlist">
                    <label for="btn-8" className="market-btn listcolor">
                      Stocks
                      <img src={AngleArrowIcon} alt="Arrow" />
                    </label>
                    <input type="checkbox" id="btn-8" />
                    <ul onClick={handleMenu}>
                      <li>
                        <Link to="/options/stocks/dis">DIS</Link>
                      </li>
                      <li>
                        <Link to="/options/stocks/bst">BST</Link>
                      </li>
                      <li>
                        <Link to="/options/stocks/pild">PILD</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="bm-item-list mainlist">
                    <label for="btn-9" className="market-btn listcolor">
                      Forex
                      <img src={AngleArrowIcon} alt="Arrow" />
                    </label>
                    <input type="checkbox" id="btn-9" />
                    <ul onClick={handleMenu}>
                      <li>
                        <Link to="/options/forex/euro">EURO USD</Link>
                      </li>
                      <li>
                        <Link to="/options/forex/usd">USD CAD</Link>
                      </li>
                      <li>
                        <Link to="/options/forex/usdjpy">USD JPY</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="bm-item-list mainlist">
                    <label for="btn-10" className="market-btn listcolor">
                      Commodities
                      <img src={AngleArrowIcon} alt="Arrow" />
                    </label>
                    <input type="checkbox" id="btn-10" />
                    <ul onClick={handleMenu}>
                      <li>
                        <Link to="/options/commodities/xauu">XAUUSD</Link>
                      </li>
                      <li>
                        <Link to="/options/commodities/gold">GOLD</Link>
                      </li>
                      <li>
                        <Link to="/options/forex/silver">SILVER</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="bm-item-list mainlist">
                <label for="btn-11" className="market-btn">
                  Acx
                  <img src={AngleArrowIcon} alt="Arrow" />
                </label>
                <input type="checkbox" id="btn-11" />
                <ul onClick={handleMenu}>
                  <li>
                    <Link to="/acx/farms">Farms</Link>
                  </li>
                  <li>
                    <Link to="/acx/pool">Pool</Link>
                  </li>
                  <li>
                    <Link to="/acx/vaults">Vaults</Link>
                  </li>
                </ul>
              </li>
              <li className="bm-item-list mainlist">
                <label for="btn-5" className="market-btn">
                  Portfolio
                  <img src={AngleArrowIcon} alt="Arrow" />
                </label>
                <input type="checkbox" id="btn-5" />
                <ul onClick={handleMenu}>
                  <li>
                    <Link to="/portfolio/wallet">Wallet</Link>
                  </li>
                  <li>
                    <Link to="/portfolio/positions">Positions</Link>
                  </li>
                  <li>
                    <Link to="/portfolio/reward">Reward</Link>
                  </li>
                  <li>
                    <Link to="/portfolio/history">History</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </Menu>
      {/*Pre Header */}

      {/*Main Header */}
      <div className={styles.container}>
        {/*left side of navbar */}
        <div className={styles.leftWrapper}>
          <div className={styles.leftContainer}>
            <Link to="/">
              <img className={styles.logo} src={Logo} alt="logo" />
            </Link>
          </div>
          <div className={styles.rightMenuWrapper}>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/market/crypto" ? styles.active : ""
              }`}
            >
              <Link to="/market/crypto">Market</Link>
              {pathname.includes("market") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>

            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/trade/crypto" ? styles.active : ""
              }`}
            >
              <Link to="/trade/crypto">Trade</Link>
              {pathname.includes("trade") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/wrap-swap" ? styles.active : ""
              }`}
            >
              <Link to="/swap/wrap-swap">Swap</Link>
              {pathname === "/swap" ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/stake" ? styles.active : ""
              }`}
            >
              <Link to="/stake/stake">Stake</Link>
              {pathname.includes("stake") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/liquidity" ? styles.active : ""
              }`}
            >
              <Link to="/liquidity">Liquidity</Link>
              {pathname === "/liquidity" ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/options/crypto/btc" ? styles.active : ""
              }`}
            >
              <Link to="/options/crypto/btc">Options</Link>
              {pathname.includes("options") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/acx/farms" ? styles.active : ""
              }`}
            >
              <Link to="/acx/farms">ACX</Link>
              {pathname.includes("acx") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/portfolio/positions" ? styles.active : ""
              }`}
            >
              <Link to="/portfolio/wallet">Portfolio</Link>
              {pathname.includes("portfolio") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
          </div>
        </div>
        {/*right side of navbar */}
        <div className={styles.btnMenuWrapper}>
          <div className={styles.menubtn} onClick={togglePopup}>
            {account
              ? `${account.substring(0, 6)}...${account.substring(
                  account.length - 4
                )}`
              : " Connect Wallet"}
          </div>
        </div>
      </div>
      <div>
        <div className={styles.mainmobileNav}>
          <Link to="/">
            <img className={styles.logo} src={Logo} alt="logo" />
          </Link>

          <div className={styles.RightWrapper}>
            <div className={styles.menubtn} onClick={togglePopup}>
              {account
                ? `${account.substring(0, 6)}...${account.substring(
                    account.length - 4
                  )}`
                : " Connect Wallet"}
            </div>
            <div
              onClick={() => setMenuOpenState(!menuOpenState)}
              className={styles.btnbtn}
            >
              <img src={mobileNav} alt="mobileNav" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
