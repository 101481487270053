import React from "react";

export default function VideoPoster({ loading, onStart, onEnd }) {
  return (
    <video
      style={{ display: `${loading ? "none" : "block"}` }}
      loop
      autoPlay
      muted
      controls={false}
      playsInline
      preload={true}
      onLoadStart={onStart}
      onLoad={onEnd}
      onLoadedData={onEnd}
    >
      <source
        src="https://res.cloudinary.com/lazydev/video/upload/v1645783264/Comp_2_2.mp4"
        type="video/mp4"
      />
    </video>
  );
}
