import Bitcoin from "../../../../assets/images/currency2.svg";
import Polygon from "../../../../assets/images/Polygon12.svg";
import Ethereum3 from "../../../../assets/images/Ethereum3.svg";

const CardData = [
  {
    id: 1,
    img: Bitcoin,
    heading1: "BTC-USDa",
    heading2: " Round 1: Nov 20th to dec 10th (20d)",
    heading3: "500.0k",
    heading4: "49.0%",
  },
  {
    id: 2,
    img: Ethereum3,
    heading1: "BTC-USDa",
    heading2: " Round 1: Nov 20th to dec 10th (20d)",
    heading3: "500.0k",
    heading4: "49.0%",
  },
  {
    id: 3,
    img: Polygon,
    heading1: "BTC-USDa",
    heading2: " Round 1: Nov 20th to dec 10th (20d)",
    heading3: "500.0k",
    heading4: "49.0%",
  },
  {
    id: 4,
    img: Bitcoin,
    heading1: "BTC-USDa",
    heading2: " Round 1: Nov 20th to dec 10th (20d)",
    heading3: "500.0k",
    heading4: "49.0%",
  },
  {
    id: 5,
    img: Polygon,
    heading1: "BTC-USDa",
    heading2: " Round 1: Nov 20th to dec 10th (20d)",
    heading3: "500.0k",
    heading4: "49.0%",
  },
];
export default CardData;
