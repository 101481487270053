import {
  ACX_CONTRACT,
  BUSD_CONTRACT,
  SACX_CONTRACT,
  WRAPPED_SWAP_CONTRACT,
} from "../../utils/contract";

export const ImportToken = async () => {
  const tokenAddress = ACX_CONTRACT;
  const tokenSymbol = "ACX";
  const tokenDecimals = 9;
  const tokenImage = "https://ropsten.etherscan.io/images/main/empty-token.png";

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};

export const importSACXToken = async () => {
  const tokenAddress = SACX_CONTRACT;
  const tokenSymbol = "sACX";
  const tokenDecimals = 9;
  const tokenImage = "https://ropsten.etherscan.io/images/main/empty-token.png";

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};
export const ImportBUSDToken = async () => {
  const tokenAddress = BUSD_CONTRACT;
  const tokenSymbol = "BUSD";
  const tokenDecimals = 18;
  const tokenImage = "https://ropsten.etherscan.io/images/main/empty-token.png";

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};
export const ImportGenericBondToken = async (
  tokenAddress,
  tokenSymbol,
  tokenDecimals
) => {
  console.log(
    "tokenAddress,tokenSymbol,tokenDecimals",
    tokenAddress,
    tokenSymbol,
    tokenDecimals
  );
  // const tokenAddress = "0x226f8Da2171EBeE14849F4AED0D87724eFeE7A89";
  // const tokenSymbol = "BUSD";
  // const tokenDecimals = 18;
  const tokenImage = "https://ropsten.etherscan.io/images/main/empty-token.png";

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};


export const ImportWrapSACX = async () => {
  const tokenAddress = WRAPPED_SWAP_CONTRACT;
  const tokenSymbol = "wSACX";
  const tokenDecimals = 9;
  const tokenImage = "https://ropsten.etherscan.io/images/main/empty-token.png";

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};
