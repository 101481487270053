import React from "react";
import SidebarComponent from "./Sidebar/Sidebar";
const Options = ({
  history,
  match: {
    params: { id },
  },
}) => {
  return (
    <>
      <SidebarComponent id={id} history={history} />
    </>
  );
};

export default Options;
