import Apple from "../../../assets/images/apple.svg";
import Microsoft from "../../../assets/images/Microsoft.svg";
import Alphabet from "../../../assets/images/gmail.svg";
import Amazon from "../../../assets/images/Amazon.svg";
import Tesla from "../../../assets/images/Tesla.svg";
const Data = [
  {
    id: 1,
    imgpath: Apple,
    imgtext1: "Apple",
    imgtext: "56523.51 USDb",
  },
  {
    id: 2,
    imgpath: Microsoft,
    imgtext1: "Microsoft",
    imgtext: "56523.51 USDb",
  },
  {
    id: 3,
    imgpath: Alphabet,
    imgtext1: "Gmail",
    imgtext: "56523.51 USDb",
  },
  {
    id: 4,
    imgpath: Amazon,
    imgtext1: "Amazon",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Tesla,
    imgtext1: "Tesla",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Apple,
    imgtext1: "Apple",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Microsoft,
    imgtext1: "Microsoft",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Apple,
    imgtext1: "Apple",
    imgtext: "56523.51 USDb",
  },
];
export default Data;
