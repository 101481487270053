import React from "react";
import style from "./zap.module.scss";

function RewardCalculator() {
  return (
    <>
      <div className={style.container}>
        <div className={style.mainwrapper}>
          <div className={style.wrapper}>
            <div className={style.inputwrapper}>
              <div className={style.inptudivs}>
                <div className={style.NavWrapper}>
                  <span>Zapin & Mint</span>
                  <form action="/">
                    <div className={style.Select}>
                      <select>
                        <option value="0">USDa</option>
                        <option value="1">BTC</option>
                        <option value="2">ETH</option>
                        <option value="3">LITE</option>
                      </select>
                      <span>Max</span>
                    </div>
                    <button type="submit" value="Submit">
                      Mint
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className={style.lableSection}>
              <div className={style.textWrapper2}>
                <div className={style.textsubWrapper}>
                  <span>TX Setting</span>
                </div>
                <div className={style.textsubWrapper}>
                  <div className={style.textHeading}>
                    Your Initial investment
                  </div>
                  <div className={style.textsubHeading}>$0.00</div>
                </div>
                <div className={style.textsubWrapper1}>
                  <div className={style.textHeading}>Current wealth</div>
                  <div className={style.textsubHeading}>$0.00</div>
                </div>
                <div className={style.textsubWrapper2}>
                  <div className={style.textHeading}>
                    Time rewards estimation
                  </div>
                  <div className={style.textsubHeading}>0.00 ACX</div>
                </div>
                <div className={style.textsubWrapper3}>
                  <div className={style.textHeading}>Potential return</div>
                  <div className={style.textsubHeading}>$0.00</div>
                </div>
                <div className={style.textsubWrapper}>
                  <div className={style.textHeading}>
                    Potential number of lambos
                  </div>
                  <div className={style.textsubHeading}>0</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RewardCalculator;
