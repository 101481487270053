import React from "react";
import style from "./Assets.module.scss";

function Assets() {
  return (
    <>
      <div className={style.container}>
        <div className={style.heading}>
          Why trade <span>ACX</span> synthetic assets?
        </div>
        <div className={style.subHeading}>
          Synthetic assets provide exposure to an asset without holding the
          underlying resource. Synths offer many advantages, including reducing
          the friction when switching between trading assets and among different
          asset classes. For example, one can trade Apple shares to synthetic
          gold to Bitcoin. <span>ACX</span> is expanding the accessibility of
          certain assets and censorship resistance in one unified, easy-to-use,
          intuitive user interface.
        </div>
      </div>
    </>
  );
}

export default Assets;
