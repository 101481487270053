import Bitcoin from "../../../../assets/images/currency2.svg";
import Chart from "../../../../assets/images/Chart.svg";

const CardData = [
  {
    id: 1,
    img: Bitcoin,
    heading1: "BTC",
    heading2: "btc",
    heading3: "$54,689.35",
    heading4: "+ 4.24%",
    heading5: "$515B",
    heading6: "$51.9B",
    img2: Chart,
    heading7: "0 BTC",
    heading8: "$0.00",
    heading9: "0%",
  },
  {
    id: 2,
    img: Bitcoin,
    heading1: "BTC",
    heading2: "btc",
    heading3: "$54,689.35",
    heading4: "+ 4.24%",
    heading5: "$515B",
    heading6: "$51.9B",
    img2: Chart,
    heading7: "0 BTC",
    heading8: "$0.00",
    heading9: "0%",
  },
  {
    id: 3,
    img: Bitcoin,
    heading1: "BTC",
    heading2: "btc",
    heading3: "$54,689.35",
    heading4: "+ 4.24%",
    heading5: "$515B",
    heading6: "$51.9B",
    img2: Chart,
    heading7: "0 BTC",
    heading8: "$0.00",
    heading9: "0%",
  },
  {
    id: 4,
    img: Bitcoin,
    heading1: "BTC",
    heading2: "btc",
    heading3: "$54,689.35",
    heading4: "+ 4.24%",
    heading5: "$515B",
    heading6: "$51.9B",
    img2: Chart,
    heading7: "0 BTC",
    heading8: "$0.00",
    heading9: "0%",
  },
  {
    id: 5,
    img: Bitcoin,
    heading1: "BTC",
    heading2: "btc",
    heading3: "$54,689.35",
    heading4: "+ 4.24%",
    heading5: "$515B",
    heading6: "$51.9B",
    img2: Chart,
    heading7: "0 BTC",
    heading8: "$0.00",
    heading9: "0%",
  },
];
export default CardData;
