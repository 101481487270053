import React, { useState } from "react";
import style from "./Card.module.scss";
import CardData from "./data";

function Card() {
  const [data] = useState(CardData);
  return (
    <div className={style.container}>
      <div className={style.containerWrapper}>
        {data.map((carddata) => {
          const { id, img, heading1, heading2, heading3, heading4 } = carddata;
          return (
            <div className={style.wrapper} key={id}>
              <div className={style.textWrapper}>
                <div className={style.LeftWrapper}>
                  <div className={style.img}>
                    <img src={img} alt="Bitcoin" />
                  </div>
                  <div className={style.HeadingWrapper}>
                    <div className={style.Heading}>{heading1}</div>
                    <div className={style.subHeading}>{heading2}</div>
                  </div>
                </div>
                <div className={style.RightWrapper}>
                  <div className={style.heading}>{heading3}</div>
                  <div className={style.subHeading}>{heading4}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Card;
