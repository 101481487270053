import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  swapSelectedData: {},
  wrappedSACXTokenBalance: "",
  tolStakedCollateral: "",
};

export const SwapSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    resetSettings: (state) => initialState,
    setSelectedSwapData: (state, action) => {
      state.swapSelectedData = action.payload;
    },
    setWrappedSACXTokenBalance: (state, action) => {
      state.wrappedSACXTokenBalance = action.payload;
    },
    setTolStakedCollateral: (state, action) => {
      state.tolStakedCollateral = action.payload;
    },
  },
});

export const {
  setSelectedSwapData,
  setWrappedSACXTokenBalance,
  setTolStakedCollateral,
} = SwapSlice.actions;

export default SwapSlice.reducer;
