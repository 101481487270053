import React from "react";
import FramerRoute from "../../../_Common/FramerRoute";
import LoadingSkeleton from "../LoadingSkeleton/loadingSkeleton";
import style from "./Unstake.module.scss";
import useUnstake from "./useUnstake";

function Unstake() {
  const {
    approved,
    changeHandler,
    account,
    onApprove,
    onUnStake,
    state,
    loading,
    balance,
    sACXTokenbalance,
    getMax,
    max,
    ARP
  } = useUnstake();

  return (
    <FramerRoute>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.textSection}>
            <div className={style.Heading}>UnStake</div>
            <div className={style.subHeading}>{`Current ACX APR : ${ARP}%`}</div>
          </div>
          <div className={style.lableSection}>
            <div className={style.balanceWrapper}>
              {account && (
                <div className={style.balance}>{`ACX : ${
                  balance && account ? balance : "0.00"
                }`}</div>
              )}
              {account && (
                <div className={style.balance}>{`sACX: ${
                  sACXTokenbalance && account ? sACXTokenbalance : "0.00"
                }`}</div>
              )}
            </div>
            <div className={style.inputField}>
              <form>
                {approved > "0" && (
                  <div className={style.labelbtn}>
                    <input
                      type="number"
                      name="amount"
                      placeholder="Enter Amount"
                      min={0}
                      onChange={changeHandler}
                      value={state?.amount}
                    />

                    <button className={`${style.btn1} `} onClick={getMax}>
                      Max
                    </button>
                  </div>
                )}
                {state?.amountVal && (
                  <span className={style.errorText}>{state?.amountVal}</span>
                )}
                {approved === "0" && (
                  <button
                    className={`${style.btn2} ${!account && style.isdisabled} `}
                    onClick={onApprove}
                    disabled={account && loading}
                  >
                    {loading ? (
                      <div className={style.spinnerWrapper}>
                        <div className={style.spinner}></div>
                      </div>
                    ) : (
                      "Approve First"
                    )}
                  </button>
                )}
                {approved > "0" && (
                  <button
                    className={`${style.btn2} ${!account && style.isdisabled}`}
                    onClick={onUnStake}
                    disabled={account && loading}
                  >
                    {loading ? (
                      <div className={style.spinnerWrapper}>
                        <div className={style.spinner}></div>
                      </div>
                    ) : (
                      "UnStake"
                    )}
                  </button>
                )}
                {account && approved === null && <LoadingSkeleton />}
                {!account && approved === null && (
                  <div className={style.connectWallet}>
                    First Connect to Wallet
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </FramerRoute>
  );
}

export default Unstake;
