import React from "react";
import style from "./trending.module.scss";
import AngleArrowIcon from "../../../assets/images/icon-dropdown.svg";
import BarChart from "../../_Common/BarChart/BarChart";
const Trending = () => {
  return (
    <>
      <div className={style.trending}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <span>Trending</span>
          </div>
          <div className={style.Boxsection}>
            <div className={style.box}>
              <span>Total ACX Staked</span>
              <p>$0.00</p>
              <label>The total value of all staked ACX</label>
            </div>
            <div className={style.box}>
              <span>Total ACX Staked</span>
              <p>$0.00</p>
              <label>The total value of all staked ACX</label>
            </div>
            <div className={style.box}>
              <span>Total ACX Staked</span>
              <p>$0.00</p>
              <label>The total value of all staked ACX</label>
            </div>
            <div className={style.box}>
              <span>Total ACX Staked</span>
              <p>$0.00</p>
              <label>The total value of all staked ACX</label>
            </div>
          </div>
          <div className={style.graphsection}>
            <div className={style.graphbody}>
              <div className={style.graghrole}>
                <div className={style.leftheading}>
                  <span>$28,905.93</span>
                  <p>ACX Price</p>
                </div>
                <div className={style.rightheading}>
                  <ul>
                    <li>
                      <button className={style.activebtn}>24H</button>
                    </li>
                    <li>
                      <button>7D</button>
                    </li>
                    <li>
                      <button>1M</button>
                    </li>
                    <li>
                      <button>3M</button>
                    </li>
                    <li>
                      <button>6M</button>
                    </li>
                    <li>
                      <button>1Y</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={style.graph}>
                <BarChart defaultColor={true} />
              </div>
            </div>
          </div>
          <div className={style.Cards}>
            <div className={style.leftside}>
              <h6>Volume Sources</h6>
              <div className={style.select}>
                <div className={style.periodsection}>
                  <label>Period</label>
                  <div className={style.selectarea}>
                    <p>Month</p>
                    <img src={AngleArrowIcon} alt="Arrow" />
                  </div>
                </div>
                <div className={style.categorysection}>
                  <label>Category</label>
                  <div className={style.selectarea}>
                    <p>Usd Volume</p>
                    <img src={AngleArrowIcon} alt="Arrow" />
                  </div>
                </div>
              </div>
              <div className={style.selecttable}>
                <table>
                  <thead>
                    <tr>
                      <td>Partner</td>
                      <td>USD Volume</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Curve</td>
                      <td>$0.00</td>
                    </tr>
                    <tr>
                      <td>Curve</td>
                      <td>$0.00</td>
                    </tr>
                    <tr>
                      <td>Curve</td>
                      <td>$0.00</td>
                    </tr>
                    <tr>
                      <td>Curve</td>
                      <td>$0.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={style.rightside}>
              <div className={style.box}>
                <span>Total Number of Unique Traders</span>
                <p>0000</p>
                <label>Ethereum addresses that have traded ACX</label>
              </div>
              <div className={style.box}>
                <span>Total Number of Unique Traders</span>
                <p>0000</p>
                <label>Ethereum addresses that have traded ACX</label>
              </div>
            </div>
          </div>
          <div className={style.graphsection}>
            <div className={style.graphbody}>
              <div className={style.graghrole}>
                <div className={style.leftheading}>
                  <span>$28,905.93</span>
                  <p>ACX Price</p>
                </div>
                <div className={style.rightheading}>
                  <ul>
                    <li>
                      <button className={style.activebtn}>24H</button>
                    </li>
                    <li>
                      <button>7D</button>
                    </li>
                    <li>
                      <button>1M</button>
                    </li>
                    <li>
                      <button>3M</button>
                    </li>
                    <li>
                      <button>6M</button>
                    </li>
                    <li>
                      <button>1Y</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={style.graph}>
                <BarChart defaultColor={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trending;
