import React, { useState } from "react";
import style from "./stocks.module.scss";
import Grapheye from "../../../assets/images/grapheye.svg";
import Togglegraph from "../../../assets/images/togglegraph.svg";
import FullScreenIcon from "../../../assets/images/full-screen.svg";
import Candlechart from "../../ChartsAndGraph/Chart";
import TableData from "../../../Data/Trade/stocks/Data";
import UsdData from "../../../Data/Trade/stocks/usddata";
const Crypto = () => {
  const [Usda] = useState(UsdData);
  const [active] = useState(1);
  const [GraphIcon, setGraphIcon] = useState(true);
  const [Tdata] = useState(TableData);
  const [Buy, setSell] = useState(1);
  const [intervalIndex, setIndex] = useState(0);
  const [isCandleStick, setChartType] = useState(true);
  return (
    <>
      <div className={style.container}>
        <div className={style.graphcontainer}>
          <div className={style.hideandshow}>
            <div className={style.graphbar}>
              <div className={style.price}>
                <p>Usda</p>
              </div>
              <div className={style.price}>
                <span>Price</span>
                <p>56965.51</p>
              </div>
              <div className={style.price}>
                <span>24h Change</span>
                <p>5.32%</p>
              </div>
              <div className={style.price}>
                <span>Vol(24h)</span>
                <p>57.01</p>
              </div>
              <div className={style.price}>
                <span>Low(24h)</span>
                <p>0.0001772</p>
              </div>
              <div className={style.price}>
                <span>High(24h)</span>
                <p>0.0001901</p>
              </div>
              <div className={style.price}>
                <img
                  src={Grapheye}
                  alt="GrapEye"
                  onClick={() => setGraphIcon(!GraphIcon)}
                  className={`${style.navItem} ${
                    active === true ? style.active : ""
                  }`}
                />
              </div>
            </div>
            {GraphIcon === true && (
              <div className={style.graph}>
                <div className={style.innergraphbody}>
                  <div className={style.timeanddate}>
                    <ul>
                      <li>
                        <button onClick={() => setIndex(0)}>1min</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(1)}>5min</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(2)}>15min</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(3)}>1h</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(4)}>1d</button>
                      </li>
                      <li>
                        <button onClick={() => setIndex(5)}>1w</button>
                      </li>
                    </ul>
                  </div>
                  <div className={style.toggleicon}>
                    <ul>
                      <li>
                        <img
                          src={Togglegraph}
                          onClick={() => setChartType(!isCandleStick)}
                          alt="CandleChart"
                        />
                      </li>
                      <li>
                        <img src={FullScreenIcon} alt="FullScreen" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={style.innergraph}>
                  <Candlechart
                    interval={intervalIndex}
                    isCandleStick={isCandleStick}
                    height="329px"
                  />
                </div>
              </div>
            )}
            {GraphIcon === false && <></>}
          </div>
          <div className={style.Graphtable}>
            <div className={style.Graphbody}>
              <div className={style.GraphData}>
                <table>
                  <tr>
                    <td>Company</td>
                    <td></td>
                    <td>Market cap</td>
                    <td>Change</td>
                    <td>Amount</td>
                    <td>Your equity</td>
                  </tr>
                  {Tdata.map((currElm) => {
                    const {
                      id,
                      imgtext1,
                      imgpath,
                      imgtext,
                      marketcap,
                      change,
                      amount,
                      yourequity,
                    } = currElm;
                    return (
                      <tr key={id}>
                        <td>
                          <img src={imgpath} alt="Table" />
                          <span> {imgtext1}</span>
                        </td>
                        <td>{imgtext}</td>
                        <td>{marketcap}</td>
                        <td>{change}</td>
                        <td>{amount}</td>
                        <td>{yourequity}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className={style.usdtable}>
          <div className={style.header}>
            <span>Your Watch List</span>
          </div>
          <table>
            <tr>
              <td>Company</td>
              <td>Last Price</td>
            </tr>
            {Usda.map((UsdElement) => {
              const { id, imgpath, imgtext, imgtext1 } = UsdElement;
              return (
                <tr key={id}>
                  <td>
                    <img src={imgpath} alt="All Images" />
                    <span>{imgtext1}</span>
                  </td>
                  <td>{imgtext}</td>
                </tr>
              );
            })}
          </table>
        </div>
        <div className={style.buyandsell}>
          <div className={style.headingbuy}>
            <span
              onClick={() => setSell(1)}
              className={Buy === 1 ? style.active : ""}
            >
              Buy
            </span>
            <span
              onClick={() => setSell(2)}
              className={Buy === 2 ? style.active : ""}
            >
              Sell
            </span>
          </div>
          {Buy === 1 ? (
            <div className={style.buyingdata}>
              <div className={style.buyusd}>
                <p>Buy USDa</p>
                <span>Balance: 0.00</span>
              </div>
              <div className={style.amount}>
                <div className={style.inneramount}>Amount</div>
                <div className={style.amountinput}>
                  <div className={style.leftusd}>USDa</div>
                  <div className={style.rightusd}>
                    <input type="text" />
                  </div>
                </div>
              </div>
              <div className={style.progressbar}>
                <div className={style.carrier}>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>0%</span>
                    </div>
                    <div className={style.line}></div>
                  </div>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>25%</span>
                    </div>
                    <div className={style.line}></div>
                  </div>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>50%</span>
                    </div>
                    <div className={style.line}></div>
                  </div>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>75%</span>
                    </div>
                    <div className={style.line}></div>
                  </div>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>100%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.buyingbody}>
                <div className={style.buyinglist}>
                  <p>USDa Value</p>
                  <span>$0.00</span>
                </div>
                <div className={style.buyinglist}>
                  <p>Fee ?</p>
                  <span>$0.00002100</span>
                </div>
                <div className={style.buyinglist}>
                  <p>GAS Prce</p>
                  <span>$1</span>
                </div>
                <div className={style.buyingbtn}>
                  <button>Confirm Trade Now</button>
                </div>
              </div>
            </div>
          ) : Buy === 2 ? (
            <div className={style.buyingdata}>
              <div className={style.buyusd}>
                <p>Sell USDa</p>
                <span>Balance: 0.00</span>
              </div>
              <div className={style.amount}>
                <div className={style.inneramount}>Amount</div>
                <div className={style.amountinput}>
                  <div className={style.leftusd}>USDa</div>
                  <div className={style.rightusd}>
                    <input type="text" />
                  </div>
                </div>
              </div>
              <div className={style.progressbar}>
                <div className={style.carrier}>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>0%</span>
                    </div>
                    <div className={style.line}></div>
                  </div>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>25%</span>
                    </div>
                    <div className={style.line}></div>
                  </div>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>50%</span>
                    </div>
                    <div className={style.line}></div>
                  </div>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>75%</span>
                    </div>
                    <div className={style.line}></div>
                  </div>
                  <div className={style.innerone}>
                    <div className={style.circle}>
                      <span className={style.text}>100%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.buyingbody}>
                <div className={style.buyinglist}>
                  <p>USDa Value</p>
                  <span>$0.00</span>
                </div>
                <div className={style.buyinglist}>
                  <p>Fee ?</p>
                  <span>$0.00002100</span>
                </div>
                <div className={style.buyinglist}>
                  <p>GAS Prce</p>
                  <span>$1</span>
                </div>
                <div className={style.buyingbtn}>
                  <button>Confirm Trade Now</button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Crypto;
