import React, { useState, useEffect } from "react";
import style from "./PreSale.module.scss";
import MyTimer from "./timer";
// import Binance from "../../../../assets/images/Binance.svg";
import ETH from "../../../../assets/images/ETH.svg";
import ACX from "../../../../assets/images/currency.svg";
import TokenModal from "../ACXTokenModal";
import { getTokenValue } from "../../../../Services/Token.helper";
import { useActiveWeb3React } from "../../../../Services/WalletConnection/useWalletConnect";
import { useAlert } from "react-alert";
import { BUSD_CONTRACT } from "../../../../utils/contract";
import FramerRoute from "../../../_Common/FramerRoute";

function PreSale() {
  const alert = useAlert();
  const { account } = useActiveWeb3React();
  const [amountToggle, setAmountToggle] = useState(false);
  const [tokenValue, setTokenValue] = useState(null);
  const { chainId } = useActiveWeb3React();
  const BuyToken = async (e) => {
    e.preventDefault();
    if (account) {
      toggleAmount();
    } else {
      alert.show("First Connect Wallet", { type: "error" });
    }
  };
  const toggleAmount = () => {
    setAmountToggle(!amountToggle);
  };
  useEffect(async () => {
    getTokenVal();
  }, [chainId,account]);
  const getTokenVal = async () => {
    const tokenVal = await getTokenValue(BUSD_CONTRACT);
    setTokenValue(tokenVal);
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.HeadingWrapper}>
          <div className={style.heading}>
            <span>Pre-Sale</span> Token
          </div>
          <div className={style.subHeading}>
            The pre-sale link will be live for 15 days prior to launch.
          </div>
        </div>
        <div className={style.CardWrapper}>
          <div className={style.leftSide} data-aos="fade-right">
            <div className={style.card}>
              <img src={ACX} alt="ACX" />
            </div>
          </div>
          <div className={style.rightSide} data-aos="fade-left">
            <div className={style.card}>
              <div className={style.cardHeading}>Pre-Sale Ends In</div>
              <div>
                <MyTimer />
              </div>
              <div className={style.cardContainer}>
                <div className={style.heading10}>
                  <div className={style.containerHeading}>TOKEN PRICE:</div>
                  <div className={style.containersubheading}>
                    1 ACX = {Number(tokenValue)?.toFixed(4)}{" BUSD"}
                  </div>
                </div>
                <div>
                  <div className={style.containerHeading}>WE ACCEPT:</div>
                  <div className={style.imgWrapper}>
                    <img src={ETH} alt="ETH" />
                    {/* <img src={Binance} alt="Binance" /> */}
                  </div>
                </div>
                <div>
                  <div className={style.containerHeading}>
                    TOKEN AVAILABLE ON PRE-SALE:
                  </div>
                  <div className={style.containersubheading}>
                    90,000,000 USDa
                  </div>
                </div>
              </div>
              <div className={style.border}>
                <div className={style.buttonFlex}>
                  <button className={style.cardButton} onClick={BuyToken}>
                    Buy Tokens with 45% Off
                  </button>
                  {/* <div className={style.prHeading}>
                    <div className={style.buttonHeading}>MINIMUM PURCHASE:</div>
                    <div className={style.buttonSubheading}>10,000 USDa</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {<TokenModal amountToggle={amountToggle} toggle={toggleAmount} />}
    </>
  );
}

export default PreSale;
