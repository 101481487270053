import { motion } from "framer-motion";
import React from "react";

const FramerRoute = ({ children,width }) => {
  const Variants = {
    initial: {
      x: "100%",
    },
    in: {
      x: 0,
    },
    out: {
      x: "-100%",
    },
  };

  const Transition = {
    type: "spring",
    mass: 0.1,
    duration: 1,
    stiffness: 40,
  };
  return (
    <motion.div
      key="first"
      initial="initial"
      animate="in"
      exit="out"
      variants={Variants}
      transition={Transition}
      style={{ width: width?width:"100%" }}
    >
      {children}
    </motion.div>
  );
};

export default FramerRoute;
