import React from "react";
import DashboardChart from "../../_Common/DashboardChart/DashboardChart";
import Card from "./Card/Card";
import Table from "./Table/Table";
import style from "./Wallet.module.scss";

function Wallet() {
  return (
    <div className={style.container}>
      <div className={style.Wrapper}>
        <div className={style.ChartWrapper}>
          <DashboardChart />
        </div>
        <div className={style.cardWrapper}>
          <Card />
        </div>

        <Table />
      </div>
    </div>
  );
}

export default Wallet;
