import React from "react";
import style from "./acx.module.scss";
import SidebarComponent from "../_Common/Sidebar/Sidebar";
import Farmsicon from "../../assets/images/farms.svg";
import Poolicon from "../../assets/images/pool.svg";
import Vaultsicon from "../../assets/images/vaults.svg";
import FarmsComponent from "./Farms/Farms";
import PoolsComponent from "./Pools/Pools";
import VaultsComponent from "./Vaults/Vaults";
const Pathlist = [
  {
    title: "farms",
    Icon: Farmsicon,
    path: "/acx/farms",
  },
  {
    title: "pool",
    Icon: Poolicon,
    path: "/acx/pool",
  },

  {
    title: "vaults",
    Icon: Vaultsicon,
    path: "/acx/vaults",
  },
];
const Acx = ({
  history,
  match: {
    params: { id },
  },
}) => {
  return (
    <>
      <div className={style.container}>
        <div className={style.sidebar}>
          <SidebarComponent Pathlist={Pathlist} id={id} history={history} />
        </div>
        <div className={style.rightsection}>
          {id === "farms" ? (
            <FarmsComponent />
          ) : id === "pool" ? (
            <PoolsComponent />
          ) : id === "vaults" ? (
            <VaultsComponent />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Acx;
