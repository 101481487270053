import React, { useEffect, useState } from "react";
import style from "./bond.module.scss";
import Modal from "./Bondpopup/popup";
import logo from "../../../assets/images/currency.svg";
import Binanceicon from "../../../assets/images/binanceicon.svg";
import BondComponent from "./BondComponent/BondComponent";
import Settings from "../../../assets/images/settings.svg";
import backIcon from "../../../assets/images/awesome-arrow-left.svg";
import BondOptions from "./BondOptions/BondOptions";
import TokenModal from "../../Home/ACXtoken/ACXTokenModal";
import { useActiveWeb3React } from "../../../Services/WalletConnection/useWalletConnect";
import { BondList } from "../../../Services/bond.helper";
import { useDispatch, useSelector } from "react-redux";
import { setBondTokens } from "../../../store/reducers/accountReducer";
import { ImportGenericBondToken } from "../../../Services/WalletConnection/ImportCustomToken";
import Skeleton from "react-loading-skeleton";
import { NetworkTestChainId } from "../../../utils/contract";
import FramerRoute from "../../_Common/FramerRoute";

const Bond = () => {
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();
  const [isopen, setIsopen] = useState(false);
  const [isopeninner, setIsopeninner] = useState(false);
  const [amountToggle, setAmountToggle] = useState(false);
  const [bondList, setBondList] = useState([]);
  const [bondData, setBondData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [slippage, setSlippage] = useState(10);
  const bondToken = useSelector((state) => state.accountsReducer.bondToken);
  const { chainId } = useActiveWeb3React();
  useEffect(() => {
    getBondList();
  }, [chainId]);

  const getBondList = async () => {
    try {
      setLoading(true);
      const data = await BondList();
      setBondList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const selectBondData = (data) => {
    setBondData(data);
    toggle();
  };
  const toggle = () => {
    setIsopen(!isopen);
    setIsopeninner(false);
    setAmountToggle(false);
  };
  const toggleinner = () => {
    setIsopeninner(!isopeninner);
  };

  const toggleAmount = () => {
    setIsopen(false);
    setIsopeninner(false);
    setAmountToggle(!amountToggle);
  };
  const checkBondToken = (e, item) => {
    e.preventDefault();
    const check = bondToken?.some(
      (add) =>
        add.bondAddress === item?.contractAddress &&
        add.accountAddress === account
    );
    if (!bondToken.length || !check) {
      if (chainId === NetworkTestChainId) {
        dispatch(
          setBondTokens({
            bondAddress: item?.contractAddress,
            accountAddress: account,
          })
        );
        ImportGenericBondToken(
          item?.contractAddress,
          item?.name,
          item?.decimals
        );
      }
    } else {
      selectBondData(item);
    }
  };
  const renderLoading = (row, column) => {
    return (
      <>
        {[...Array(row).keys()].map((col) => (
          <tr>
            {[...Array(column).keys()].map((item) => (
              <td style={{ padding: "10px 10px 10px 10px" }}>
                <Skeleton
                  baseColor="#4d4370"
                  highlightColor="#00eaea"
                  height={30}
                />
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };
   return (
    <FramerRoute>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <p>Bond 1,1</p>
          </div>
          <div className={style.Cards}>
            <div className={style.card}>
              <p>ACX Price</p>
              <span>$0.00</span>
            </div>
            <div className={style.card}>
              <p>Floor</p>
              <span>$0.00</span>
            </div>
          </div>

          <div className={style.tablesection}>
            <table cellspacing="15">
              <tr>
                <td>Bond</td>
                <td>Price</td>
                <td>ROI</td>
                <td></td>
              </tr>

              {
                <>
                  {loading
                    ? renderLoading(3, 4)
                    : bondList?.map((item) => (
                        <tr>
                          <td>
                            <div className={style.imgsection}>
                              <img src={logo} alt="" />
                              <img
                                src={Binanceicon}
                                className={style.absoluteimg}
                                alt=""
                              />
                              <span>{item.name}</span>
                            </div>
                          </td>
                          <td>
                            {Number(item?.price)?.toFixed(2) +
                              " " +
                              item?.priceName}
                          </td>
                          <td>ROI</td>
                          <td>
                            <button onClick={(e) => checkBondToken(e, item)}>
                              Bond
                            </button>
                          </td>
                        </tr>
                      ))}
                </>
              }
            </table>
            {/* )} */}
          </div>
        </div>
      </div>
      <Modal visible={isopen} onClose={toggle}>
        <div className={style.SettingContainer}>
          <div className={style.wrapper}>
            {!isopeninner ? (
              <div className={style.imgsection}>
                <img src={logo} alt="" />
                <img src={Binanceicon} className={style.absoluteimg} alt="" />
                <span>{bondData?.name}</span>
              </div>
            ) : (
              <div className={style.heading}>Options</div>
            )}
            {!isopeninner ? (
              <img
                src={Settings}
                alt=""
                onClick={toggleinner}
                className={style.settingicon}
              />
            ) : (
              <img
                src={backIcon}
                alt=""
                onClick={toggleinner}
                className={style.settingicon}
              />
            )}
          </div>
          {!isopeninner ? (
            <BondComponent
              toggle={toggle}
              toggleAmount={toggleAmount}
              bondData={bondData}
            />
          ) : (
            <BondOptions
              toggleinner={toggleinner}
              account={account}
              address={address}
              setAddress={setAddress}
              slippage={slippage}
              setSlippage={setSlippage}
            />
          )}
        </div>
      </Modal>
      {
        <TokenModal
          amountToggle={amountToggle}
          toggle={toggleAmount}
          bondData={bondData}
          address={address}
          slippage={slippage}
        />
      }
    </FramerRoute>
  );
};

export default Bond;
