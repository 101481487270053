import React from "react";
import style from "./sidebar.module.scss";
const Sidebar = ({ id, Pathlist, history }) => {
  const getvalue = (item) => {
    history.push(item.path);
  };

  return (
    <>
      <div className={style.sidebar}>
        <div className={style.subsidebar}>
          <ul>
            {Pathlist.map((item, i) => (
              <li
                key={i}
                onClick={() => getvalue(item)}
                className={
                  item.path.split("/")[2].toLowerCase() === id.toLowerCase()
                    ? style.active
                    : ""
                }
              >
                <div className={style.siderole}>
                  <div className={style.sideroleimg}>
                    <img src={item.Icon} alt="Crypto" />
                  </div>
                  <div className={style.siderolelable}>
                    <label>{item.title}</label>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
