import React from "react";
import style from "./BarChart.module.scss";
import data from "./data";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const BarChart = () => {
  return (
    <div className={style.container}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={1000}
          height={300}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          // border-radius={{
          //   top: 20,
          //   right: 20,
          //   left: 20,
          //   bottom: 20,
          // }}
        >
          {/* <CartesianGrid strokeDasharray="3 " /> */}
          {/* <XAxis dataKey="name" /> */}
          {/* <YAxis baseLine={8} /> */}
          <YAxis
            padding={{ top: 20, bottom: 20 }}
            tick={{ stroke: "white", strokeWidth: "0.5", fontSize: 12 }}
            tickLine={false}
            axisLine={false}
          />
          <XAxis tick={false} axisLine={false} />

          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="50%" stopColor="#00EAEA" stopOpacity={1} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>

          <Tooltip />
          <Area
            className={style.wrapper}
            type="monotone"
            dataKey="uv"
            stackId="1"
            stroke="#00EAEA"
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChart;
