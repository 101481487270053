import React, { useEffect, useState } from "react";
import {
  BalanceOf,
  getSwapData,
  GetTokenCorrespondingValue,
  SwapToken,
  SynthToUSD,
} from "../../../Services/swap.helper";
import { useActiveWeb3React } from "../../../Services/WalletConnection/useWalletConnect";
import { useAlert } from "react-alert";

const useAllowSwap = () => {
  const alert = useAlert();
  const { account, chainId } = useActiveWeb3React();
  const [swapData, setSwapData] = useState([]);
  const [selectedTo, setSelectedTo] = useState({});
  const [selectedFrom, setSelectedFrom] = useState({});
  const [AmountTo, setAmountTo] = useState("");
  const [AmountFrom, setAmountFrom] = useState("");
  const [clickedFrom, setClickedFrom] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [loadingSwap, setLoadingSwap] = useState(false);
  const [loadingSwapData, setLoadingSwapData] = useState(false);
  const [loadingSwapInput, setLoadingSwapInput] = useState(false);
  const [swapIconFrom, setSwapIconFrom] = useState(true);
  const [usdValue, setUsdValue] = useState("");
  const [SingleUsdValue, setSingleUsdValue] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    GetSwapData();
    // GetBalanceOf();
  }, [account, chainId]);
  useEffect(() => {
    swapData && setSelectedFrom(swapData[0]);
    swapData && setSelectedTo(swapData[1]);
  }, [swapData]);
  useEffect(() => {
    selectedTo?.contractAddress &&
      selectedFrom?.contractAddress &&
      calculateAmount(clickedFrom ? AmountFrom : AmountTo, clickedFrom);
  }, [selectedFrom?.contractAddress, selectedTo?.contractAddress]);

  const GetSwapData = async () => {
    try {
      setLoadingSwapData(true)
      const res = await getSwapData(account);
      setLoadingSwapData(false)
      setSwapData(res);
      setLoadingSwapData(false)
    } catch (error) {}
  };

  const calculateAmount = async (value, checkAmount) => {
    // setAmountFrom("");
    // setAmountTo("");
    setErrorMessage("");
    if (countDecimals(Number(value)) > 9) {
      alert.show("Input value should not be greater than 9 floating points", {
        type: "error",
      });
      return;
    }
    try {
      setLoadingSwapInput(true);
      setClickedFrom(checkAmount);
      if (value > 0) {
        const resUsd = await GetTokenCorrespondingValue(
          selectedFrom,
          selectedTo,
          1
        );
        const resUsdData = {
          fromName: selectedFrom?.name,
          toName: selectedTo?.name,
          synthValue: resUsd?.synthValue,
          usdValue: resUsd?.usdValueWithFee,
        };
        setSingleUsdValue(resUsdData);
        if (checkAmount) {
          setAmountFrom(value);
          const res = await GetTokenCorrespondingValue(
            selectedFrom,
            selectedTo,
            value,
            account
          );
          setAmountTo(res?.synthValue);
          setUsdValue(res?.usdValueWithFee);
          // const x = Number(e.target.value) / tokenValue;
          // setAmountTo(x.toString());
        } else {
          setAmountTo(value);
          const res = await GetTokenCorrespondingValue(
            selectedTo,
            selectedFrom,
            value,
            account
          );
          setAmountFrom(res?.synthValue);
          setUsdValue(res?.usdValueWithFee);
          // const y = Number(e.target.value) * tokenValue;
          // setAmountFrom(y.toString());
        }
      } else {
        setAmountFrom(value);
        setAmountTo(value);
      }
      setLoadingSwapInput(false);
    } catch (error) {
      setLoadingSwapInput(false);
      console.log("error", error);
    }

  };
  const submitHandler = async (e) => {
    e.preventDefault();
    !account && setPopupVisible((prev) => !prev);
    if (account) {
      if (!+AmountFrom && !+AmountTo) {
        setErrorMessage("Invalid Input Field ");
        return;
      }
      try {
        setLoadingSwap(true);
        const res = await SwapToken(
          selectedFrom,
          selectedTo,
          account,
          AmountFrom
        );
        setLoadingSwap(false);
        alert.show("Transaction Successful", { type: "success" });
        setAmountFrom("");
        setAmountTo("");
      } catch (error) {
        setLoadingSwap(false);
        alert.show("Transaction Failed", { type: "error" });
        setAmountFrom("");
        setAmountTo("");
        console.log("error", error);
      }
    }
  };
  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;

    var str = value.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
      return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
      return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
  };
  return {
    swapData,
    selectedTo,
    setSelectedTo,
    selectedFrom,
    setSelectedFrom,
    account,
    popupVisible,
    setPopupVisible,
    submitHandler,
    calculateAmount,
    setClickedFrom,
    clickedFrom,
    AmountFrom,
    AmountTo,
    loadingSwap,
    usdValue,
    SingleUsdValue,
    loadingSwapInput,
    errorMessage,
    loadingSwapData
  };
};

export default useAllowSwap;
