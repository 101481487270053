import sMATIC from "../assets/images/sMATIC.svg";
import sATOM from "../assets/images/sATOM.svg";
import sJPY from "../assets/images/sJPY.svg";
import sEUR from "../assets/images/sEUR.svg";

export const CoinColor = {
  sMATIC: "#8247E5",
  sATOM: "#2e3148",
  sEUR: "rgb(255,200,67)",
  sJPY: "black",
};
export const defaultSynthSwapTokens = {
  name: "ACX synthSwap List",
  timestamp: "2022-01-26T11:45:09Z",
  version: {
    major: 4,
    minor: 0,
    patch: 0,
  },
  tags: {},
  crypto: [
    {
      name: "sATOM",
      symbol: "sATOM",
      contractAddress: "0x0e754940693210Dd8282227F1c7b7C7DC399D88B",
      decimals: 9,
      icon: sATOM,
      color: CoinColor["sATOM"],
    },
    {
      name: "sMATIC",
      symbol: "sMATIC",
      contractAddress: "0x3ED3cd94555C60947cB961B3299598BEa46fbdAA",
      decimals: 9,
      icon: sMATIC,
      color: CoinColor["sMATIC"],
    },
  ],
  forex: [
    {
      name: "sJapaneseYen",
      symbol: "sJPY",
      contractAddress: "0x40f49E545058403f511B12262d41F4e46c63Cf5A",
      decimals: 9,
      icon: sJPY,
      color: CoinColor["sJPY"],
    },
    {
      name: "sEuro",
      symbol: "sEUR",
      contractAddress: "0xeD67088830B08007Dbc4E876F15352391ECA99ca",
      decimals: 9,
      icon: sEUR,
      color: CoinColor["sEUR"],
    },
  ],
  commodities: [
    {
      name: "sGOLD",
      symbol: "sGOLD",
      contractAddress: "0x59Be65664EEaE255De61a5A7d1Bf34bc62fC2c09",
      decimals: 9,
    },
    {
      name: "sGAS",
      symbol: "sGAS",
      contractAddress: "0x49BcDb3e0BF6AACde009547b4C5f1D58E15430b7",
      decimals: 9,
    },
  ],
  stocks: [],
};
