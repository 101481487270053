import React, { useState } from "react";
import style from "./Unique.module.scss";
import Data from "./data";

function Unique() {
  const [data] = useState(Data);
  return (
    <>
      <div className={style.width}>
        <div className={style.blogsRow}>
          <div className={style.container}>
            <div className={style.heading}>
              What Makes&nbsp;<span>ACX</span>&nbsp;Unique?
            </div>
            <div className={style.cardData}>
              {data.map((currentElement) => {
                const { id, img, title, dec, heading } = currentElement;
                return (
                  <div className={style.card} key={id}data-aos="flip-up" >
                    <div className={style.homeCard}>
                      <img src={img} alt="img" />
                    </div>
                    <div className={style.cardHeading}>
                      <span>{heading}.</span>
                      {title}
                    </div>
                    <div className={style.subheading}>{dec}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Unique;
