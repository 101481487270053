import homeCard from "../../../assets/images/homeCard.svg";
import homeCard2 from "../../../assets/images/homeCard2.svg";
import homeCard3 from "../../../assets/images/homeCard3.svg";

const Data = [
  {
    id: 1,
    heading: 1,
    img: homeCard3,
    title: " Infinite Liquidity",
    dec: "ACX's extremely unique model does not have a traditional order book. All transactions are done Peer to Smart Contract as opposed to P2P. Unlike other protocols that profit off maker and taker fees and are widespread in the community today. ACX guarantees the best execution price with the tightest spread every time.",
  },
  {
    id: 2,
    heading: 2,
    img: homeCard2,
    title: " Earn Yield at Scale",
    dec: "ACX liquidity pools are leveraged to deploy assets at scale without issue. The pools are continuously optimized to earn yield generated from functions such as automated market making and safe margin lending that continually redeploy to compound.",
  },
  {
    id: 3,
    heading: 3,
    img: homeCard,
    title: " Secure - Decentralized - Verifiable",
    dec: "ACX integrates the highest cryptographic safeguards and best practices while adding WebAuthn protocols. All transactions and states will be validated and stored on public-based blockchains such as BSC, enabling audits and upholding integrity.",
  },
];
export default Data;
