import React, { useState } from "react";
import style from "./Card.module.scss";
import Data from "./data";

function Card() {
  const [data] = useState(Data);
  return (
    <div>
      <div className={style.container}>
        {data.map((CardData) => {
          const { id, heading, subHeading } = CardData;
          return (
            <div className={style.wrapper} key={id}>
              <div className={style.heading}>{heading}</div>
              <div className={style.subHeading}>{subHeading}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Card;
