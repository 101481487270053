import Web3 from "web3";
import ABI from "../../utils/ABI/contract-api.json";
import SWAP_ABI from "../../utils/ABI/Swap-abi.json";
import staking_ABI from "../../utils/ABI/staking-contract-abi.json";
import sACX_ABI from "../../utils/ABI/SACX-contract-abi.json";
import BOND_ABI from "../../utils/ABI/BOND-abi.json";
import TREASURE_ABI from "../../utils/ABI/treasure-contract-abi.json";
import DISTRIBUTOR_ABI from "../../utils/ABI/distributor-contract-abi.json";
import EXCHANGE_ABI from "../../utils/ABI/exchange-contract-abi.json";
import sWRAP_ABI from "../../utils/ABI/wSwap-abi.json";
import swapStaking_ABI from "../../utils/ABI/swapStaking-contract-abi.json";
import swapPrice_ABI from "../../utils/ABI/swapPrice_ABI-contract-abi.json";
import {
  ACX_CONTRACT,
  BOND_CONTRACT,
  DISTRIBUTOR_CONTRACT,
  EXCHANGE_CONTRACT,
  SACX_CONTRACT,
  STAKING_CONTRACT,
  SWAP_STAKING_CONTRACT,
  TREASURE_CONTRACT,
  WRAPPED_SWAP_CONTRACT,
  SWAP_PRICE_CONTRACT
} from "../../utils/contract";

function GetProvider() {
  // const { account } = useActiveWeb3React();
  const web3 = new Web3();
  web3.setProvider(window.web3.currentProvider);
  return web3;
}
export const GetsACXContract = async () => {
  try {
    // const contractAddress = "0xE6d41aC36506C28672E358737dfE499807Db3bac";
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(ABI, ACX_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetStakingContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(staking_ABI, STAKING_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {}
};

export const GetsSACXContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(sACX_ABI, SACX_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {}
};
export const GetBondContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(BOND_ABI, BOND_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetTreasureContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(TREASURE_ABI, TREASURE_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetGenericBondContract = async (contractAddress) => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(ABI, contractAddress);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetDistributorContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(
        DISTRIBUTOR_ABI,
        DISTRIBUTOR_CONTRACT
      );
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetExchangeContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(EXCHANGE_ABI, EXCHANGE_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetSwapStakingContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(swapStaking_ABI, SWAP_STAKING_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetSwapStakingPriceContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(swapPrice_ABI, SWAP_PRICE_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetGenericSwapContract = async (contractAddress) => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(SWAP_ABI, contractAddress);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetsWrapSwapContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(sWRAP_ABI, WRAPPED_SWAP_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
