// ******************************************************************************

//Ropsten Testnet:
// export const ACX_CONTRACT="0x10B229C2771c75485BdE87273354FddF11aA5902";
// export const SACX_CONTRACT="0x1B16Bc6f4E157707154b7cFB4aFA1b0E73970dB9";
// export const STAKING_CONTRACT="0x283d1793a5159171e275962254C2D3B198ebd76D";
// export const BOND_CONTRACT="0xfC43bB4Cf2ABb48d1770f553F938A8193115055a";
// export const BUSD_CONTRACT="0xc4973618E2b0f88e2C5b9490121AAA1CF578A832";
// export const TREASURE_CONTRACT="0xA13eE7162b4484c6268e3102434baF8d2C9cf517";
// export const DISTRIBUTOR_CONTRACT="0xEa436d537C62d478571AC15C10F0995680A6Fb17";

//************************************************************************************* */

//FantomTestnet :

// export const ACX_CONTRACT="0xC85f3b514Ce5a1885B275392Bb9344354a070c60";
// export const SACX_CONTRACT="0xF75c782A4a05490a3d69755A2be00998230517ba";
// export const STAKING_CONTRACT="0xe824Fc04928c0A6E63838c3f6375d68A974b2dc1";
// export const BOND_CONTRACT="0x85B7688933b86Ac5041Bd6d8459C3F496370fB1f";
// export const BUSD_CONTRACT="0xd53a7C74a14068839607e807e7EBAf7c3e8F221F";
// export const TREASURE_CONTRACT="0xdA57963bd5E1ACEF5B7C734c8227ac1847EC32e8";
// export const DISTRIBUTOR_CONTRACT="0x5c1b3f5521948692A76F756094301B5A7B571c8d";

// //Change Network Name
// export const NetworkTest = "FTMTest";
// export const NetworkMain = "FTM";
// //Change ChainId
// export const NetworkMainChainId = "";
// export const NetworkTestChainId = 4002;

//************************************************************************************* */

//BinanceTestnet :
export const ACX_CONTRACT = "0xCfbeD470aEEf35f931D309585Ce7902dE033A55e";
export const SACX_CONTRACT = "0x5dFaCb65d076E98646b85424A71a27Efb930F281";
export const STAKING_CONTRACT = "0x0cB6Fd4E9943129274Adbcf71BA258b7bC11a402";
export const BOND_CONTRACT = "0x6F5D943Bd0Fe7642238F6ee0a9C66913D066aFBa";
export const BUSD_CONTRACT = "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735";
export const TREASURE_CONTRACT = "0x48A987Bf5db229451E1BBce24d08ee0936e83484";
export const DISTRIBUTOR_CONTRACT =
  "0xd46027B0ad37C4FeEe9CaF0B5B9C4eb9aAa3Db3C";
export const EXCHANGE_CONTRACT = "0x2984eb06D454e8c571B37B2841A972Ad6B3585Ee";
export const SWAP_STAKING_CONTRACT =
  "0x1f176702EB9A8902723c1794896fEA1c91c83848";
export const WRAPPED_SWAP_CONTRACT =
  "0xfA118e347ad6Fc519F1B4a273B9C22c51c8Cb2da";
export const SWAP_PRICE_CONTRACT = "0xc62FeaBA0C691036C53dd4598FB0E88f422B1701";


// SwapContarcts
// export const SWAP_CONTRACTS = {
//   sMATIC: "0x893F87045531461ac1A3c95B0EF3206b7F1721cE",
//   sATOM: "0xc7bC22e09f69b27d527CFdDC1F3e3cC77b800703",
// };

// export const sMATIC = "0x893F87045531461ac1A3c95B0EF3206b7F1721cE";
// export const sATOM = "0xc7bC22e09f69b27d527CFdDC1F3e3cC77b800703";

//Change Network Name
export const NetworkTest = "EthereumTest";
export const NetworkMain = "";
//Change ChainId
export const NetworkMainChainId = "";
export const NetworkTestChainId = 4;

//************************************************************************************* */
