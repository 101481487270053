import React from "react";
import Table from "./table/Table";

function Earn() {
  return (
    <div>
      <Table />
    </div>
  );
}

export default Earn;
