import React from "react";
import axios from "axios";
import Web3 from "web3";

const GetCoinData = async () => {
  try {
    const res = await axios.get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc&sparkline=false"
    );
    const data = res?.data?.map((item) => {
      return {
        symbol: item.symbol,
        current_price: item.current_price,
      };
    });
    return data;
  } catch (error) {}
  return;
};

export const ConvertToWEI = (data) => {
  const value = Web3.utils.toWei(Number(data).toFixed(8).toString(), "ether");
  return value;
};
export const ConvertFromWEI = (data) => {
  const value = Web3.utils.fromWei(data, "gwei");
  const value2 = Number(value).toFixed(0).toString();
  return value2;
};

export default GetCoinData;
