import React, { useState } from "react";
import style from "./dashboard.module.scss";
import CardData from "../../../Data/ReverseTokenAcxr/Dashboard";
const Dashboard = () => {
  const [Data] = useState(CardData);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <p>
              Each ACXR (Alchemi Reserve) token is backed by a basket of assets
              (e.g., USDa, ACXR-BNB LP Tokens etc etc) held in the Alchemi
              treasury, giving it a value it can't fall below.
            </p>
          </div>
          <div className={style.cardsection}>
            {Data.map((currentElm) => {
              const { id, title, description } = currentElm;
              return (
                <div className={style.box} key={id}>
                  <span>{description}</span>
                  <p>{title}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
