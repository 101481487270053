import Lcx from "../../../assets/images/lcx.svg";
import Request from "../../../assets/images/request.svg";
import Linch from "../../../assets/images/linch.svg";
import Ren from "../../../assets/images/ren.svg";
import Polygon from "../../../assets/images/Polygon.svg";
import Curve from "../../../assets/images/curve.svg";
const Data = [
  {
    id: 1,
    imgpath: Lcx,
    imgtext1: "Lcx",
    imgtext: "56523.51 USDb",
  },
  {
    id: 2,
    imgpath: Request,
    imgtext1: "Request",
    imgtext: "56523.51 USDb",
  },
  {
    id: 3,
    imgpath: Linch,
    imgtext1: "Linch",
    imgtext: "56523.51 USDb",
  },
  {
    id: 4,
    imgpath: Ren,
    imgtext1: "Ren",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Polygon,
    imgtext1: "Polygon",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Curve,
    imgtext1: "Curve",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Ren,
    imgtext1: "Ren",
    imgtext: "56523.51 USDb",
  },
  {
    id: 1,
    imgpath: Request,
    imgtext1: "Request",
    imgtext: "56523.51 USDb",
  },
];
export default Data;
