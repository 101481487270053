import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const PieGraph = () => {
  const data = [
    { name: "Group A", value: 200 },
    { name: "Group B", value: 100 },
    { name: "Group C", value: 100 },
    { name: "Group D", value: 200 },
  ];

  const COLORS = ["#14a0c0", "#7de314", "#01cc9b", "#055f88"];

  // const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    // cx,
    // cy,
    // midAngle,
    innerRadius,
    outerRadius,
  }) => {
    // const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    // const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // const y = cy + radius * Math.sin(-midAngle * RADIAN);
    // return (
    //   <text
    //     x={x}
    //     y={y}
    //     fill="white"
    //     textAnchor={x > cx ? "start" : "end"}
    //     dominantBaseline="central"
    //   >
    //     {`${(percent * 100).toFixed(0)}%`}
    //   </text>
    // );
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={500} height={500}>
        <Pie
          stroke="#442E5C"
          strokeWidth={5}
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
export default PieGraph;
