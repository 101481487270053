import React, { useState } from "react";
import style from "./blockchain.module.scss";
import ETH from "../../../assets/images/ETH1.svg";
import btc from "../../../assets/images/bitcoinLogo.svg";
import iconNext from "../../../assets/images/iconNext.svg";
import SwapDown from "../../../assets/images/SwapDown.svg";
import { useHistory } from "react-router-dom";
import DropDown from "../../_Common/DropDown/dropDown";
import BlockChainPopup from "../blockchain/blockChainPopup/popup/BlockChainPopup";
import BlockChainModal from "../blockchain/blockChainPopup/ModalStyles/BlockChainModal";

const Blockchain = () => {
  const history = useHistory();
  const [popupVisible, setPopupVisible] = useState(false);
  const togglePopup = (e) => {
    e.preventDefault();
    setPopupVisible((prev) => !prev);
  };
  const [selected, setSelected] = useState("BTC");
  const options = ["BTC", "USDa", "LITE", "ETH"];
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            ACX Swap is a convenient, fast, and ultra secure way to execute
            instant cross-chain swaps of your favorite coins across all EVM
            blockchains.
          </div>
          <div className={style.CardWrapper}>
            <BlockChainPopup
              visible={popupVisible}
              onClose={() => setPopupVisible(false)}
            >
              <BlockChainModal />
            </BlockChainPopup>
            <div className={style.leftWrapper} onClick={togglePopup}>
              <img src={btc} alt="btc" />
              <div className={style.textWrapper}>Bitcoin</div>
            </div>
            <div className={style.Next}>
              <img src={iconNext} alt="iconNext" />
            </div>
            <div className={style.rightWrapper}>
              <img src={ETH} alt="ETH" />
              <div className={style.textWrapper}>Ethereum</div>
            </div>
          </div>
          <div className={style.NavWrapper}>
            <div className={style.DropDown}>
              <DropDown
                padding="13px 5px"
                grow="100%"
                selected={selected}
                setSelected={setSelected}
                options={options}
                // imgShow={true}
              />
            </div>

            <input type="number" placeholder="0.0" />
          </div>
          <button
            className={style.btn1}
            type="submit"
            value="Submit"
            onClick={() => history.push("/allow-swap")}
          >
            Swap
          </button>
          <div className={style.SwapDown}>
            <img src={SwapDown} alt="SwapDown" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blockchain;
