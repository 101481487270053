import React from "react";
import Card from "./Stake/Card";
import SidebarComponent from "../_Common/Sidebar/Sidebar";
import style from "./stake.module.scss";
import stake from "../../assets/images/stake1.svg";
import Earning from "../../assets/images/Earn.svg";
import debt from "../../assets/images/Loan.svg";
import Loan from "../../assets/images/Loan.svg";
import MintBurnLog from "../../assets/images/MintBurn.svg";
import MintBurn from "./MintBurn/MintBurn";
import Earn from "./Earn/Earn";
import Debt from "./Debt/debt";
import InstantLoan from "./InstantLoan/InstantLoan";
import Bond from "../../assets/images/bond.svg";
import BondComponent from "./Bond/Bond";
const Pathlist = [
  {
    title: "Stake",
    Icon: stake,
    path: "/stake/stake",
  },
  // {
  //   title: "Mint & Burn",
  //   Icon: MintBurnLog,
  //   path: "/stake/mint-burn",
  // },
  // {
  //   title: "Earn",
  //   Icon: Earning,
  //   path: "/stake/earn",
  // },
  // {
  //   title: "Debit",
  //   Icon: debt,
  //   path: "/stake/debt",
  // },
  {
    title: "InstantLoan",
    Icon: Loan,
    path: "/stake/instantLoan",
  },
  {
    title: "Bond",
    Icon: Bond,
    path: "/stake/bond",
  },
];
function Stake({
  history,
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <div className={style.container}>
        <div className={style.sidebar}>
          <SidebarComponent Pathlist={Pathlist} id={id} history={history} />
        </div>
        <div className={style.rightsection}>
          {id === "stake" && <Card />}
          {id === "mint-burn" && <MintBurn />}
          {id === "earn" && <Earn />}
          {id === "debt" && <Debt />}
          {id === "instantLoan" && <InstantLoan />}
          {id === "bond" && <BondComponent />}
        </div>
      </div>
    </>
  );
}

export default Stake;
