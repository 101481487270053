const TableData = [
  {
    id: 1,
    asset: "USDa",
    price: "$-338,203,523.60",
    change: "35.70%",
  },
  {
    id: 2,
    asset: "BTCa",
    price: "$-338,203,523.60",
    change: "35.70%",
  },
  {
    id: 3,
    asset: "USDa",
    price: "$-338,203,523.60",
    change: "35.70%",
  },
  {
    id: 4,
    asset: "BTCa",
    price: "$-338,203,523.60",
    change: "35.70%",
  },
  {
    id: 5,
    asset: "USDa",
    price: "$-338,203,523.60",
    change: "35.70%",
  },
  {
    id: 6,
    asset: "BTCa",
    price: "$-338,203,523.60",
    change: "35.70%",
  },
  {
    id: 7,
    asset: "USDa",
    price: "$-338,203,523.60",
    change: "35.70%",
  },
  {
    id: 8,
    asset: "BTCa",
    price: "$-338,203,523.60",
    change: "35.70%",
  },
];
export default TableData;
