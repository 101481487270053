const Data = [
  {
    id: 1,
    heading: "$0.00",
    subHeading: "24H Change",
  },
  {
    id: 2,
    heading: "$0.00",
    subHeading: "Highest Balance",
  },
  {
    id: 3,
    heading: "1 Day",
    subHeading: "Portfolio Age",
  },
  {
    id: 4,
    heading: "---",
    subHeading: "Best 24H Asset",
  },
  {
    id: 5,
    heading: "---",
    subHeading: "Worst 24H Asset",
  },
];
export default Data;
