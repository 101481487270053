import React from "react";
import style from "./mainEnd.module.scss";
import PreSale from "../PreSale/PreSale";
import Assets from "../Assets/Assets";
import Slider from "../Users/Slider";

const MainEnd = () => {
  return (
    <>
      <div className={style.mainWrapper}>
        <PreSale />
        <Assets />
        <Slider />
      </div>
    </>
  );
};

export default MainEnd;
