import React from "react";
import style from "./Header.module.scss";

function Header() {
  return (
    <div className={style.container}>
      <div className={style.Wrapper}>
        <div className={style.Heading}>Portfolio Value</div>
        <div className={style.subHeading}>$0.00</div>
      </div>
    </div>
  );
}

export default Header;
