import Mask from "../../../assets/images/Mask.svg";
import Silver from "../../../assets/images/silver.svg";
const TableData = [
  {
    id: 1,
    ImgPath: Mask,
    Imgtext1: "XAUU",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 2,
    ImgPath: Mask,
    Imgtext1: "GoldD",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 3,
    ImgPath: Silver,
    Imgtext1: "SILVER",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 4,
    ImgPath: Mask,
    Imgtext1: "GoldD",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 5,
    ImgPath: Mask,
    Imgtext1: "GoldD",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
  {
    id: 6,
    ImgPath: Silver,
    Imgtext1: "SILVER",
    lastprice: "$512.11",
    change: "+12.01%",
    amount: "1,998.00",
    time: "00:46:00",
  },
];
export default TableData;
