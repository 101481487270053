import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getWrappedSACXTokenBalance,
  SynthToUSD,
  totalStakedCollateral,
  wSACXtoSynth,
} from "../../../../../Services/swap.helper";
import { useActiveWeb3React } from "../../../../../Services/WalletConnection/useWalletConnect";
import { setWrappedSACXTokenBalance ,setTolStakedCollateral} from "../../../../../store/reducers/swapReducer";

const useMint = ({ swapSelectedData }) => {
  const dispatch =useDispatch()
  const { account, chainId } = useActiveWeb3React();
  const [wSACXtoSynthValue, setwSACXtoSynthValue] = useState(null);
  const [synthUsdPrice, setSynthUsdPrice] = useState(null);
  const [tokenBalToWSACX, setTokenBalToWSACX] = useState(null);
  const [amount, setAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    account && checkWrappedSACXTokenBalance();
    account && getSinglewSACXtoSynth("1");
    account && GetSynthToUSD(swapSelectedData, "1");
    account && getTotalStakedCollateral();
  }, [account, chainId]);

  const checkWrappedSACXTokenBalance = async () => {
    const approved = await getWrappedSACXTokenBalance(account);
    dispatch(setWrappedSACXTokenBalance(approved));
  };
  const GetSynthToUSD = async (swapSelectedData, value) => {
    const res = await SynthToUSD(swapSelectedData, value);
    setSynthUsdPrice(res);
  };
  const getwSACXtoSynth = async (value) => {
    const res = await wSACXtoSynth(value, swapSelectedData, account);
    return res;
  };
  const getSinglewSACXtoSynth = async (value) => {
    const res = await getwSACXtoSynth(value);
    setwSACXtoSynthValue(res);
  };
  const getTotalStakedCollateral = async (value) => {
    const res = await totalStakedCollateral();
    dispatch(setTolStakedCollateral(res));
  };
  const changeHandler = async (e) => {
    setErrorMessage("");
    setAmount(e.target.value);

    const res = await getwSACXtoSynth(e.target.value);
    setTokenBalToWSACX(res);

  };
  return {
    wSACXtoSynthValue,
    synthUsdPrice,
    changeHandler,
    tokenBalToWSACX,
    setAmount,
    amount,
    errorMessage,
    setErrorMessage,
    checkWrappedSACXTokenBalance,
    getTotalStakedCollateral
  };
};
export default useMint;
