import React, { useState } from "react";
import style from "./Tab.module.scss";
import ActiveBorrows from "./ActiveBorrows/ActiveBorrows";
import BorrowSynths from "./BorrowSynths/BorrowSynths";
import Table from "../table/Table";

function MintTab() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <div className={style.Wrapper}>
        <div className={style.container}>
          <div className={style.tabs}>
            <ul className={style.nav}>
              <li
                onClick={() => setActiveTab(1)}
                className={activeTab === 1 ? style.active : ""}
              >
                ActiveBorrows
              </li>
              <li
                onClick={() => setActiveTab(2)}
                className={activeTab === 2 ? style.active : ""}
              >
                BorrowSynths
              </li>
            </ul>
            <div className="outlet">
              {activeTab === 1 && <ActiveBorrows />}
              {activeTab === 2 && <BorrowSynths />}
            </div>
          </div>
        </div>
        <div className={style.CardContainer}>
          <div className={style.CardWrapper}>
            <div className={style.Card1}>
              <div className={style.Heading}>
                Borrow ACX using other assets{" "}
              </div>
              <div className={style.subHeading}>
                Borrow Synths assets ETH against and renBTC Collateral,
                <br />
                <span>Learn More.</span>
              </div>
            </div>
            <div className={style.Card2}>
              <div className={style.Heading2}>PENDING WITHDRAWAL</div>
              <div className={style.subHeading}>
                YOU HAVE NO PENDING WITHDRAWALS
              </div>
            </div>
            <div className={style.Card3}>
              <Table />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MintTab;
