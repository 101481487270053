import React, { useState } from "react";
import style from "./Table.module.scss";
import TableData from "./data";

function Table() {
  const [data] = useState(TableData);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.tableContainer}>
            <div className={style.HeadingWrapper}>
              <div className={style.Heading}>History</div>
            </div>
            <div className={style.tableWrapper}>
              <div className={style.subWrapper}>
                <table>
                  <thead>
                    <tr>
                      <td>Assets</td>
                      <td>Position</td>
                      <td>Time</td>
                      <td>Expiry</td>
                      <td>Qty</td>
                      <td>Cost</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((win) => {
                      const {
                        id,
                        price,
                        change,
                        asset,

                        Tvl,
                        Rewards,
                        Time,
                        img,
                      } = win;
                      return (
                        <tr key={id} className={style.winrow}>
                          <td className={style.tdimg}>
                            <img src={img} alt="img" />
                            <div className={style.tdFont}>
                              <b className={style.FontHeading}>{asset}</b>
                            </div>
                          </td>
                          <td>
                            <b>{price}</b>
                          </td>
                          <td>
                            <b>{change}</b>
                          </td>
                          <td>
                            <b>{Tvl}</b>
                          </td>
                          <td>
                            <b>{Rewards}</b>
                          </td>
                          <td>
                            <b>{Time}</b>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
