import React from "react";
import style from "./heading.module.scss";

function Heading() {
  return (
    <>
      <div className={style.Container}>
        <div className={style.textWrapper}>
          <div className={style.text1}>
            <div className={style.Heading1}>STAKED VALUE</div>
            <div className={style.subHeading}>$O.OO</div>
          </div>
          <div className={style.text2}>
            <div className={style.Heading1}>C-RATIO</div>
            <div className={style.subHeading}>0%</div>
            <div className={style.border}></div>
          </div>
          <div className={style.text3}>
            <div className={style.Heading1}>ACTIVE DEBT</div>
            <div className={style.subHeading}>$O.OO</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Heading;
