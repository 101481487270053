import React, { useState } from "react";
import style from "./mint.module.scss";
import MintData from "../../../Data/ReverseTokenAcxr/Mint";
const Mint = () => {
  const [Data] = useState(MintData);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <p>ACX Treasury Bond Market</p>
          </div>
          <div className={style.mintsection}>
            <div className={style.mintheading}>
              <div className={style.balance}>
                <span>$1,850,393,677</span>
                <p>Treasury Balance</p>
              </div>
              <div className={style.balance}>
                <span>$1,850,393,677</span>
                <p>Treasury Balance</p>
              </div>
            </div>
            <div className={style.minttable}>
              <table>
                <thead>
                  <tr>
                    <td>Mint</td>
                    <td>Price</td>
                    <td>ROI</td>
                    <td>Purchased</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((currentElm) => {
                    const { id, imgpath, mint, price, roi, Purchased, btn } =
                      currentElm;
                    return (
                      <tr key={id}>
                        <td>
                          <img src={imgpath} alt="Mint" />
                          <span>{mint}</span>
                        </td>
                        <td>{price}</td>
                        <td>{roi}</td>
                        <td>{Purchased}</td>
                        <td>
                          <button>{btn}</button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mint;
