import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountsAddress: [],
  bondToken: [],
  swapToken:[]
};

export const accountAddressSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    resetSettings: (state) => initialState,
    setAccountAddress: (state, action) => {
      state.accountsAddress = [...state.accountsAddress, action.payload];
    },
    setBondTokens: (state, action) => {
      state.bondToken = [...state.bondToken, action.payload];
    },
    setSwapToken: (state, action) => {
      state.swapToken = [...state.swapToken, action.payload];
    },
  },
});

export const { setAccountAddress,setBondTokens,setSwapToken} = accountAddressSlice.actions;

export default accountAddressSlice.reducer;
