import React from "react";
import style from "./currentsynths.module.scss";

const CurrentSynths = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <span>Current Top 3 Synths</span>
          </div>
          <div className={style.Cards}>
            <div className={style.card}>
              <span>ACX</span>
              <label>Fully diluted market cap for ACX</label>
              <span>ACX</span>
              <p>$0.00</p>
              <span>ACX</span>
              <p>$0.00</p>
            </div>
            <div className={style.card}>
              <span>ACX</span>
              <label>Fully diluted market cap for ACX</label>
              <span>ACX</span>
              <p>$0.00</p>
              <span>ACX</span>
              <p>$0.00</p>
            </div>
            <div className={style.card}>
              <span>ACX</span>
              <label>Fully diluted market cap for ACX</label>
              <span>ACX</span>
              <p>$0.00</p>
              <span>ACX</span>
              <p>$0.00</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentSynths;
