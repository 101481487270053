import React from "react";
import Skeleton from "react-loading-skeleton";
import style from "./loadingStake.module.scss";


const LoadingSkeleton = () => {
  return (
    <div className={style.loadingWrapper}>
      <div className={style.loadingTab}>
        <Skeleton
          baseColor="#4d4370"
          highlightColor="#00eaea"
          height={20}
          width={100}
        />
        <Skeleton
          baseColor="#4d4370"
          highlightColor="#00eaea"
          height={20}
          width={100}
        />
      </div>
      <Skeleton baseColor="#4d4370" highlightColor="#00eaea" height={40} />
      <Skeleton baseColor="#4d4370" highlightColor="#00eaea" height={40} />
      <Skeleton
        baseColor="#4d4370"
        highlightColor="#00eaea"
        height={20}
        width={100}
      />
      <Skeleton
        baseColor="#4d4370"
        highlightColor="#00eaea"
        height={20}
        width={100}
      />
    </div>
  );
};

export default LoadingSkeleton;
