import React, { useEffect, useState } from "react";
import Modal from "../../../Swap/popup/popup";
import styles from "./ACXTokenModal.module.scss";
import buyTokenIcon from "../../../../assets/images/buyToken.svg";
import { getTokenValue } from "../../../../Services/Token.helper";
// import { GetBondContract } from "../../../../Services/Contract/contract.helper";
import Web3 from "web3";
import { useActiveWeb3React } from "../../../../Services/WalletConnection/useWalletConnect";
// import GetCoinData from "../../../../Services/Coin.helper";
import { useAlert } from "react-alert";
import { BUSD_CONTRACT } from "../../../../utils/contract";
import {
  BuyToken,
  CheckApprovedPreSale,
  SendApprovedPreSale,
} from "../../../../Services/preSale";
import Skeleton from "react-loading-skeleton";
import { depositorInfo } from "../../../../Services/bond.helper";
import { GetBondContract } from "../../../../Services/Contract/contract.helper";
import BigNumber from "bignumber.js";
const TokenModal = ({
  amountToggle,
  toggle,
  bondData,
  slippage = 10,
  address,
}) => {
  const alert = useAlert();
  const { account } = useActiveWeb3React();
  const [acxAmount, setAcxAmount] = useState("");
  const [acxAmountVal, setAcxAmountVal] = useState("");
  const [ethAmount, setEthAmount] = useState("");
  const [ethAmountVal, setEthAmountVal] = useState("");
  const [tokenValue, setTokenValue] = useState(null);
  const [approved, setApproved] = useState(null);
  const [loading, setLoading] = useState(false);
  const [depositInfo, setDepositInfo] = useState(false);
  const [bondPrice, setBondPrice] = useState(null);
  const [clickedInput, setClickedInput] = useState(false);
  const { chainId } = useActiveWeb3React();

  useEffect(() => {
    if (amountToggle) {
      account && getTokenVal();
      account && GetDepositData();
      account && getApprovedToken();
    }
  }, [account, chainId, amountToggle]);
  useEffect(() => {
    if (amountToggle) {
      getBondPrice();
    }
  }, [amountToggle]);
  const GetDepositData = async () => {
    try {
      const res = await depositorInfo(
        bondData ? bondData.contractAddress : BUSD_CONTRACT,
        account,
        bondData
          ? bondData.isLiquidityBond
            ? bondData.LP_Decimal
            : bondData.decimals
          : 1e18
      );
  
      setDepositInfo(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getTokenVal = async () => {
    const tokenVal = await getTokenValue(
      bondData ? bondData.contractAddress : BUSD_CONTRACT,
      bondData ? bondData.decimals : 1e18
    );
    setTokenValue(Number(tokenVal));
  };
  const getApprovedToken = async () => {
    const approved = await CheckApprovedPreSale(
      account,
      bondData ? bondData.contractAddress : BUSD_CONTRACT
    );
    setApproved(approved);
  };
  const getBondPrice = async () => {
    try {
      const customToken = await GetBondContract();
      const tokenAmountETH = await customToken.methods
        .bondPrice(bondData ? bondData.contractAddress : BUSD_CONTRACT)
        .call();
      setBondPrice(tokenAmountETH);
    } catch (error) {
      console.log("error", error);
    }
  };
  const validation = () => {
    let error = false;
    if (!acxAmount || acxAmount == 0) {
      setAcxAmountVal("Field Cannot be empty");
      error = true;
    }
    if (!ethAmount || ethAmount == 0) {
      setEthAmountVal("Field Cannot be empty");
      error = true;
    }
    return error;
  };
  const buyToken = async (e) => {
    e.preventDefault();
    try {
      if (validation()) {
        return;
      }
      setLoading(true);

      approved == 0 &&
        (await SendApprovedPreSale(
          account,
          bondData ? bondData.contractAddress : BUSD_CONTRACT
        ));
      const decimal = bondData ? bondData.decimals : 1e18;
      const dec = bondData ? Math.pow(10, Number(decimal)) : 1e18;
      const amount = ethAmount * dec;
      const slippageAmount = (bondPrice / 100) * slippage;
      const maxAmount = Number(bondPrice) + slippageAmount;

      await BuyToken(
        bondData ? bondData.contractAddress : BUSD_CONTRACT,
        amount,
        maxAmount,
        address ? address : account,
        account
      );

      getApprovedToken();
      // const res = await MyContract.methods.buyTokens().send({ value: value , from: account});
      setLoading(false);
      toggle();
      alert.show("Buy Successfully", { type: "success" });
      setEthAmount(null);
      setAcxAmount(null);
    } catch (error) {
      toggle();
      alert.show("Something Went Wrong", { type: "error" });
      console.log("error", error);
      setLoading(false);
      setEthAmount(null);
      setAcxAmount(null);
    }
  };
  const calculateAmount = (e, checkAmount) => {
    setAcxAmountVal("");
    setEthAmountVal("");
    setClickedInput(checkAmount);
 
    if (e.target.value > 0) {
      if (checkAmount) {
        const x = Number(e.target.value) / tokenValue;
        setAcxAmount(x.toString());
        setEthAmount(e.target.value);
      } else {
        const y = Number(e.target.value) * tokenValue;
        setEthAmount(y.toString());
        setAcxAmount(e.target.value);
      }
    } else {
      setAcxAmount(e.target.value);
      setEthAmount(e.target.value);
    }
  };

  return (
    <>
      <Modal
        visible={amountToggle}
        onClose={() => {
          toggle();
          setEthAmount(null);
          setAcxAmount(null);
          setAcxAmountVal("");
          setEthAmountVal("");
        }}
        buyToken={true}
      >
        <div className={styles.container}>
          <div className={styles.heading}> Enter Amount </div>
          <div className={styles.amountLabel}>
            {` BUSD Payment (1 ACX ≈ ${
              tokenValue
                ? tokenValue < 1
                  ? tokenValue?.toFixed(9)
                  : tokenValue
                : 0.0
            } ${bondData ? bondData.name : "BUSD"})`}
          </div>
          <div className={styles.amountContainer}>
            <div className={styles.inputWrapper}>
              <div className={styles.label}>ACX Quantity to buy </div>
              <input
                disabled={!tokenValue}
                className={styles.input}
                type="text"
                onChange={(e) => {
                  calculateAmount(e, false);
                }}
                value={
                  acxAmount
                    ? !clickedInput
                      ? acxAmount
                      : BigNumber(acxAmount).toFixed()
                    : acxAmount
                }
              />
              <div className={styles.errorLabel}>{acxAmountVal}</div>
            </div>

            <div className={styles.imgWrapper}>
              <img src={buyTokenIcon} />
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.label}>Equivalent </div>
              <input
                className={styles.input}
                type="text"
                disabled={!tokenValue}
                onChange={(e) => {
                  calculateAmount(e, true);
                }}
                value={
                  ethAmount
                    ? clickedInput
                      ? ethAmount
                      : BigNumber(ethAmount).toFixed()
                    : ethAmount
                }
              />
              <div className={styles.errorLabel}>{ethAmountVal}</div>
            </div>
          </div>
          {/* <div
            className={styles.buttonWrapper}
            style={{ marginBottom: "20px", justifyContent: "center" }}
          >
            <div className={styles.button} onClick={calculateAmount}>
              Calculate
            </div>
          </div> */}
          <div className={styles.detailWrapper}>
            <div className={styles.textWrapper2}>
              <div className={styles.textsubWrapper}>
                <div className={styles.textHeading}>{"Claimable ACX"}</div>
                {depositInfo ? (
                  <div className={styles.textsubHeading}>
                    {depositInfo.payout}
                  </div>
                ) : (
                  <Skeleton
                    baseColor="#4d4370"
                    highlightColor="#00eaea"
                    height={20}
                    width={100}
                  />
                )}
              </div>
              <div className={styles.textsubWrapper}>
                <div className={styles.textHeading}>
                  {"Purchase at the rate"}
                </div>
                {depositInfo ? (
                  <div className={styles.textsubHeading}>
                    {BigNumber(depositInfo?.pricePaid).toFixed()}
                  </div>
                ) : (
                  <Skeleton
                    baseColor="#4d4370"
                    highlightColor="#00eaea"
                    height={20}
                    width={100}
                  />
                )}
              </div>
              <div className={styles.textsubWrapper}>
                <div className={styles.textHeading}>{" Time Remaining"}</div>
                {depositInfo ? (
                  <div className={styles.textsubHeading}>
                    {depositInfo.timeRemaining}
                  </div>
                ) : (
                  <Skeleton
                    baseColor="#4d4370"
                    highlightColor="#00eaea"
                    height={20}
                    width={100}
                  />
                )}
              </div>
              {/* <div className={styles.textsubWrapper}>
                <div className={styles.textHeading}>{"Time Vesting"}</div>
                {depositInfo ? (
                  <div className={styles.textsubHeading}>
                    {depositInfo.vesting}
                  </div>
                ) : (
                  <Skeleton
                    baseColor="#4d4370"
                    highlightColor="#00eaea"
                    height={20}
                    width={100}
                  />
                )}
              </div> */}
              <div className={styles.textsubWrapper}>
                <div className={styles.textHeading}>{`Final ${
                  bondData ? bondData.name : "BUSD"
                } Amount`}</div>
                {depositInfo ? (
                  <div className={styles.textsubHeading}>
                    {ethAmount > 0
                      ? Number(ethAmount)?.toFixed(8)
                      : Number(ethAmount)}
                  </div>
                ) : (
                  <Skeleton
                    baseColor="#4d4370"
                    highlightColor="#00eaea"
                    height={20}
                    width={100}
                  />
                )}
              </div>
              <div className={styles.textsubWrapper}>
                <div className={styles.textHeading}>{" Final ACX Amount "}</div>
                {depositInfo ? (
                  <div className={styles.textsubHeading}>
                    {acxAmount > 0
                      ? Number(acxAmount)?.toFixed(8)
                      : Number(acxAmount)}
                  </div>
                ) : (
                  <Skeleton
                    baseColor="#4d4370"
                    highlightColor="#00eaea"
                    height={20}
                    width={100}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles.buttonWrapper}>
            <button
              className={styles.button}
              onClick={buyToken}
              disabled={loading}
            >
              {loading ? (
                <div className={styles.spinnerWrapper}>
                  <div className={styles.spinner}></div>
                </div>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TokenModal;
