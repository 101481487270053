import React from "react";
import Progress from "react-progressbar";

class Horizontal extends React.Component {
  render() {
    return (
      <div>
        <Progress completed={100} />
      </div>
    );
  }
}

export default Horizontal;
