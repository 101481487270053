import React from "react";
import Swap from "../Swap/swap";
import AllowSwap from "../Swap/AllowSwap/AllowSwap";
import Market from "../Market/Market";
import Stack from "../Stake/Stake";
import MintBurn from "../Stake/MintBurn/MintBurn";
import Trade from "../Trade/Trade";
import Liquidity from "../Liquidity/Liquidity";
import Createpair from "../Liquidity/createpair/Createpair";
import Addliquidity from "../Liquidity/addliquidity/Addliquidity";
import Options from "../Options/Options";
import AcxComponent from "../Acx/Acx";
import AcxToken from "../Home/ACXtoken/AcxToken";
import ReverseTokenAcxr from "../ReverseTokenAcxr/ReverseToken";
import Synths from "../Synths/Synths";
import Portfolio from "../Portfolio/Portfolio";
import Glossary from "../Glossary/Glossary";
import NetworkStats from "../NetworkStats/NetworkStats";
import CommingSoonModal from "./Modal/commingSoonModal/Modal";
import Home from "../Home/home";
import GenricModal from "./Modal/popup/popup";
import Error from "./Error/Error";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import WrappedSwap from "../Swap/WrapSwap/WrapSwap";

const NavigationRoute = () => {
  const CommingSoon = ({ history }) => {
    return (
      <div style={{ minHeight: "100vh" }}>
        {
          <GenricModal
            visible={true}
            onClose={() => {
              history.replace("/");
            }}
            scroll={true}
          >
            <CommingSoonModal />
          </GenricModal>
        }
      </div>
    );
  };
  return (
    <AnimatePresence exitBeforeEnter >
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/market/:id" component={Market} />
        <Route exact path="/trade/:id" component={Trade} />
        <Route exact path="/swap/:id" component={Swap} />
        <Route exact path="/wrap-swap" component={WrappedSwap} />
        <Route exact path="/acxToken" component={AcxToken} />
        <Route exact path="/portfolio/:id" component={Portfolio} />
        <Route exact path="/stake/:id" component={Stack} />
        <Route exact path="/liquidity" component={Liquidity} />
        <Route exact path="/create-pair" component={Createpair} />
        <Route exact path="/add-liquidity" component={Addliquidity} />
        <Route exact path="/options/crypto/:id" component={Options} />
        <Route exact path="/options/stocks/:id" component={Options} />
        <Route exact path="/options/forex/:id" component={Options} />
        <Route exact path="/options/commodities/:id" component={Options} />
        <Route exact path="/acx/:id" component={AcxComponent} />
        <Route exact path="/mint-burn" component={MintBurn} />
        <Route exact path="/network-stats" component={NetworkStats} />
        <Route exact path="/reverse-token-acxr/:id" component={ReverseTokenAcxr} />
        <Route exact path="/synths" component={Synths} />
        <Route exact path="/glossary" component={Glossary} />
        <Route path="*" component={Error} />
      </Switch>
      </AnimatePresence>
  );
};

export default NavigationRoute;
