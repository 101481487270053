import React, { useState } from "react";
import style from "./Burn.module.scss";
import NewScreen from "../NewScreen/NewScreen";
import useMint from "../MINT/useMint";
import useMintBurn from "../../useMintBurn";
import { useSelector } from "react-redux";

const Burn = () => {
  const { swapSelectedData } = useSelector((state) => state.swapReducer);
  const {

    wSACXtoSynthValue,
    changeHandler,
    tokenBalToWSACX,
    setAmount,
    amount,
    errorMessage,
    setErrorMessage,
    checkWrappedSACXTokenBalance,
    getTotalStakedCollateral
  } = useMint({swapSelectedData});
  const [activeTab, setActiveTab] = useState(0);
  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    setAmount("");
  };
  return (
    <>
      <div className={style.container}>
        {activeTab === 0 && (
          <div className={style.wrapper}>
            <div className={style.FirstWrapper}>
              <div className={style.topComp} onClick={() => handleActiveTab(1)}>
                <div className={style.Heading}>BURN MAX</div>
                <div className={style.subHeading}>
                  Burn the maximum amount of {swapSelectedData?.name}
                </div>
              </div>
            </div>

            <div className={style.SecWrapper}>
              <div
                className={style.bottomComp2}
                onClick={() => handleActiveTab(2)}
              >
                <div className={style.Heading}>BURN CUSTOM</div>
                <div className={style.subHeading}>
                  Burn the custom amount of {swapSelectedData?.name}
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 2 || activeTab === 1 ? (
          <NewScreen
            handleActiveTab={handleActiveTab}
            mint={false}
            max={activeTab === 1 ? true : false}
      
            wSACXtoSynthValue={wSACXtoSynthValue}
            changeHandler={changeHandler}
            tokenBalToWSACX={tokenBalToWSACX}
            setAmount={setAmount}
            amount={amount}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            checkWrappedTokenBal={checkWrappedSACXTokenBalance}
            getTotalStakedCollateral={getTotalStakedCollateral}
          />
        ) : null}
      </div>
    </>
  );
};

export default Burn;
