const Data = [
  {
    id: 1,
    date: "08-21",
    pair: "ETH/USDa",
    buying: "0.32000",
    selling: "0.16000",
    amount: "2998.00",
    status: "Completed",
    btn: "verify",
  },
  {
    id: 2,
    date: "08-21",
    pair: "ETH/USDa",
    buying: "0.32000",
    selling: "0.32000",
    amount: "1,998.00",
    status: "Completed",
    btn: "verify",
  },
  {
    id: 3,
    date: "08-21",
    pair: "ETH/USDa",
    buying: "0.32000",
    selling: "0.32000",
    amount: "1,998.00",
    status: "Completed",
    btn: "verify",
  },
  {
    id: 4,
    date: "08-21",
    pair: "ETH/USDa",
    buying: "0.32000",
    selling: "0.32000",
    amount: "1,998.00",
    status: "Completed",
    btn: "verify",
  },
  {
    id: 5,
    date: "08-21",
    pair: "ETH/USDa",
    buying: "0.32000",
    selling: "0.32000",
    amount: "1,998.00",
    status: "Completed",
    btn: "verify",
  },
  {
    id: 6,
    date: "08-21",
    pair: "ETH/USDa",
    buying: "0.32000",
    selling: "0.32000",
    amount: "1,998.00",
    status: "Completed",
    btn: "verify",
  },
  {
    id: 7,
    date: "08-21",
    pair: "ETH/USDa",
    buying: "0.32000",
    selling: "0.32000",
    amount: "1,998.00",
    status: "Completed",
    btn: "verify",
  },
];
export default Data;
