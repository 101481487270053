import React, { useEffect, useState } from "react";
import style from "./bondoptions.module.scss";
const BondOptions = ({
  account,
  setAddress,
  address,
  slippage,
  setSlippage,
}) => {
  useEffect(() => {
    setAddress(account);
  }, [account]);

  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.heading}></div>
          <div className={style.formbody}>
            <div className={style.inputfield}>
              <label>Slippage</label>
              <div className={style.field}>
                <input
                  type="number"
                  required="required"
                  autoComplete={"new-password"}
                  onChange={(e) => setSlippage(e.target.value)}
                  value={slippage}
                />
              </div>
              <label>
                Transaction may revert if price changes by more than slippage %
              </label>
            </div>
            <div className={style.inputfield}>
              <label>Recipient Address</label>
              <div className={style.field}>
                <input
                  type="address"
                  required="required"
                  autoComplete={"new-password"}
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </div>
              <label>
                Choose recipient address. By default, this is your currently
                connected address
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BondOptions;
