import React, { useState } from "react";
import style from "./Table.module.scss";
import TableData from "./data";

function Table() {
  const [active, setActive] = useState(1);
  const [data] = useState(TableData);
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.textWrapper}>
            <div className={style.text1}>
              <div className={style.Heading1}>Est. upcoming rewards</div>
              <div className={style.subHeading}>$O.OO</div>
            </div>
            <div className={style.text2}>
              <div className={style.Heading1}>Earning</div>
              <div className={style.subHeading}>0%</div>
              <div className={style.border}></div>
            </div>
            <div className={style.text3}>
              <div className={style.Heading1}>Limited Time</div>
              <div className={style.subHeading}>$O.OO</div>
            </div>
          </div>
          <div className={style.tableContainer}>
            <ul>
              <li
                onClick={() => setActive(1)}
                className={`${style.navItem} ${
                  active === 1 ? style.active : ""
                }`}
              >
                Active
              </li>
              <li
                onClick={() => setActive(2)}
                className={`${style.navItem} ${
                  active === 2 ? style.active : ""
                }`}
              >
                Expired
              </li>
            </ul>
            <div className={style.tableWrapper}>
              {active === 1 && (
                <div className={style.subWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <td>Select a pool</td>
                        <td>Est. APR</td>
                        <td>Staked Balance</td>
                        <td>TVL</td>
                        <td>Rewards</td>
                        <td>Time Left</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((win) => {
                        const {
                          id,
                          price,
                          change,
                          asset,
                          subAsset,
                          Tvl,
                          Rewards,
                          Time,
                          img,
                        } = win;
                        return (
                          <tr key={id} className={style.winrow}>
                            <td className={style.tdimg}>
                              <img src={img} alt="img" />
                              <div className={style.tdFont}>
                                <b className={style.FontHeading}>{asset}</b>
                                <b className={style.FontsubHeading}>
                                  {subAsset}
                                </b>
                              </div>
                            </td>
                            <td>
                              <b>{price}</b>
                            </td>
                            <td>
                              <b>{change}</b>
                            </td>
                            <td>
                              <b>{Tvl}</b>
                            </td>
                            <td>
                              <b>{Rewards}</b>
                            </td>
                            <td>
                              <b>{Time}</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {active === 2 && (
                <div className={style.subWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <td>Select a pool</td>
                        <td>Est. APR</td>
                        <td>Staked Balance</td>
                        <td>TVL</td>
                        <td>Rewards</td>
                        <td>Time Left</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((win) => {
                        const {
                          id,
                          price,
                          change,
                          asset,
                          subAsset,
                          Tvl,
                          Rewards,
                          Time,
                          img,
                        } = win;
                        return (
                          <tr key={id} className={style.winrow}>
                            <td className={style.tdimg}>
                              <img src={img} alt="img" />
                              <div className={style.tdFont}>
                                <b className={style.FontHeading}>{asset}</b>
                                <b className={style.FontsubHeading}>
                                  {subAsset}
                                </b>
                              </div>
                            </td>
                            <td>
                              <b>{price}</b>
                            </td>
                            <td>
                              <b>{change}</b>
                            </td>
                            <td>
                              <b>{Tvl}</b>
                            </td>
                            <td>
                              <b>{Rewards}</b>
                            </td>
                            <td>
                              <b>{Time}</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
