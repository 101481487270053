import Checked from "../../../../assets/images/check.svg";
const Data = [
  {
    id: 1,
    strike: "$6,000.00",
    breaks: "$4,145.58",
    implied: "127.13%",
    price: "$1838.53",
    plus: "+",
  },
  {
    id: 2,
    strike: "$6,000.00",
    breaks: "$4,145.58",
    implied: "127.13%",
    price: "$1838.53",
    checked: Checked,
  },
  {
    id: 3,
    strike: "$6,000.00",
    breaks: "$4,145.58",
    implied: "127.13%",
    price: "$1838.53",
    plus: "+",
    checked: "&#10003",
  },
];
export default Data;
