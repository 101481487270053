import Bitcoin from "../../../assets/images/bitcoin.svg";
import Binanceicon from "../../../assets/images/binanceicon.svg";
import Sorticon from "../../../assets/images/sorticon.svg";
import Uniswapicon from "../../../assets/images/uniswap.svg";
import Acxlogo from "../../../assets/images/acxlogos.svg";
import Ethmlogo from "../../../assets/images/ethrm.svg";
const Data = [
  {
    id: 1,
    imgpath: Bitcoin,
    ethmlogo: Ethmlogo,
    earned: "Earned",
    text: "0",
    apr: "APR",
    aprtxt: "38.53%",
    Liquidity: "Liquidity",
    Liquiditytext: "$475,365,113",
    Multiplier: "Multiplier",
    Multipliertext: "40x",
    arrowicon: Sorticon,
  },
  {
    id: 2,
    imgpath: Binanceicon,
    ethmlogo: Ethmlogo,
    earned: "Earned",
    text: "0",
    apr: "APR",
    aprtxt: "38.53%",
    Liquidity: "Liquidity",
    Liquiditytext: "$475,365,113",
    Multiplier: "Multiplier",
    Multipliertext: "40x",
    arrowicon: Sorticon,
  },
  {
    id: 3,
    imgpath: Uniswapicon,
    ethmlogo: Ethmlogo,
    earned: "Earned",
    text: "0",
    apr: "APR",
    aprtxt: "38.53%",
    Liquidity: "Liquidity",
    Liquiditytext: "$475,365,113",
    Multiplier: "Multiplier",
    Multipliertext: "40x",
    arrowicon: Sorticon,
  },
  {
    id: 4,
    imgpath: Acxlogo,
    ethmlogo: Ethmlogo,
    earned: "Earned",
    text: "0",
    apr: "APR",
    aprtxt: "38.53%",
    Liquidity: "Liquidity",
    Liquiditytext: "$475,365,113",
    Multiplier: "Multiplier",
    Multipliertext: "40x",
    arrowicon: Sorticon,
  },
];

export default Data;
