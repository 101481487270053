import React from "react";
import Network from "./Network/Network";
import Trending from "./Trending/Trending";
import Farming from "./Farming/Farming";
import Staking from "./Staking/Staking";
import Liquidations from "./Liquidations/Liquidations";
import CurrentSynths from "./CurrentSynths/CurrentSynths";
import style from "./network.module.scss";
const NetworkStats = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.topWrapper}>
          <Network />
          <Trending />
        </div>
        <div className={style.midWrapper}>
          <Farming />
          <Staking />
        </div>
        <div className={style.bottomWrapper}>
          <Liquidations />
          <CurrentSynths />
        </div>
      </div>
    </>
  );
};

export default NetworkStats;
