import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import accountReducer from "./reducers/accountReducer";
import settingReducer from "./reducers/settingsReducer";
import swapReducer from "./reducers/swapReducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["accountsReducer"],
  // blacklist: ["accountsReducer"],
};

const reducers = combineReducers({
  accountsReducer: accountReducer,
  settingReducer: settingReducer,
  swapReducer: swapReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
//
export const persistor = persistStore(store);
