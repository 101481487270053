import React from "react";
import style from "./footer.module.scss";
import FooterLogo from "../../../assets/images/footerlogo.svg";
import Telegram from "../../../assets/images/telegram.svg";
import Facebook from "../../../assets/images/facebook_white.svg";
import Twitter from "../../../assets/images/twitter.svg";
import Reddit from "../../../assets/images/reddit.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const Footer = () => {
  const { admin_settings } = useSelector((state) => state.settingReducer);
  return (
    <React.Fragment>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.leftsection}>
            <div className={style.logoimage}>
              <img src={FooterLogo} alt="Logo" />
            </div>
            <div className={style.text}>
              <p className={style.innerpara}>
               {admin_settings?.siteDescription}
              </p>
              <a href="https://www.acxd.io/">{admin_settings?.contactEmail}</a>
            </div>
          </div>
          <div className={style.mainlistsection}>
            <div className={style.listsection}>
              <label>ACX TOKEN</label>
              <ul>
                <li>
                  <Link to="/acx/farms">
                    <a href="_blank">ACX</a>
                  </Link>
                </li>
                <li>
                  <Link to="/synths">
                    <a href="_blank">Synths</a>
                  </Link>
                </li>
                <li>
                  <Link to="/reverse-token-acxr/dashboard">
                    <a href="_blank">Reserve Token ACXR</a>
                  </Link>
                </li>
                <li>
                  <a href="/">Build</a>
                </li>
                <li>
                  <a href="/">Governance</a>
                </li>
              </ul>
            </div>
            <div className={style.listsection}>
              <label>PRODUCTS</label>
              <div className={style.colwrapper}>
                <ul>
                  <li>
                    <Link to="/market/crypto">
                      <a href="_blank">Market</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/trade/crypto">
                      <a href="_blank">Trade</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/swap/allow-swap">
                      <a href="_blank">Swap</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/stake/stake">
                      <a href="_blank">Stake</a>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="/liquidity">
                      <a href="_blank">Liquidity</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/options/crypto/btc">
                      <a href="_blank">Options</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/acx/farms">
                      <a href="_blank">ACX</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style.listsection}>
              <label>COMPANY</label>
              <ul>
                <li>
                  <Link to="/network-stats">
                    <a href="_black">Network Stats</a>
                  </Link>
                </li>
                <li>
                  <a href={admin_settings?.whitePaperUrl} target="_blank">White Paper</a>
                </li>
                <li>
                  <a href={admin_settings?.lightPaperUrl} target="_blank">Light Paper</a>
                </li>
                <li>
                  <a href="/">Docs</a>
                </li>
                <li>
                  <Link to="/glossary">
                    <a href="_black">Glossary</a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className={style.listsection}>
              <label className={style.socials}>SOCIAL</label>
              <ul className={style.socialicons}>
                <li>
                  <a
                    href={admin_settings?.telegramUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={Telegram}
                      className={style.iconsimage}
                      alt="Telegram"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={admin_settings?.facebookUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={Facebook}
                      className={style.iconsimage}
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={admin_settings?.twitterUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href={admin_settings?.redditUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img src={Reddit} alt="Reddit" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={style.copyright}>
          <p>{admin_settings?.copyRight}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
