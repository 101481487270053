import Bitcoin from "../../../../assets/images/bitcoin.svg";
import Ethrem from "../../../../assets/images/ethrm.svg";
import Bitcoing from "../../../../assets/images/bitcoingreen.svg";
import currency from "../../../../assets/images/currency.svg";

const TableData = [
  {
    id: 1,
    img: Bitcoin,
    asset: "BTCa",
    subAsset: "Staking Reward",
    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$56868.6453",
    Rewards: "56868.6453 USDa",
    Time: "1d 22h 18m 47s",
  },
  {
    id: 2,
    img: currency,
    asset: "ACX Vault",
    subAsset: "Staking Reward",
    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$208.1315",
    Rewards: "208.1315 USDa",
    Time: "1d 22h 18m 47s",
  },
  {
    id: 3,
    img: Ethrem,
    asset: "ETHa",
    subAsset: "Staking Reward",
    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$4143.445",
    Rewards: "4143.445 USDa",
    Time: "1d 22h 18m 47s",
  },
  {
    id: 4,
    img: Bitcoing,
    asset: "BCHa",
    subAsset: "Staking Reward",
    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$562.79",
    Rewards: "562.79 USDa",
    Time: "1d 22h 18m 47s",
  },
  {
    id: 5,
    img: Bitcoin,
    asset: "BCHa",
    subAsset: "Staking Reward",
    price: "0.00%",
    change: "0.0000 USDa",
    Tvl: "$56868.6453",
    Rewards: "56868.6453 USDa",
    Time: "1d 22h 18m 47s",
  },
];
export default TableData;
