import {
  GetBondContract,
  GetsACXContract,
  GetsSACXContract,
  GetTreasureContract,
} from "./Contract/contract.helper";
import React, { useEffect } from "react";
import Web3 from "web3";
import { BUSD_CONTRACT } from "../utils/contract";
import BigNumber from "bignumber.js";

export const Balance = (account, library, chainId) => {
  // const [balance, setBalance] = React.useState();
  // useEffect(() => {
  //   if (!!account && !!library) {
  //     let stale = false;

  //     library
  //       .getBalance(account)
  //       .then((balance) => {
  //         if (!stale) {
  //           setBalance(balance);
  //         }
  //       })
  //       .catch(() => {
  //         if (!stale) {
  //           setBalance(null);
  //         }
  //       });

  //     return () => {
  //       stale = true;
  //       setBalance(undefined);
  //     };
  //   }
  // }, [account, library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

  return;
};
export const getTokenValueInUSD = async (address, decimals) => {
  const dec = Math.pow(10, Number(decimals));
  try {
    const customToken = await GetBondContract();
    const tokenAmountETH =
      (await customToken.methods.bondPriceInUSD(address).call()) / dec;

    return tokenAmountETH.toFixed(4);
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const getTokenValue = async (address, decimal) => {
  const customToken = await GetBondContract();
  const customPrice = async (decimal) => {
    
    const customTokenTreasure = await GetTreasureContract();
    const treasureValue = await customTokenTreasure.methods
      .valueOf(address, decimal)
      .call();
    const tokenVal = await customToken.methods
      .payoutFor(address, treasureValue)
      .call();
    const LP = Number(tokenVal);
    const res = 1e9 / Number(LP);
    const res2 = BigNumber(res).toFixed()
    return Number(res2);
  };
  try {
    const customToken = await GetBondContract();
    const { isLiquidityBond } = await customToken.methods
      .BondTerms(address)
      .call();
    if (isLiquidityBond) {
      const res = await customPrice("1000000000000000000");
      return res;
    } else {
      if (address === BUSD_CONTRACT) {
        const tokenAmountETH = await customToken.methods
          .bondPrice(address)
          .call();
        const data = tokenAmountETH / 100;
        const res = data;
        return res;
      } else {
        const dec = Math.pow(10, Number(decimal));
        const res = await customPrice(dec);
        return res;
      }
    }
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const getTokenBalance = async (account) => {
  const customToken = await GetsACXContract();
  const tokenAmountETH = await customToken.methods.balanceOf(account).call();
  const tokenValue = tokenAmountETH / 1e9;
  return tokenValue;
};
export const getFakeTokenBalance = async (account) => {
  const customToken = await GetsSACXContract();
  const tokenAmountETH = await customToken.methods.balanceOf(account).call();
  const tokenValue = tokenAmountETH / 1e9;
  return tokenValue;
};
function GetProvider() {
  const web3 = new Web3();
  web3.setProvider(window.web3.currentProvider);
  return web3;
}

export const getBlocks = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      // web3.eth.getBlockNumber().then(console.log);
      const res = await web3.eth.getBlockNumber();
      return res;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
