import React from "react";
import style from "./network.module.scss";
import BarChart from "../../_Common/BarChart/BarChart";
const Network = () => {
  return (
    <>
      <div className={style.network}>
        <div className={style.wrapper}>
          <div className={style.heading}>
            <span>Network Stats</span>
          </div>
          <div className={style.graphsection}>
            <div className={style.graphbody}>
              <div className={style.graghrole}>
                <div className={style.leftheading}>
                  <span>$28,905.93</span>
                  <p>ACX Price</p>
                </div>
                <div className={style.rightheading}>
                  <ul>
                    <li>
                      <button className={style.activebtn}>24H</button>
                    </li>
                    <li>
                      <button>7D</button>
                    </li>
                    <li>
                      <button>1M</button>
                    </li>
                    <li>
                      <button>3M</button>
                    </li>
                    <li>
                      <button>6M</button>
                    </li>
                    <li>
                      <button>1Y</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={style.graph}>
                <BarChart defaultColor={true} />
              </div>
            </div>
          </div>
          <div className={style.Cards}>
            <div className={style.card}>
              <span>ACX</span>
              <p>$0.00</p>
              <label>Fully diluted market cap for ACX</label>
            </div>
            <div className={style.card}>
              <span>ACX</span>
              <p>$0.00</p>
              <label>Fully diluted market cap for ACX</label>
            </div>
            <div className={style.card}>
              <span>ACX</span>
              <p>$0.00</p>
              <label>Fully diluted market cap for ACX</label>
            </div>
          </div>
          <div className={style.Boxsection}>
            <div className={style.box}>
              <span>Total ACX Staked</span>
              <p>$0.00</p>
              <label>The total value of all staked ACX</label>
            </div>
            <div className={style.box}>
              <span>Total ACX Staked</span>
              <p>$0.00</p>
              <label>The total value of all staked ACX</label>
            </div>
            <div className={style.box}>
              <span>Total ACX Staked</span>
              <p>$0.00</p>
              <label>The total value of all staked ACX</label>
            </div>
            <div className={style.box}>
              <span>Total ACX Staked</span>
              <p>$0.00</p>
              <label>The total value of all staked ACX</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Network;
