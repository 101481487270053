import React from "react";
import style from "./modal.module.scss";
import logo from "../../../../assets/images/currency.svg";
import Innerlogo from "../../../../assets/images/comingSoon/footer.svg";
import Telegram from "../../../../assets/images/comingSoon/Telegram.png";
import Facebook from "../../../../assets/images/Facebook.png";
import Discord from "../../../../assets/images/comingSoon/discord.png";
import Reddit from "../../../../assets/images/comingSoon/Reddit.png";
import Twitter from "../../../../assets/images/comingSoon/Twitter.png";
import { useSelector } from "react-redux";
const Modal = () => {
  const { admin_settings } = useSelector((state) => state.settingReducer);
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.container}>
          <div className={style.innerlogo}>
            <img src={logo} alt="InnerLogo" />
          </div>
          <p>Wrong Network</p>
          <span>Please change network you are not on an appropriate Network</span>
          <div className={style.socialicons}>
            <ul>
              <li>
                <a  href={admin_settings?.telegramUrl} target="_blank">
                  <img src={Telegram} alt="Telegram" />
                </a>
              </li>
              <li>
                <a  href={admin_settings?.facebookUrl} target="_blank">
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a  href={admin_settings?.redditUrl}target="_blank">
                  <img src={Reddit} alt="Reddit" />
                </a>
              </li>
              <li>
                <a href={admin_settings?.twitterUrl} target="_blank">
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
