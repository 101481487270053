import { BLOCKS_PER_DAY } from "../utils/AppConstant";
import { ACX_CONTRACT } from "../utils/contract";
import {
  GetBondContract,
  GetGenericBondContract,
  GetStakingContract,
  GetTreasureContract,
} from "./Contract/contract.helper";
import { getBlocks, getTokenValueInUSD } from "./Token.helper";

export const WarmupInfo = async (account) => {
  try {
    const customToken = await GetStakingContract();
    const res = customToken.methods.warmupInfo(account).call();
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const EpochInfo = async () => {
  try {
    // ;
    const customToken = await GetStakingContract();
    const res = customToken.methods.epoch().call();
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const redeemToken = async (accountAddress, account) => {
  try {
    const customToken = await GetBondContract();
    const res = customToken.methods
      .redeem(accountAddress, account, false)
      .send({ from: account });
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const claimACXToken = async (account) => {
  try {
    const customToken = await GetStakingContract();
    const res = customToken.methods.claim(account).send({ from: account });
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const BondList = async () => {
  try {
    const customTokenBond = await GetBondContract();
    const bondCount = await customTokenBond.methods.bondsCount().call();
    let bondList = [];
    if (bondCount) {
      for (let i = 0; i < bondCount; i++) {
        const bondData = await customTokenBond.methods.BondsList(i).call();
        if (bondData) {
          const { isLiquidityBond } = await customTokenBond.methods
            .BondTerms(bondData)
            .call();
          const customToken = await GetGenericBondContract(bondData);
          let name = "";
          let decimalUSD;
          let priceName = "$";
          if (isLiquidityBond) {
            const Token0 = await customToken.methods.token0().call();
            const customTokenLP0 = await GetGenericBondContract(Token0);
            const name1 = await customTokenLP0.methods.name().call();
            const Token1 = await customToken.methods.token1().call();
            const customTokenLP1 = await GetGenericBondContract(Token1);
            const name2 = await customTokenLP1.methods.name().call();
            name = `${name1}-${name2}`;
            const nonACXToken = ACX_CONTRACT === Token0 ? Token1 : Token0;
            const customTokenNonACX = await GetGenericBondContract(nonACXToken);
            priceName = await customTokenNonACX.methods.name().call();
            decimalUSD = await customTokenNonACX.methods.decimals().call();
          } else {
            name = await customToken.methods.name().call();
            decimalUSD = await customToken.methods.decimals().call();
          }
          const   decimals = await customToken.methods.decimals().call();
          const price = await getTokenValueInUSD(bondData, decimalUSD);
 
          if (price) {
            bondList.push({
              name: name,
              contractAddress: bondData,
              price: price,
              decimals: decimals,
              priceName,
              LP_Decimal:decimalUSD,
              isLiquidityBond
            });
          }
        }
      }
    }
    return bondList;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const depositorInfo = async (contractAddress, account , decimal) => {
  try {
    const customToken = await GetBondContract();
    const res = await customToken.methods
      .DepositorInfo(contractAddress, account)
      .call();
    if (res) {

      const dec = Math.pow(10, Number(decimal));
      const payout = res.payout / 1e9;
      const pricePaid = res.pricePaid / Number(dec);
      const vesting = await BondClaimRemainingBal(res.vesting);
      const lastBlock = Number(res.lastBlock) + Number(res.vesting);
      const currentBlock = await getBlocks();
      const leftBlocks = lastBlock - currentBlock;
      const timeRemaining = await BondClaimRemainingBal(leftBlocks);
 
      const data = {
        payout,
        pricePaid,
        timeRemaining,
        vesting,
      };

      return data;
    }
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const BondClaimRemainingBal = async (blocks) => {
  const blockLeft = blocks;
  if (blockLeft > 0) {
    const blocksPerHr = BLOCKS_PER_DAY / 24;
    const hoursPerBlock = 1 / blocksPerHr;
    const hoursLeft = blockLeft * hoursPerBlock;
    let res = hoursLeft;
    if (hoursLeft > 24) {
      const days = (hoursLeft / 24)?.toFixed(0);
      res = `${days} days`;
      return res;
    } else if (hoursLeft < 1) {
      const mins = (hoursLeft * 60)?.toFixed(0);
      res = `${mins} minutes`;
      return res;
    }
    return `${res?.toFixed(0)} hrs`;
  } else {
    return "0 min";
  }
};
