import React, { useEffect, useState } from "react";
import {
  CheckApprovedStaking,
  getAPR,
  SendApprovedStaking,
  SendStaking,
  StakeClaimRemainingBal,
} from "../../../../Services/Staking.helper";
import { useActiveWeb3React } from "../../../../Services/WalletConnection/useWalletConnect";
import { useAlert } from "react-alert";
import {
  getBlocks,
  getFakeTokenBalance,
  getTokenBalance,
} from "../../../../Services/Token.helper";
import {
  claimACXToken,
  EpochInfo,
  WarmupInfo,
} from "../../../../Services/bond.helper";

const useStakeTab = () => {
  const alert = useAlert();
  const { account, chainId } = useActiveWeb3React();
  const [approved, setApproved] = useState(null);
  const [balance, setBalance] = useState(null);
  const [warmupInfo, setWarmupInfo] = useState(null);
  const [epochInfo, setEpochInfo] = useState(null);
  const [sACXTokenbalance, setSACXTokenBalance] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [ARP, setARP] = useState(0);
  const [state, setState] = useState({
    amount: "",
    amountVal: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingClaim, setLoadingClaim] = useState(false);
  useEffect(() => {
    account && checkApprovedSkating();
    account && checkTokenBalance();
    account && checkSACXTokenBalance();
    account && checkWarmupInfo();
    account && checkEpochInfo();
    account && checkTimeRemaining();
    account && checkAPR();
  }, [account, chainId]);

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value, [name + "Val"]: "" }));
  };
  const resetState = () => {
    setState((prev) => ({ ...prev, amount: "", amountVal: "" }));
  };
  const validation = () => {
    let error = false;
    if (!state?.amount) {
      setState((prev) => ({ ...prev, amountVal: "Amount cannot be empty " }));
      error = true;
    } else if (state?.amount < 0 || state?.amount == 0) {
      setState((prev) => ({
        ...prev,
        amountVal: "Amount must be greater than 0",
      }));
      error = true;
    }
    return error;
  };
  const checkWarmupInfo = async () => {
    const data = await WarmupInfo(account);
    setWarmupInfo(data);
  };
  const checkTimeRemaining = async () => {
    const data = await StakeClaimRemainingBal(account);
    setTimeRemaining(data);
  };
  const checkEpochInfo = async () => {
    const info = await EpochInfo();
    setEpochInfo(info);
  };
  const checkApprovedSkating = async () => {
    const approved = await CheckApprovedStaking(account);
    setApproved(approved);
  };
  const checkTokenBalance = async () => {
    const bal = await getTokenBalance(account);
    setBalance(bal);
  };
  const checkSACXTokenBalance = async () => {
    const sacxBal = await getFakeTokenBalance(account);
    setSACXTokenBalance(sacxBal);
  };
  const onApprove = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const approvedTokens = await SendApprovedStaking(account);
      setLoading(false);
      checkApprovedSkating();
      alert.show("Token Approved Successfully", { type: "success" });
    } catch (error) {
      setLoading(false);
      alert.show("Something Went Wrong", { type: "error" });
    }
  };
  const onClaim = async (e) => {
    e.preventDefault();
    try {
      setLoadingClaim(true);
      const approvedTokens = await claimACXToken(account);
      setLoadingClaim(false);
      checkWarmupInfo();
      checkEpochInfo();
      checkTokenBalance();
      checkSACXTokenBalance();
      checkTimeRemaining();
      alert.show("Token Claimed Successfully", { type: "success" });
    } catch (error) {
      setLoadingClaim(false);
      alert.show("Something Went Wrong", { type: "error" });
    }
  };
  const onStake = async (e) => {
    e.preventDefault();
    if (validation()) {
      return;
    }
    try {
      setLoading(true);
      const stakingTokens = await SendStaking(state?.amount, account);
      setLoading(false);
      resetState();
      checkWarmupInfo();
      checkEpochInfo();
      checkTokenBalance();
      checkSACXTokenBalance();
      checkTimeRemaining();
      alert.show("Token Staked Successfully", { type: "success" });
    } catch (error) {
      setLoading(false);
      alert.show("Something Went Wrong", { type: "error" });
    }
  };
  const checkAPR = async () => {
    try {
      const ARP = await getAPR();
      setARP(ARP)
    } catch (error) {
      console.log("error", error);
    }
  };
  return {
    approved,
    changeHandler,
    account,
    onApprove,
    onStake,
    state,
    loading,
    balance,
    sACXTokenbalance,
    warmupInfo,
    epochInfo,
    onClaim,
    loadingClaim,
    timeRemaining,
    ARP
  };
};

export default useStakeTab;
