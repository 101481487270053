import { ANNUAL_REBASE, BLOCKS_PER_DAY } from "../utils/AppConstant";
import { STAKING_CONTRACT } from "../utils/contract";
import { EpochInfo, WarmupInfo } from "./bond.helper";
import {
  GetDistributorContract,
  GetsACXContract,
  GetsSACXContract,
  GetStakingContract,
} from "./Contract/contract.helper";
import { useActiveWeb3React } from "./WalletConnection/useWalletConnect";

export const CheckApprovedStaking = async (account) => {
  const customToken = await GetsACXContract();
  const approved = await customToken.methods
    .allowance(account, STAKING_CONTRACT)
    .call();
  return approved;
};
export const SendApprovedStaking = async (account) => {
  //   const amountWEI = ConvertToWEI("100000000000000000000000");
  const customToken = await GetsACXContract();
  const addApproved = await customToken.methods
    .approve(STAKING_CONTRACT, "100000000000000000000000")
    .send({ from: account });
  return addApproved;
};
export const SendStaking = async (amount, account) => {
  const amountWEI = amount * 1e9;
  const customToken = await GetStakingContract();
  const addApproved = await customToken.methods
    .stake(amountWEI, account)
    .send({ from: account });

  return;
};
export const CheckApprovedUnStaking = async (account) => {
  const customToken = await GetsSACXContract();
  const approved = await customToken.methods
    .allowance(account, STAKING_CONTRACT)
    .call();
  return approved;
};
export const SendApprovedUnStaking = async (account) => {
  const customToken = await GetsSACXContract();
  const addApproved = await customToken.methods
    .approve(STAKING_CONTRACT, "100000000000000000000000")
    .send({ from: account });
  return addApproved;
};
export const SendUnStaking = async (amount, max, account) => {
  const amountWEI = amount * 1e9;
  const customToken = await GetStakingContract();
  const addApproved = await customToken.methods
    .unstake(amountWEI, max)
    .send({ from: account });

  return addApproved;
};
export const StakeClaimRemainingBal = async (account) => {
  const userInfo = await WarmupInfo(account);
  const epoch = await EpochInfo();
  const epochLeft = userInfo?.expiry - epoch?.number;
  const blockLeft = epoch?.length * epochLeft;
  if (blockLeft > 0) {
    const blocksPerHr = BLOCKS_PER_DAY / 24;
    const hoursPerBlock = 1 / blocksPerHr;
    const hoursLeft = blockLeft * hoursPerBlock;
    let res = hoursLeft;
    if (hoursLeft > 24) {
      const days = (hoursLeft / 24)?.toFixed(0);
      res = `${days} days`;
      return res;
    } else if (hoursLeft < 1) {
      const mins = (hoursLeft * 60)?.toFixed(0);
      res = `${mins} minutes`;
      return res;
    }
    return `${res?.toFixed(0)} hrs`;
  } else {
    return "0 min";
  }
};
const totalSupplyACX = async () => {
  try {
    const customToken = await GetsACXContract();
    const res1 = await customToken.methods.totalSupply().call();
    const res = res1 / 1e9;
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};

const rewardRate = async () => {
  try {
    const customToken = await GetDistributorContract();
 
    const length = await customToken.methods.infoLength().call();
    const data = await customToken.methods.info(length - 1).call();
    const res = data?.rate / 10000;
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
const circulatingSupply = async () => {
  try {
    const customToken = await GetsSACXContract();
    const res1 = await customToken.methods.circulatingSupply().call();
    const res = res1 / 1e9;
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};

export const getAPR = async () => {
  try {
    const TotalSupply = await totalSupplyACX();
    const reward = await rewardRate();
    const annualRebase = ANNUAL_REBASE;
    const RewardRate = reward / 100;
    const circulating = await circulatingSupply();
    const annualReward = Number(TotalSupply) * RewardRate * annualRebase;
    const arpPercentage = (annualReward * 100) / circulating;
    return arpPercentage.toFixed(2);
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
