import React from "react";
import style from "./bridge.module.scss";
import ChainArrow from "../../../assets/images/chainarrow.svg";
const Bridge = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.bridgesection}>
            <div className={style.heading}>
              <p>Bridge tokens across EVM compatible blockchains</p>
            </div>
            <div className={style.chainsection}>
              <div className={style.fromchain}>
                <label>From Chain</label>
                <input type="text" placeholder="Avalanche Mainnet" />
              </div>
              <div className={style.swapechain}>
                <img src={ChainArrow} alt="chain" />
              </div>
              <div className={style.tochain}>
                <label>To Chain</label>
                <input type="text" placeholder="Fantom Opera Mainnet" />
              </div>
            </div>
            <div className={style.usd}>
              <div className={style.usdinput}>
                <label>USDa</label>
                <input type="text" placeholder="Amount" />
                <button>MAX</button>
              </div>
              <div className={style.approvebtn}>
                <button>Approve</button>
              </div>
            </div>
            <div className={style.textwrapper}>
              <p>
                Note: The "Approve" transaction is only needed when bridge for
                the first time; subsequent bridge only requires you to perform
                the "Bridge"transaction.
              </p>
              <div className={style.textsubWrapper}>
                <div className={style.textHeading}>Your Balance</div>
                <div className={style.textsubHeading}>0USDa</div>
              </div>
              <div className={style.textsubWrapper}>
                <div className={style.textHeading}>Crosschain Fee</div>
                <div className={style.textsubHeading}>0USDa</div>
              </div>
              <div className={style.textsubWrapper}>
                <div className={style.textHeading}>Minimum Crosschain Fee</div>
                <div className={style.textsubHeading}>0.1%</div>
              </div>
              <div className={style.textsubWrapper}>
                <div className={style.textHeading}>Maximum Crosschain Fee</div>
                <div className={style.textsubHeading}>0.00 USDa</div>
              </div>
              <div className={style.textsubWrapper}>
                <div className={style.textHeading}>
                  Minimum Crosschain Amount
                </div>
                <div className={style.textsubHeading}>$0.00.00 USDa</div>
              </div>
              <div className={style.textsubWrapper}>
                <div className={style.textHeading}>
                  Maximum Crosschain Amount
                </div>
                <div className={style.textsubHeading}>10-30min</div>
              </div>
              <div className={style.textsubWrapper}>
                <div className={style.textHeading}>
                  Estimated Time of Crosschain Arrival
                </div>
                <div className={style.textsubHeading}>12 hours</div>
              </div>
              <div className={style.textsubWrapper}>
                <div className={style.textHeading}>
                  Crosschain amount larger than 15
                </div>
                <div className={style.textsubHeading}>12 hours</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bridge;
