import React, { useEffect, useState } from "react";
import {
  BalanceOf,
  CheckApprovedUnStaking,
  getSACXTokenBalance,
  GetSACXTowSACX,
  getWrappedSACXTokenBalance,
  GetwSACXToSACX,
  SendApprovedUnStaking,
  SubmitWrapSwap,
} from "../../../Services/swap.helper";
import { useActiveWeb3React } from "../../../Services/WalletConnection/useWalletConnect";
import { useAlert } from "react-alert";

const useWrapSwap = () => {
  const alert = useAlert();
  const { account, chainId } = useActiveWeb3React();
  const options = ["SACX", "wSACX"];
  const [popupVisible, setPopupVisible] = useState(false);
  const [loadingSwap, setLoadingSwap] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selected, setSelected] = useState(options[0]);
  const [amount, setAmount] = useState("");
  const [singleValue, setSingleValue] = useState({});
  const [totalValue, setTotalValue] = useState({});
  const [approved, setApproved] = useState(null);
  const [sACXTokenBalance, setSACXTokenBalance] = useState(null);
  const [wrappedSACXTokenBalance, setWrappedSACXTokenBalance] = useState(null);

  useEffect(() => {
    calculateAmount(amount);
    calculateAmountSingle(amount);
  }, [selected]);
  useEffect(() => {
    calculateAmountSingle(amount);
  }, [amount]);
  useEffect(() => {
    account && checkApprovedUnSkating();
    account && checkWrappedSACXTokenBalance();
    account && checkSACXTokenBalance();
  }, [account, chainId]);

  const calculateAmount = async (value) => {
    setAmount(value);
    if (selected === "wSACX") {
      const res = await GetwSACXToSACX(value, account);
      setTotalValue({
        from: "wSACX",
        to: "SACX",
        amount: res,
      });
    } else if (selected === "SACX") {
      const res = await GetSACXTowSACX(value, account);
      setTotalValue({
        from: "SACX",
        to: "wSACX",
        amount: res,
      });
    }
  };
  const calculateAmountSingle = async (amount) => {
    setErrorMessage("");
    if (+amount) {

      if (selected === "wSACX") {
        const res = await GetwSACXToSACX(1, account);
        setSingleValue({
          from: "wSACX",
          to: "SACX",
          amount: res,
        });
      } else if (selected === "SACX") {
        const res = await GetSACXTowSACX(1, account);
        setSingleValue({
          from: "SACX",
          to: "wSACX",
          amount: res,
        });
      }
    } else {
      setSingleValue({
        from: "",
        to: "",
        amount: "",
      });
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    !account && setPopupVisible((prev) => !prev);
    if (approved === "0") {
      onApprove();
    } else {
      if (account) {
        if (+!amount) {
          setErrorMessage("Invalid Input Field ");
          return;
        }
        try {
          setLoadingSwap(true);
          await SubmitWrapSwap(selected, amount, account);
          setLoadingSwap(false);
          alert.show("Transaction Successful", { type: "success" });
          checkWrappedSACXTokenBalance();
          checkSACXTokenBalance();
          setAmount("");
        } catch (error) {
          setLoadingSwap(false);
          alert.show("Transaction Failed", { type: "error" });
          setAmount("");
        }
      }
    }
  };
  const checkApprovedUnSkating = async () => {
    const approved = await CheckApprovedUnStaking(account);
    setApproved(approved);
  };

  const onApprove = async (e) => {
    try {
      setLoadingSwap(true);
      const approvedTokens = await SendApprovedUnStaking(account);
      setLoadingSwap(false);
      checkApprovedUnSkating();
      alert.show("Token Approved Successfully", { type: "success" });
    } catch (error) {
      setLoadingSwap(false);
      alert.show("Something Went Wrong", { type: "error" });
    }
  };
  const checkWrappedSACXTokenBalance = async () => {
    const approved = await getWrappedSACXTokenBalance(account);
    setWrappedSACXTokenBalance(approved);
  };
  const checkSACXTokenBalance = async () => {
    const approved = await getSACXTokenBalance(account);
    setSACXTokenBalance(approved);
  };
  return {
    popupVisible,
    setPopupVisible,
    submitHandler,
    calculateAmount,
    amount,
    selected,
    setSelected,
    options,
    errorMessage,
    loadingSwap,
    loadingSwap,
    account,
    calculateAmountSingle,
    singleValue,
    totalValue,
    approved,
    sACXTokenBalance,
    wrappedSACXTokenBalance,
  };
};

export default useWrapSwap;
