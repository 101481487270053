import React, { useEffect, useState } from "react";
import {
  CheckApprovedUnStaking,
  getAPR,
  SendApprovedUnStaking,
  SendStaking,
  SendUnStaking,
} from "../../../../Services/Staking.helper";
import { useActiveWeb3React } from "../../../../Services/WalletConnection/useWalletConnect";
import { useAlert } from "react-alert";
import {
  getFakeTokenBalance,
  getTokenBalance,
} from "../../../../Services/Token.helper";

const useUnstake = () => {
  const alert = useAlert();
  const { account,chainId } = useActiveWeb3React();
  const [approved, setApproved] = useState(null);
  const [max, setMax] = useState(false);
  const [balance, setBalance] = useState(null);
  const [sACXTokenbalance, setSACXTokenBalance] = useState(null);
  const [ARP, setARP] = useState(0);
  const [state, setState] = useState({
    amount: "",
    amountVal: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    account && checkApprovedUnSkating();
    account && checkTokenBalance();
    account && checkSACXTokenBalance();
    account && checkAPR();
  }, [account,chainId]);

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value, [name + "Val"]: "" }));
  };
  const resetState = () => {
    setState((prev) => ({ ...prev, amount: "", amountVal: "" }));
  };
  const validation = () => {
    let error = false;
    if (!state?.amount) {
      setState((prev) => ({ ...prev, amountVal: "Amount cannot be empty " }));
      error = true;
    } else if (state?.amount < 0 || state?.amount == 0) {
      setState((prev) => ({
        ...prev,
        amountVal: "Amount must be greater than 0",
      }));
      error = true;
    } else if (state?.amount === sACXTokenbalance) {
      setMax(false);
    } else if (state?.amount !== sACXTokenbalance) {
      setMax(true);
    }
    return error;
  };
  const checkApprovedUnSkating = async () => {
    const approved = await CheckApprovedUnStaking(account);
    setApproved(approved);
  };
  const checkTokenBalance = async () => {
    const approved = await getTokenBalance(account);
    setBalance(approved);
  };
  const checkSACXTokenBalance = async () => {
    const approved = await getFakeTokenBalance(account);
    setSACXTokenBalance(approved);
  };
  const getMax = async (e) => {
    e.preventDefault();
    const approved = await getFakeTokenBalance(account);
    setState((prev) => ({ ...prev, amount: approved }));
  };
  const onApprove = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const approvedTokens = await SendApprovedUnStaking(account);
      setLoading(false);
      checkApprovedUnSkating();
      alert.show("Token Approved Successfully", { type: "success" });
    } catch (error) {
      setLoading(false);
      alert.show("Something Went Wrong", { type: "error" });
    }
  };
  const onUnStake = async (e) => {
    e.preventDefault();
    if (validation()) {
      return;
    }
    let max;
    if (state?.amount === sACXTokenbalance) {
      max = false;
    } else if (state?.amount !== sACXTokenbalance) {
      max = true;
    }
    try {
      setLoading(true);
      const stakingTokens = await SendUnStaking(state?.amount, max, account);
      setLoading(false);
      resetState();
      checkTokenBalance();
      checkSACXTokenBalance();
      alert.show("Token UnStaked Successfully", { type: "success" });
    } catch (error) {
      setLoading(false);
      alert.show("Something Went Wrong", { type: "error" });
    }
  };
  const checkAPR = async () => {
    try {
      const ARP = await getAPR();
      setARP(ARP)
    } catch (error) {
      console.log("error", error);
    }
  };
  return {
    approved,
    changeHandler,
    account,
    onApprove,
    onUnStake,
    state,
    loading,
    balance,
    sACXTokenbalance,
    getMax,
    max,
    ARP
  };
};

export default useUnstake;
