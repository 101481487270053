import React, { useState, useEffect } from "react";
import style from "./synths.module.scss";
import SynthsData from "../../Data/Synths/Synths";
const Synths = () => {
  //   const [active, setactive] = useState(0);
  const [Data, setData] = useState(SynthsData);
  const [clonedData] = useState(SynthsData);
  const [name, setname] = useState("All");
  useEffect(() => {
    if (name === "All") {
      let slicedata = clonedData.slice(0, 8);
      setData(slicedata);
    }
  }, [Data]);
  const GetValue = (name) => {
    setname(name);
    if (name === "All") {
      setData(SynthsData);
    } else if (name === "forex") {
      let filterData = clonedData.filter((m) => m.name === name);
      setData(filterData);
    } else if (name === "crypto") {
      let filterData = clonedData.filter((m) => m.name === name);
      setData(filterData);
    } else if (name === "commodities") {
      let filterData = clonedData.filter((m) => m.name === name);
      setData(filterData);
    }
  };
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.synths}>
            <div className={style.heading}>
              <span>SYNTHS</span>
              <p>
                Synths are derivative tokens providing exposure to a range of
                assets. They can be traded with infinite liquidity and zero
                slippage by leveraging the ACX protocol’s unique pooled
                collateral model. Trades between Synths generate a small fee
                that is distributed to ACX collateral providers.
              </p>
            </div>
            <div className={style.synthsdata}>
              <div className={style.slectionarea}>
                <ul>
                  <li
                    onClick={() => GetValue("All")}
                    className={name === "All" ? style.active : ""}
                  >
                    All
                  </li>
                  <li
                    onClick={() => GetValue("forex")}
                    className={name === "forex" ? style.active : ""}
                  >
                    FOREX
                  </li>
                  <li
                    onClick={() => GetValue("crypto")}
                    className={name === "crypto" ? style.active : ""}
                  >
                    CRYPTO
                  </li>
                  <li
                    onClick={() => GetValue("commodities")}
                    className={name === "commodities" ? style.active : ""}
                  >
                    COMMODITIES
                  </li>
                </ul>
              </div>
              <div className={style.cards}>
                {Data.map((currentElm) => {
                  const { id, title, live, price, pvalue, description } =
                    currentElm;
                  return (
                    <div className={style.card} key={id}>
                      <div className={style.title}>
                        <span>{title}</span>
                        <p>{live}</p>
                      </div>
                      <div className={style.usdprice}>
                        <span>{price}</span>
                        <p>{pvalue}</p>
                      </div>
                      <div className={style.description}>
                        <p>{description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Synths;
