import React, { useState } from "react";
import style from "./ChartTab.module.scss";
import Tab2 from "./Tab2/Tab2";
import Tab1 from "./Tab1/Tab1";

function CartTab() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <div className={style.Wrapper}>
        <div className={style.container}>
          <div className={style.tabs}>
            <ul className={style.nav}>
              <li
                onClick={() => setActiveTab(1)}
                className={activeTab === 1 ? style.active : ""}
              >
                OVERVIEW
              </li>
              <li
                onClick={() => setActiveTab(2)}
                className={activeTab === 2 ? style.active : ""}
              >
                MANAGE
              </li>
            </ul>
            <div className="outlet">
              {activeTab === 1 && <Tab1 />}
              {activeTab === 2 && <Tab2 />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartTab;
