import React, { useState } from "react";
import style from "./ActiveBorrows.module.scss";
import DropDown from "../../../../_Common/DropDown/dropDown";

const Burn = () => {
  const [selected, setSelected] = useState("BTC");
  const USING = ["BTC", "USDa", "LITE", "ETH"];
  const [selectedBORROW, setSelectedBORROW] = useState("BTC");
  const BORROW = ["BTC", "USDa", "LITE", "ETH"];
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.sectionContainer}>
            <div className={style.selection}>
              <div className={style.selectionWrapper}>
                <div className={style.heading}>BORROW</div>
                <div className={style.DropDown}>
                  <DropDown
                    // padding="13px 5px"
                    grow="100%"
                    selected={selectedBORROW}
                    setSelected={setSelectedBORROW}
                    options={BORROW}
                    imgShow={true}
                  />
                </div>
              </div>
              <div className={style.subHeading}>O.OO</div>
            </div>
            <div className={style.selection2}>
              <div className={style.selectionWrapper}>
                <div className={style.heading}>USING</div>
                <div className={style.DropDown}>
                  <DropDown
                    // padding="13px 5px"
                    grow="100%"
                    selected={selected}
                    setSelected={setSelected}
                    options={USING}
                    imgShow={true}
                  />
                </div>
              </div>
              <div className={style.subHeading}>O.OO</div>
            </div>
          </div>
          <div className={style.textSection}>
            <div className={style.heading1}>C-RATIO</div>
            <div className={style.textWrapper}>
              <div className={style.heading}>INTEREST RATE</div>
              <div className={style.subheading}>0.00%</div>
            </div>
            <div className={style.textWrapper}>
              <div className={style.heading}>INTEREST RATE</div>
              <div className={style.subheading}>0.00%</div>
            </div>
            <div className={style.textWrapper}>
              <div className={style.heading}>INTEREST RATE</div>
              <div className={style.subheading}>
                = 136 <spn>EDIT</spn>
              </div>
            </div>
            <button>Approve</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Burn;
