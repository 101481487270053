import React, { useState } from "react";
import StakeTab from "./StakeTab/StakeTab";
import Unstake from "./Unstake/Unstake";
import RewardCalculator from "./RewardCalculator/RewardCalculator";
import Withdraw from "./Withdraw/Withdraw";
import style from "./Card.module.scss";
import FramerRoute from "../../_Common/FramerRoute";

function Card() {
  const [activeTab, setActiveTab] = useState("StakeTab");
  return (
    <FramerRoute>
      <div className={style.container}>
        <div className={style.tabs}>
          <ul className={style.nav}>
            <li
              onClick={() => setActiveTab("StakeTab")}
              className={activeTab === "StakeTab" ? style.active : ""}
            >
              Stake Token
            </li>

            {/* <li
            onClick={() => setActiveTab("RewardCalculator")}
            className={activeTab === "RewardCalculator" ? style.active : ""}
          >
            Reward Calculator
          </li> */}
            <li
              onClick={() => setActiveTab("Unstake")}
              className={activeTab === "Unstake" ? style.active : ""}
            >
              Unstake Token
            </li>
            {/* <li
            onClick={() => setActiveTab("Withdraw")}
            className={activeTab === "Withdraw" ? style.active : ""}
          >
            Withdraw
          </li> */}
          </ul>
          <div className="outlet">
            {activeTab === "StakeTab" && <StakeTab />}
            {/* {activeTab === "RewardCalculator" && <RewardCalculator />} */}
            {activeTab === "Unstake" && <Unstake />}
            {/* {activeTab === "Withdraw" && <Withdraw />} */}
          </div>
        </div>
      </div>
    </FramerRoute>
  );
}

export default Card;
